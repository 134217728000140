import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Costrate } from 'src/app/shared/costrate.model';
import { CostrateService } from 'src/app/shared/costrate.service';
import { CostrateItemsComponent } from './costrate-items.component';

@Component({
  selector: 'app-costrate',
  templateUrl: './costrate.component.html',
  styles: []
})
export class CostrateComponent implements OnInit {

  constructor(private dialog: MatDialog, public dataCostRate: CostrateService, 
    private router: Router, private currentRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dataCostRate.getCostRateList().then(res => this.dataCostRate.dataCostRate = res as Costrate);
  }

  refreshList(){
    this.dataCostRate.getCostRateList().then(res => this.dataCostRate.dataCostRate = res as Costrate);
  };

  AddOrEditCostRateItem(costRateItemIndex, WorkstationID){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="60%";
    dialogConfig.height ="70%";
    dialogConfig.data = { costRateItemIndex, WorkstationID };
    this.dialog.open(CostrateItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });
  };
}

