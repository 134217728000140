import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuotesComponent } from './quotes/quotes.component';
import { QuoteComponent } from './quotes/quote/quote.component';
import { CustomerComponent } from './maintenance/customer/customer.component';
import { BoardComponent } from './maintenance/board/board.component';
import { PaperComponent } from './maintenance/paper/paper.component';
import { SupplierComponent } from './maintenance/supplier/supplier.component';
import { AuxiliaryComponent } from './maintenance/auxiliary/auxiliary.component';
import { WorkstationComponent } from './maintenance/workstation/workstation.component';
import { InkComponent } from './maintenance/ink/ink.component';
import { QuotehomeComponent } from './quotehome/quotehome.component';
import { FreightComponent } from './maintenance/freight/freight.component';
import { DestinyComponent } from './maintenance/destiny/destiny.component';
import { PalletComponent } from './maintenance/pallet/pallet.component';
import { CostrateComponent } from './maintenance/costrate/costrate.component';
import { TrucktypeComponent } from './maintenance/trucktype/trucktype.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [
    { path:'', redirectTo:'quotehome', pathMatch:'full'},
    { path:'quotes', component: QuotesComponent, canActivate:[MsalGuard]},
    { path:'quote', children:[
        { path:'', component: QuoteComponent},
        { path:'edit/:id',component: QuoteComponent}
    ], canActivate:[MsalGuard]},
    { path:'board', component: BoardComponent , canActivate:[MsalGuard]},
    { path:'paper', component: PaperComponent, canActivate:[MsalGuard]},
    { path:'customer', component: CustomerComponent, canActivate:[MsalGuard]},
    { path:'supplier', component: SupplierComponent, canActivate:[MsalGuard]},
    { path:'freight', component: FreightComponent, canActivate:[MsalGuard]},
    { path:'destiny', component: DestinyComponent, canActivate:[MsalGuard]},
    { path:'trucktype', component: TrucktypeComponent, canActivate:[MsalGuard]},
    { path:'pallet', component: PalletComponent, canActivate:[MsalGuard]},
    
    //{ path:'updatepapercost', component: UpdatepapercostComponent},
    { path:'auxiliary', component: AuxiliaryComponent, canActivate:[MsalGuard]},
    { path:'workstation', component: WorkstationComponent, canActivate:[MsalGuard]},
    { path:'costrate', component: CostrateComponent, canActivate:[MsalGuard]},
    { path:'ink', component: InkComponent, canActivate:[MsalGuard]},
    { path:'quotehome', component: QuotehomeComponent, canActivate:[MsalGuard]},
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        // Don't perform initial navigation in iframes or popups
        initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
            ? "enabledNonBlocking"
            : "disabled", // Set to enabledBlocking to use Angular Universal
    }),],
    exports: [RouterModule],
})

export class AppRoutingModule {}

