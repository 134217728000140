import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { PaperService } from 'src/app/shared/paper.service';
import { Updatepapercost } from 'src/app/shared/updatepapercost.model';
import { forEachChild } from 'typescript';

@Component({
  selector: 'app-updatepapercost',
  templateUrl: './updatepapercost.component.html',
  styles: []
})
export class UpdatepapercostComponent implements OnInit {
  updatePaperCost: Updatepapercost;
  isValid: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) 
  public data,
  public dialogRef: MatDialogRef<UpdatepapercostComponent>,
  public paperService: PaperService, private toastr: ToastrService,
  private router: Router, private currentRoute: ActivatedRoute) { }

  ngOnInit(): void {

      this.updatePaperCost = {
        increasePercentaje: 'option2',
        increaseAbsolute: '',
        UpdateTestLiner: false,
        UpdateTestMedium: false,
        UpdateKraftLiner: false,
        UpdateKraftMedium: false,
        UpdateWhiteTop: false,
        UpdateAll: false,
        UpdateTestLinerQuantity: 0, 
        UpdateTestMediumQuantity: 0,
        UpdateKraftLinerQuantity: 0,
        UpdateKraftMediumQuantity: 0,
        UpdateWhiteTopQuantity: 0,
        UpdateAllQuantity: 0
      }
  }

  onSubmit (form: NgForm){
    //this.validateForm(form.value)
    if (this.isValid == false){
      this.dialogRef.close();
    }
    else 
    {
      if(this.updatePaperCost.UpdateTestLiner == false && this.updatePaperCost.UpdateTestMedium == false &&
        this.updatePaperCost.UpdateKraftLiner == false && this.updatePaperCost.UpdateKraftMedium == false &&
        this.updatePaperCost.UpdateWhiteTop == false && this.updatePaperCost.UpdateAll == false &&
        this.updatePaperCost.UpdateTestLinerQuantity == 0 && this.updatePaperCost.UpdateTestMediumQuantity == 0 &&
        this.updatePaperCost.UpdateKraftLinerQuantity == 0 && this.updatePaperCost.UpdateKraftMediumQuantity == 0 &&
        this.updatePaperCost.UpdateWhiteTopQuantity == 0 && this.updatePaperCost.UpdateAllQuantity == 0){
          this.toastr.error('Por favor, confirmar el incremento de costo en todos los papeles!', 'Corrugated Quote.')
          exit;
      }
      else
      {
        if(this.validateForm(form.value)==true) {
          this.onUpdatePaperCost();
          
          this.dialogRef.close();
        }
        //};
        this.isValid = true;
      }
    };
  };

  onClose(){
    this.isValid = false;
  }

  onOptionPercentaje(){
      this.updatePaperCost.UpdateTestLinerQuantity = 0
      this.updatePaperCost.UpdateTestMediumQuantity = 0
      this.updatePaperCost.UpdateKraftLinerQuantity = 0
      this.updatePaperCost.UpdateKraftMediumQuantity = 0
      this.updatePaperCost.UpdateWhiteTopQuantity = 0
      this.updatePaperCost.UpdateAllQuantity = 0
  }

  onCheckTestLiner(){
    this.updatePaperCost.UpdateAll = false
  }

  onCheckAllPaper(){
      this.updatePaperCost.UpdateTestLiner = false
      this.updatePaperCost.UpdateTestMedium = false
      this.updatePaperCost.UpdateKraftLiner = false
      this.updatePaperCost.UpdateKraftMedium = false
      this.updatePaperCost.UpdateWhiteTop = false
  }

  onUpdatePaperCost(){
    if (this.updatePaperCost.increasePercentaje == "option2") {
      if(this.updatePaperCost.UpdateTestLiner = true){
        this.paperService.putPaperCost("Test Liner", this.updatePaperCost.UpdateTestLinerQuantity, 0, this.paperService.paperList).subscribe(res => {
          this.toastr.success('Actualización exitosa!: Test Liner', 'Corrugated Quote.');
        });
      };
      if(this.updatePaperCost.UpdateTestMedium = true){
        this.paperService.putPaperCost("Test Medium", this.updatePaperCost.UpdateTestMediumQuantity, 0, this.paperService.paperList).subscribe(res => {
          this.toastr.success('Actualización exitosa!: Test Medium', 'Corrugated Quote.');
        });
      };
      if(this.updatePaperCost.UpdateKraftLiner = true){
        this.paperService.putPaperCost("Kraft Liner", this.updatePaperCost.UpdateKraftLinerQuantity, 0, this.paperService.paperList).subscribe(res => {
          this.toastr.success('Actualización exitosa!: Kraft Liner', 'Corrugated Quote.');
        });
      };
      if(this.updatePaperCost.UpdateKraftMedium = true){
        this.paperService.putPaperCost("Kraft Medium", this.updatePaperCost.UpdateKraftMediumQuantity, 0, this.paperService.paperList).subscribe(res => {
          this.toastr.success('Actualización exitosa!: Kraft Medium', 'Corrugated Quote.');
        });
      };
      if(this.updatePaperCost.UpdateWhiteTop = true){
        this.paperService.putPaperCost("White Top", this.updatePaperCost.UpdateWhiteTopQuantity, 0, this.paperService.paperList).subscribe(res => {
          this.toastr.success('Actualización exitosa!: White Top', 'Corrugated Quote.');
        });
      };
      if(this.updatePaperCost.UpdateAll = true){
        this.paperService.putPaperCost("PaperType", this.updatePaperCost.UpdateAllQuantity, 0, this.paperService.paperList).subscribe(res => {
          this.toastr.success('Actualización exitosa!: All Paper', 'Corrugated Quote.');
        });
      };
    }
    else {
      if(this.updatePaperCost.UpdateTestLiner = true){
        this.paperService.putPaperCost("Test Liner", 0, (this.updatePaperCost.UpdateTestLinerQuantity / 100), this.paperService.paperList).subscribe(res => {
          this.toastr.success('Actualización exitosa!: Test Liner', 'Corrugated Quote.');
        });
      };
      if(this.updatePaperCost.UpdateTestMedium = true){
        this.paperService.putPaperCost("Test Medium" , 0, (this.updatePaperCost.UpdateTestMediumQuantity / 100), this.paperService.paperList).subscribe(res => {
          this.toastr.success('Actualización exitosa!: Test Medium', 'Corrugated Quote.');
        });
      };
      if(this.updatePaperCost.UpdateKraftLiner = true){
        this.paperService.putPaperCost("Kraft Liner", 0, (this.updatePaperCost.UpdateKraftLinerQuantity / 100), this.paperService.paperList).subscribe(res => {
          this.toastr.success('Actualización exitosa!: Kraft Liner', 'Corrugated Quote.');
        });
      };
      if(this.updatePaperCost.UpdateKraftMedium = true){
        this.paperService.putPaperCost("Kraft Medium", 0, (this.updatePaperCost.UpdateKraftMediumQuantity / 100), this.paperService.paperList).subscribe(res => {
          this.toastr.success('Actualización exitosa!: Kraft Medium', 'Corrugated Quote.');
        });
      };
      if(this.updatePaperCost.UpdateWhiteTop = true){
        this.paperService.putPaperCost("White Top", 0, (this.updatePaperCost.UpdateWhiteTopQuantity / 100), this.paperService.paperList).subscribe(res => {
          this.toastr.success('Actualización exitosa!: White Top', 'Corrugated Quote.');
        });
      };
      if(this.updatePaperCost.UpdateAll = true){
        this.paperService.putPaperCost("PaperType", 0, (this.updatePaperCost.UpdateAllQuantity / 100), this.paperService.paperList).subscribe(res => {
          this.toastr.success('Actualización exitosa!: Todos los papeles', 'Corrugated Quote.');
        });
      };
    }
  };

  validateForm(formUpdatePaperCost: Updatepapercost){
    this.onvalidateForm();
    if (this.isValid == false){
      exit;
    }else {
      this.isValid = true;
    };
    return this.isValid;
  };

  onvalidateForm(){
    if (this.updatePaperCost.UpdateTestLiner == true && this.updatePaperCost.UpdateTestLinerQuantity == 0){
      this.getformFocus("UpdateTestLinerQuantity", "Ingresa el incremento de costo para el Test liner")
    };
    if (this.updatePaperCost.UpdateTestLiner == false && this.updatePaperCost.UpdateTestLinerQuantity > 0){
      this.getformFocus("UpdateTestLiner", "Confirma el incremento de costo para el Test liner")
    };
    if (this.updatePaperCost.UpdateTestMedium == true && this.updatePaperCost.UpdateTestMediumQuantity == 0){
      this.getformFocus("UpdateTestMediumQuantity", "Ingresa el incremento de costo para el Test medium")
    };
    if (this.updatePaperCost.UpdateTestMedium == false && this.updatePaperCost.UpdateTestMediumQuantity > 0){
      this.getformFocus("UpdateTestMedium", "Confirma el incremento de costo para el Test medium")
    };
    if (this.updatePaperCost.UpdateKraftLiner == true && this.updatePaperCost.UpdateKraftLinerQuantity == 0){
      this.getformFocus("UpdateKraftLinerQuantity", "Ingresa el incremento de costo para el Kraft liner")
    };
    if (this.updatePaperCost.UpdateKraftLiner == false && this.updatePaperCost.UpdateKraftLinerQuantity > 0){
      this.getformFocus("UpdateKraftLiner", "Confirma el incremento de costo para el Kraft liner")
    };
    if (this.updatePaperCost.UpdateKraftMedium == true && this.updatePaperCost.UpdateKraftMediumQuantity == 0){
      this.getformFocus("UpdateKraftMediumQuantity", "Ingresa el incremento de costo para el Kraft medium")
    };
    if (this.updatePaperCost.UpdateKraftMedium == false && this.updatePaperCost.UpdateKraftMediumQuantity > 0){
      this.getformFocus("UpdateKraftMedium", "Confirma el incremento de costo para el Kraft medium")
    };
    if (this.updatePaperCost.UpdateWhiteTop == true && this.updatePaperCost.UpdateWhiteTopQuantity == 0){
      this.getformFocus("UpdateWhiteTopQuantity", "Ingresa el incremento de costo para el White Top ")
    };
    if (this.updatePaperCost.UpdateWhiteTop == false && this.updatePaperCost.UpdateWhiteTopQuantity > 0){
      this.getformFocus("UpdateWhiteTop", "Confirma el incremento de costo para el White top")
    };
    if (this.updatePaperCost.UpdateAll == true && this.updatePaperCost.UpdateAllQuantity == 0){
      this.getformFocus("UpdateAllQuantity", "Ingresa el incremento de costo para todos los papeles")
    };
    if (this.updatePaperCost.UpdateAll == false && this.updatePaperCost.UpdateAllQuantity > 0){
      this.getformFocus("UpdateAll", "Confirma el incremento de costo para todos los papeles")
    };
    /* if (this.paperService.paperList.Weight == 0 || this.paperService.paperList.Weight == null){
      this.getformFocus("Weight", "Paper Weight")
    }; */
  };

  getformFocus(formID: string, nameForm: string) {
    this.toastr.error('Por favor, ' + nameForm, 'Corrugated Quote.');
    document.getElementById(formID).focus();
    this.isValid = false;
    return this.isValid;
  };

probaron(){
  this.onvalidateForm()
}

}
