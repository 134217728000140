import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuoteItem } from 'src/app/shared/quote-item.model';
import { QuoteService } from 'src/app/shared/quote.service';
import { Board } from 'src/app/shared/board.model';
import { BoardService } from 'src/app/shared/board.service';
import { Boardcost } from 'src/app/shared/boardcost.model';
import { BoardcostService } from 'src/app/shared/boardcost.service';
import { TapeService } from 'src/app/shared/tape.service';
import { Tape } from 'src/app/shared/tape.model';
import { WorkstationService } from 'src/app/shared/workstation.service';
import { Workstation } from 'src/app/shared/workstation.model';
import { CostrateService } from 'src/app/shared/costrate.service';
import { Costrate } from 'src/app/shared/costrate.model';
import { AuxiliaryService } from 'src/app/shared/auxiliary.service';
import { Auxiliary } from 'src/app/shared/auxiliary.model';
import { Product } from 'src/app/shared/product.model';
import { ProductService } from 'src/app/shared/product.service';


import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { TruckTypeService } from 'src/app/shared/TruckType.service';
import { TruckType } from 'src/app/shared/TruckType.model';

@Component({
  selector: 'app-quote-sheet',
  templateUrl: './quote-sheet.component.html',
  styleUrls: []
})
export class QuoteSheetComponent implements OnInit {
  formData: QuoteItem;
  boardList: Board [];
  boardCost: Boardcost;
  tapeList: Tape [];
  costrateList: Costrate [];
  workstationList: Workstation [];
  auxiliaryList: Auxiliary [];
  productList: Product [];
  truckTypeList: TruckType [];

  isValid: boolean = true;
  mostrarPallet: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data,
    public dialogRef: MatDialogRef<QuoteSheetComponent>,
    private quoteService: QuoteService, private boardService: BoardService, 
    private truckTypeService: TruckTypeService,
    private tapeService: TapeService, private boardcostService: BoardcostService, 
    private workstationService: WorkstationService, private costrateService: CostrateService, 
    private auxiliaryService: AuxiliaryService, private productService: ProductService,
    private toastr: ToastrService) { }

    ngOnInit(): void {
      this.boardService.getBoardListOneAlternative("quoteSales", 1).then(res => this.boardList = res as Board[]);
      this.tapeService.getTapeList().then(res => this.tapeList = res as Tape[]);
      this.workstationService.getWorkstationList().then(res => this.workstationList = res as Workstation[]);
      this.costrateService.getCostRateList().then(res => this.costrateList = res as Costrate[]);
      this.auxiliaryService.getAuxiliaryList().then(res => this.auxiliaryList = res as Auxiliary[]);
      this.productService.getProductList().then(res => this.productList = res as Product[]);
      this.truckTypeService.getTruckTypeList().then(res => this.truckTypeList = res as TruckType[]);
      
      if(this.quoteService.formData.CargoType == "Con Tarima"){
        this.mostrarPallet = true;
      } else{
        this.mostrarPallet = false;
      };

      if (this.data.quoteItemIndex==null)
        this.formData = {
          QuoteItemID: null, 
          QuoteID: this.data.QuoteID,
          FluteID: 0,
          BoardID: null,
          StyleID: 6,
          StyleName: "Lamina de Carton Corrugado",
          Overlap: 0,
          ClosureID: 1,
          Register:"",
          DieType: "",
          TapeID: 1,
          TapeQuantity: 0,
          Width: 0,
          Length: 0,
          Depth: 0,
          Output: 0,
          outPutRDCL: 0,
          outPutRDCW: 0,
          Score: '',
          WidthBoard: 0,
          LengthBoard: 0,
          AddTrim:0,
          BoxArea: 0,
          DensityBoard: 0,
          BoxWeight: 0,
          AreaShipment: 0,
          TotalAreaRequired: 0,
          TotalWeightRequired: 0,
          InkNumber: 0,
          InkID01: null,
          PerInk01: 0,
          PerPrintingPlate01: 0,
          InkID02: null,
          PerInk02: 0,
          PerPrintingPlate02: 0,
          InkID03: null,
          PerInk03: 0,
          PerPrintingPlate03: 0,
          InkID04: null,
          PerInk04: 0,
          PerPrintingPlate04: 0,
          InkID05: null,
          PerInk05: 0,
          PerPrintingPlate05: 0,
          InkID06: null,
          PerInk06: 0,
          PerPrintingPlate06: 0,
          BoxBundle: 0,
          StrapDirection: "Sin Fleje",
          StrapNumber: 0,
          BundleWidthPallet: 0,
          BundleAlongPallet: 0,
          Layer: 0,
          StrechFilm: false,
          Pallet: false,
          PlateDie: false,
          ProductionTimeCorr: 0,
          ProductionTimeConv: 0,
          CostMPNeto: 0,
          CostMP: 0,
          CostAuxCorr: 0,
          CostCorrToConv: 0,
          CostFixed: 0,
          SubTotCorrugator: 0,
          CostMacConverting: 0,
          CostInk: 0,
          CostPlateTotal: 0,
          CostDieTotal: 0,
          CostPlate: 0,
          CostDie: 0,
          CostPVA: 0,
          CostTape: 0,
          CostCover: 0,
          CostFilm: 0,
          CostBoardPackaging: 0,
          CostPallet: 0,
          CostStrapping5mm: 0,
          CostStrapping12mm: 0,
          CostWasteConv: 0,
          SubTotConverting: 0,
          //consumptions materials
          ConsStarch: 0,
          ConsCoating: 0,
          ConsPVA: 0,
          ConsDieRDC: 0,
          ConsDieFDC: 0,
          ConsStringKing: 0,
          ConsOpenSesame: 0,
          ConsCover: 0,
          ConsInk01: 0,
          ConsInk02: 0,
          ConsInk03: 0,
          ConsInk04: 0,
          ConsInk05: 0,
          ConsInk06: 0,
          ConsPlate01: 0,
          ConsPlate02: 0,
          ConsPlate03: 0,
          ConsPlate04: 0,
          ConsPlate05: 0,
          ConsPlate06: 0,
          ConsStrapping5mm: 0,
          ConsStrapping12mm: 0,
          ConsStrechFilm: 0,
          ConsPallet: 0,
          ConsBoardPackaging: 0,
          ConsWater: 0,
          ConsGasForklift: 0,
          ConsGasBoiler: 0,
          //fields for partitions 20/11/2023
           //fields for partitions 20/11/2023
          PartitionKit: false,
          QuantityDivLong: 0,
          QuantityDivShort: 0,
          BoardIDPartition: '',
          TypeMachPartition: '',
          AssemblyPartition: false,
          WidthDivLong: 0,
          LengthDivLong: 0,
          WidthDivShort: 0,
          LengthDivShort: 0,
          BoxAreaDivLong: 0,
          BoxAreaDivShort: 0,
          WidthBoardDivLong: 0,
          LengthBoardDivLong: 0,
          WidthBoardDivShort: 0,
          LengthBoardDivShort: 0,
          OutputDivLong: 0,
          OutputDivShort: 0,
          DensityBoardPartition: 0,
          TotalAreaRequiredDivLong: 0,
          TotalAreaRequiredDivShort: 0,
          //separadores horizontales
          SeparatorTop: false,
          SeparatorCenter: false,
          SeparatorDown: false,
          QuantitySepTop: 0,
          QuantitySepCenter: 0,
          QuantitySepDown: 0,
          BoardIDSepTop: '',
          BoardIDSepCenter: '',
          BoardIDSepDown: '',
          TypeMachSepTop: '',
          TypeMachSepCenter: '',
          TypeMachSepDown: '',
          WidthSepTop: 0,
          LengthSepTop: 0,
          WidthSepCenter: 0,
          LengthSepCenter: 0,
          WidthSepDown: 0,
          LengthSepDown: 0,
          BoxAreaSepTop: 0,
          BoxAreaSepCenter: 0,
          BoxAreaSepDown: 0,
          WidthBoardSepTop: 0,
          LengthBoardSepTop: 0,
          WidthBoardSepCenter: 0,
          LengthBoardSepCenter: 0,
          WidthBoardSepDown: 0,
          LengthBoardSepDown: 0,
          OutputSepTop: 0,
          OutputSepCenter: 0,
          OutputSepDown: 0,
          DensityBoardSetTop: 0,
          DensityBoardSetCenter: 0,
          DensityBoardSetDown: 0,
          TotalAreaRequiredSepTop: 0,
          TotalAreaRequiredSepCenter: 0,
          TotalAreaRequiredSepDown: 0,
    //end fields partitions
          Total:0
        }
       else
          this.formData = Object.assign({}, this.quoteService.quoteItems[this.data.quoteItemIndex]);
          if (this.formData.BoardID == null){
            this.boardcostService.getBoardCostListId("C-19-K-MI-AI-XX-1").subscribe((boardCosts: Boardcost) => {
              this.boardCost = boardCosts}, error => console.error(error));
          } else {
            this.boardcostService.getBoardCostListId(this.formData.BoardID).subscribe((boardCosts: Boardcost) => {
              this.boardCost = boardCosts}, error => console.error(error));
          };
    }
  
    onSubmit (form: NgForm){
    
      if (this.validateForm(form.value)){
        if (this.data.quoteItemIndex == null)
          //this.quoteService.quoteItems.push(form.value);
          this.quoteService.quoteItems.push(this.formData);
        else
          //this.quoteService.quoteItems[this.data.quoteItemIndex] = form.value;
          this.quoteService.quoteItems[this.data.quoteItemIndex] = this.formData;
          this.dialogRef.close();
      };
      this.isValid = true;
    };
    
  //validateForm(formData: QuoteItem){
    validateForm(formData: QuoteItem){
      this.onvalidateForm();
      if (this.isValid == false){
        exit;
      }else {
        this.isValid = true;
        this.quoteService.formData.BoardID = this.formData.BoardID;
        this.onCargoTypeSheet()
        this.onBoardCost();
        //formData = this.formData;
       /*  for (let i in this.formData){
          this.quoteItems[i].ItemID=Number(this.quoteItems[i].ItemID);
          this.quoteItems[i].Quantity=Number(this.quoteItems[i].Quantity);   
        } */
        
      };
      return this.isValid;
      
    };
  
    updateToleranceBoard(tolerance){ 
      if (tolerance.selectedIndex == 0 ) {
  
        this.formData.FluteID = 0;
        this.formData.BoardID = '';
      } else {
        this.formData.FluteID = this.boardList[tolerance.selectedIndex-1].FluteID;
        this.formData.BoardID = this.boardList[tolerance.selectedIndex-1].BoardID;
        this.formData.DensityBoard = this.boardList[tolerance.selectedIndex-1].Density;
        //this.onLayer();
        this.onCargoTypeSheet()
        this.ongetBoardCost();
      };
    };
  
    onDevelopmentBox (){
        this.formData.LengthBoard = this.formData.Length
        this.formData.WidthBoard = this.formData.Width
        this.formData.BoxArea = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard)/1000000).toFixed(4));
        this.formData.BoxWeight = parseFloat((this.formData.DensityBoard * this.formData.BoxArea).toFixed(2));
        this.formData.AreaShipment = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard * this.quoteService.formData.BoxShipment)/1000000000).toFixed(4));
        this.formData.TotalAreaRequired = parseFloat((this.formData.LengthBoard * this.formData.WidthBoard/1000000 * (1 + (this.formData.AddTrim/100))).toFixed(4));
        this.formData.TotalWeightRequired = parseFloat(((this.formData.DensityBoard * this.formData.LengthBoard * this.formData.WidthBoard /1000000) * (1 + (this.formData.AddTrim/100))).toFixed(2));
        //this.onLayer();
        this.ongetBoardCost();
    };
    
    //call costs of Board
    onBoardCost(){
      let productionTimeCorr: number; let indexSelected: number;
      let costStrapping02: number = 0; let thickness: number = 0;
      let costTape: number = 0; let costFilm: number = 0; 
      let costBoardPackaging: number = 0; let costPallet: number = 0;
      let smOrder: number;
      let consTape: number = 0;
      let consStrapping12mm: number = 0; let consPallet: number = 0;
      let consStrechFilm: number = 0; let consBoardPackaging: number = 0;
  
      //in this block, we are going to calculated the production Time for corrugator
      smOrder = this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired
      indexSelected = this.boardList.findIndex(typeDensity => typeDensity.BoardID == this.formData.BoardID);    
      if (this.boardList[indexSelected].TypeDensity == 0) {
        productionTimeCorr = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * (1 + +(this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenLow * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
      } else if(this.boardList[indexSelected].TypeDensity == 1) {
        productionTimeCorr = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * (1 + +(this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenMed * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
      } else {
        productionTimeCorr = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * (1 + (this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenHig * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
      };
      
      //in this block, we will calculate production cost
      productionTimeCorr = productionTimeCorr + 1;
      //this.formData.ProductionTimeCorr = productionTimeCorr;
      if (this.boardCost.BoardID != null){
        this.formData.CostMP = parseFloat((this.boardCost.BoardPaperCostMM2 * (1 + +(this.boardList[indexSelected].WasteCorrugatorOtherApp / 100)) * this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder / 1000).toFixed(4));
        this.formData.CostMPNeto = this.formData.CostMP;
      if (smOrder <= 1000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 2).WasteTimeCorr01;
        this.formData.CostMP = this.formData.CostMP * (1 + +(this.productList.find(smorder => smorder.ProductID == 2).Waste01 / 100));
      } else if(smOrder <= 2000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 2).WasteTimeCorr02;
        this.formData.CostMP = this.formData.CostMP * (1 + +(this.productList.find(smorder => smorder.ProductID == 2).Waste02 / 100));
      } else if(smOrder <= 3000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 2).WasteTimeCorr03;
        this.formData.CostMP = this.formData.CostMP * (1 + +(this.productList.find(smorder => smorder.ProductID == 2).Waste03 / 100));
      } else if(smOrder <= 4000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 2).WasteTimeCorr04;
        this.formData.CostMP = this.formData.CostMP * (1 + +(this.productList.find(smorder => smorder.ProductID == 2).Waste04 / 100));
      };
      this.formData.ProductionTimeCorr = productionTimeCorr;
        
        //The CostAuxCorrM2 is the cost by production sheetboard and sales sheetboard.
        this.formData.CostAuxCorr = parseFloat(((this.boardCost.StarchCostMM2 + +this.boardCost.CoatingCostMM2 + +this.boardCost.OtherAuxCostMM2) * (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder / 1000)).toFixed(4));        
        this.formData.CostCorrToConv = parseFloat(((this.costrateList[0].DirLaborCostHR + +this.costrateList[0].IndLaborCostHR + +this.costrateList[0].MaintenanceCostHR + +this.costrateList[0].EnergyCostoHR + +this.costrateList[0].WaterCostHR + +this.costrateList[0].GasCostHR + +this.costrateList[0].IndManCostHR) * (productionTimeCorr / 60)).toFixed(4));
        this.formData.CostFixed = parseFloat((this.costrateList[0].CorpMiscCostHR * productionTimeCorr / 60).toFixed(4));
        
        //in this block, we are going to calculated the Strech Film cost.
        if (this.formData.StrechFilm == true){
          consStrechFilm =  (this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatRatCon) * (1 + (this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatWaste / 100)) * (this.quoteService.formData.PalletProduced);
          costFilm = this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatCost;
          this.formData.CostFilm = parseFloat((costFilm * consStrechFilm).toFixed(4));
        }else {
          this.formData.CostFilm = 0;
          consStrechFilm = 0;
        };
  
        //in this block, we are gonna calculated the Board for Packaging cost
        consBoardPackaging = this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatRatCon * (1 + (this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatWaste / 100)) * (this.quoteService.formData.PalletProduced);
        costBoardPackaging = this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatCost;
        this.formData.CostBoardPackaging = parseFloat((costBoardPackaging * consBoardPackaging).toFixed(4));
        //in this block, we are gonna calculated the Pallet cost
        if (this.formData.Pallet == true){
          consPallet = Math.ceil(this.quoteService.formData.PalletProduced);
          costPallet = this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatCost * this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatRatCon * (1 + (this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatWaste / 100));
          this.formData.CostPallet = parseFloat((costPallet * consPallet).toFixed(4));
        }else {
          this.formData.CostPallet = 0;
          consPallet = 0;
        };
        
        //in this block we are calculated the Tape
        if (this.formData.TapeID != 1){
          costTape = this.auxiliaryList.find(tape => tape.AuxMatID == this.formData.TapeID).AuxMatCost;
          consTape = this.auxiliaryList.find(constape => constape.AuxMatID == this.formData.TapeID).AuxMatRatCon * (1 + (this.auxiliaryList.find(constape => constape.AuxMatID == this.formData.TapeID).AuxMatWaste / 100));
          consTape = parseFloat((consTape * this.formData.LengthBoard * this.quoteService.formData.AverageOrder * this.formData.TapeQuantity / 1000).toFixed(4));
          this.formData.CostTape = parseFloat((costTape * consTape).toFixed(4));
        } else {
          this.formData.CostTape = 0;
          this.formData.TapeQuantity = 0;
          consTape = 0;
        };
             
        //in this block we are going to calculated the Strapping of 5 and 12 mm.
        thickness = this.boardList.find(thicknes => thicknes.BoardID == this.formData.BoardID).Thickness; 
        costStrapping02 = this.auxiliaryList.find(Strapping02 => Strapping02.AuxMatID == 2).AuxMatCost * (1 + (this.auxiliaryList.find(Strapping02 => Strapping02.AuxMatID == 2).AuxMatWaste / 100));
        consStrapping12mm = parseFloat((20 * this.quoteService.formData.PalletProduced).toFixed(2));        
        this.formData.CostStrapping12mm = parseFloat((costStrapping02 * consStrapping12mm / 1000).toFixed(4));        
        this.formData.CostWasteConv = 0;
        this.formData.SubTotCorrugator = parseFloat((this.formData.CostMP + +this.formData.CostAuxCorr + +this.formData.CostCorrToConv + +this.formData.CostFixed + +this.formData.CostTape + +this.formData.CostFilm + +this.formData.CostPallet + +this.formData.CostBoardPackaging + +this.formData.CostStrapping12mm + +this.formData.CostWasteConv).toFixed(4));     
        this.formData.SubTotConverting = 0; 
        this.formData.Total = parseFloat((this.formData.SubTotConverting + this.formData.SubTotCorrugator).toFixed(2));
      
        //consumptions materials
      if (this.boardList[indexSelected].TypeStarch == "AI"){
        this.formData.ConsStarch = parseFloat(((this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired) * ((this.auxiliaryList.find(consstarch => consstarch.AuxMatID == 13).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(consstarch => consstarch.AuxMatID == 13).AuxMatWaste)/100))).toFixed(2));
      }else {
        this.formData.ConsStarch = parseFloat(((this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired) * ((this.auxiliaryList.find(consStarch => consStarch.AuxMatID == 14).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(consStarch => consStarch.AuxMatID == 14).AuxMatWaste)/100))).toFixed(2));
      };
      if (this.boardList[indexSelected].CoatingTo == "XX"){
        this.formData.ConsCoating = 0;
      } else if(this.boardList[indexSelected].CoatingTo == "RL"){
            this.formData.ConsCoating = parseFloat(((0.3334 * this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired) * ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatWaste)/100))).toFixed(2));
      } else if (this.boardList[indexSelected].CoatingTo == "RLM") {
            this.formData.ConsCoating = parseFloat(((0.6664 * this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired) * ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatWaste)/100))).toFixed(2));
      } else if(this.boardList[indexSelected].CoatingTo == "RLMM"){
          this.formData.ConsCoating = parseFloat(((this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired) * ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatWaste)/100))).toFixed(2));
      };
      this.formData.ConsPVA = 0;
      this.formData.ConsDieFDC = 0;
      this.formData.ConsDieRDC = 0;
      if(this.formData.TapeID == 4){
        this.formData.ConsStringKing = 0;
        this.formData.ConsOpenSesame = parseFloat(consTape.toFixed(2));
      }else if (this.formData.TapeID == 5){
        this.formData.ConsOpenSesame = 0;
        this.formData.ConsStringKing = parseFloat(consTape.toFixed(2));
      } else {
        this.formData.ConsStringKing = 0;
        this.formData.ConsOpenSesame = 0;
      };
      this.formData.ConsCover = 0; 
      this.formData.ConsInk01 = 0;
      this.formData.ConsInk02 = 0;
      this.formData.ConsInk03 = 0;
      this.formData.ConsInk04 = 0;
      this.formData.ConsInk05 = 0;
      this.formData.ConsInk06 = 0;
      this.formData.ConsPlate01 = 0;
      this.formData.ConsPlate02 = 0;
      this.formData.ConsPlate03 = 0;
      this.formData.ConsPlate04 = 0;
      this.formData.ConsPlate05 = 0;
      this.formData.ConsPlate06 = 0;
      this.formData.ConsStrapping5mm = 0;
      this.formData.ConsStrapping12mm = consStrapping12mm;
      this.formData.ConsStrechFilm = consStrechFilm;
      this.formData.ConsPallet = consPallet;
      this.formData.ConsBoardPackaging = consBoardPackaging;
      this.formData.ConsWater = parseFloat(((this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired) * ((this.auxiliaryList.find(conswater => conswater.AuxMatID == 16).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(conswater => conswater.AuxMatID == 16).AuxMatWaste)/100)) / 1000).toFixed(2));
      this.formData.ConsGasForklift = parseFloat(((this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired) * ((this.auxiliaryList.find(consgasforklift => consgasforklift.AuxMatID == 17).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(consgasforklift => consgasforklift.AuxMatID == 17).AuxMatWaste)/100))).toFixed(2));
      this.formData.ConsGasBoiler = parseFloat(((this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired / 1000) * ((this.auxiliaryList.find(consgasboiler => consgasboiler.AuxMatID == 18).AuxMatRatCon)) * (1 + ((this.auxiliaryList.find(consgasboiler => consgasboiler.AuxMatID == 18).AuxMatWaste)/100))).toFixed(2));
      };
    };
  
    ongetBoardCost(){
      if(this.formData.BoardID != '')
      this.boardcostService.getBoardCostListId(this.formData.BoardID).subscribe((boardCosts: Boardcost) => {
        this.boardCost = boardCosts; this.onBoardCost()}, error => console.error(error));
        ;
    };
  
  //call cost when the value change en checkbox
    ongetCheckChange() {
       this.ongetBoardCost();
    };
  
  //call onBoardCost
    onChangeTape(){
      this.ongetBoardCost();
      if(this.formData.TapeID ==1){
        this.formData.TapeQuantity = 0
      };
    };
  
    onvalidateForm(){
      if (this.formData.BoardID == null){
        this.getformFocus("BoardID", "la Resistencia")
      };
      if (this.formData.Width <= 0){
        this.getformFocus("Width", "el Ancho")
      };
      if (this.formData.Length <= 0){
        this.getformFocus("Length", " el Largo")
      };
    };
  
    getformFocus(formID: string, nameForm: string) {
      this.toastr.error('Por favor, Seleccione ' + nameForm, 'Corrugated Quote.');
      document.getElementById(formID).focus();
      this.isValid = false;
      return this.isValid;
    };
  
    /* onLayer(){
      if(this.formData.FluteID == 1){
      this.formData.Layer = 800;
      }else if(this.formData.FluteID == 2){
        this.formData.Layer = 700;
      }else if(this.formData.FluteID == 3){
        this.formData.Layer = 600;
      }else if(this.formData.FluteID == 4){
        this.formData.Layer = 400;
      }else if(this.formData.FluteID == 7){
        this.formData.Layer = 250;
      }else if(this.formData.FluteID == 13){
        this.formData.Layer = 200;
      };
    }; */

    onCargoTypeSheet(){
      let areaBundle: number; let numberBundle: number; let volBundle: number = 0;
      let heightBundle: number; let thickness: number; let volCargo: number;
      //Variables paletizations
      let xlengthPlengthB: number;  let ywidthPlengthB: number; 
      let wlengthPwidthB: number;  let zwidthPwidthB: number;
      let lengthB: number; let widthB: number; //let lengthBundle: number; let widthBundle: number;
      let lengthP: number = 1.2; let widthP: number = 1.05; let heightP: number = 1.2;
      let layerP: number; //let numPalletCargo: number; 
      let maxBundle01: number; let maxBundle02: number; let maxBundleP: number;
      let numBundleWidth: number; let numBundleLength: number;
      let lengthPalletF: number; let widthPalletF: number; let heightPalletF: number;
      let blocksB: number; let blocks02B: number;
      let numBunBlock: number; let numBunBlocks02: number;
      let lengthCargo: number;
      let boxPallet: number; let indexTruck: number = 0;
      let indexTruckSelected: number = 0;
      //end
      //lineal meters, caliper.
      this.quoteService.NewOrEdit = true;
      thickness = this.boardList.find(thicknes => thicknes.BoardID == this.formData.BoardID).Thickness / 1000;
      this.quoteService.lengthBundle = parseFloat((this.formData.LengthBoard / 1000).toFixed(3));
      this.quoteService.widthBundle = parseFloat((this.formData.WidthBoard / 1000).toFixed(3));
      areaBundle = parseFloat((this.quoteService.lengthBundle * this.quoteService.widthBundle).toFixed(3));
      heightBundle = parseFloat((thickness).toFixed(3));
  
        //Volum one bundleSheet
        volBundle = parseFloat((areaBundle * heightBundle).toFixed(3));
        //number bundle for shipment/freigh
        indexTruckSelected = this.truckTypeList.findIndex(indextruck => indextruck.TruckID == this.quoteService.formData.TruckID); 
        //unitization
        if (this.quoteService.formData.CargoType == "Granel"){
          widthP = 1.15;
          lengthP = 1.20;
          heightP = 1.20;
          layerP = Math.round(heightP / heightBundle);
          this.formData.Layer = layerP;
          numberBundle = Math.ceil(this.quoteService.formData.BoxShipment / this.formData.Layer)
          volCargo = volBundle * numberBundle * this.formData.Layer;
          //this part is for determineted the Ideal Track
          for(let index = 0 ; index < this.truckTypeList.length && volCargo <= this.truckTypeList[index].CapacityM3; index++){
            this.quoteService.formData.IdealTruck = this.truckTypeList[index].TruckName;
            this.quoteService.formData.UsedCapacityTruck = Math.ceil(volCargo * 100 / this.truckTypeList[index].CapacityM3);
            this.quoteService.formData.TruckID = this.truckTypeList[index].TruckID;
          };
  
          this.quoteService.formData.PalletID = 0;
          //test
          this.quoteService.fullCapacity = Math.floor(this.truckTypeList[0].CapacityM3 / volBundle) * this.formData.Layer;
          this.quoteService.trailerCapacity = Math.floor(this.truckTypeList[1].CapacityM3 / volBundle) * this.formData.Layer;
          this.quoteService.tortonCapacity = Math.floor(this.truckTypeList[2].CapacityM3 / volBundle) * this.formData.Layer;
          this.quoteService.rabonCapacity = Math.floor(this.truckTypeList[3].CapacityM3 / volBundle) * this.formData.Layer;
          this.quoteService.camionetaCapacity = Math.floor(this.truckTypeList[4].CapacityM3 / volBundle) * this.formData.Layer;
         
          heightPalletF = parseFloat((layerP * heightBundle).toFixed(3));
          lengthB = Math.max(this.quoteService.lengthBundle, this.quoteService.widthBundle);
          widthB = Math.min(this.quoteService.lengthBundle, this.quoteService.widthBundle);
  
            areaBundle = this.quoteService.lengthBundle * this.quoteService.widthBundle;
            xlengthPlengthB = Math.floor(lengthP / lengthB);
            ywidthPlengthB = Math.floor(widthP / lengthB);
            wlengthPwidthB = Math.floor(lengthP / widthB);
            zwidthPwidthB = Math.floor(widthP / widthB);
  
            //number maximun boundle per pallet
            maxBundle01 = xlengthPlengthB * zwidthPwidthB;
            maxBundle02 = ywidthPlengthB * wlengthPwidthB;
  
            //end
            if ((lengthB + widthB) <= widthP) {
              blocksB = Math.floor(widthP / (lengthB + widthB))
              blocks02B = Math.floor(widthP / (lengthB + (2 * widthB)))
              numBunBlock = blocksB * (xlengthPlengthB + wlengthPwidthB)
              numBunBlocks02 = blocks02B * (wlengthPwidthB + (2 * xlengthPlengthB))
              maxBundleP = Math.max(numBunBlock, numBunBlocks02, maxBundle01, maxBundle02)
  
  
              if ((maxBundle01 >= maxBundle02) && (maxBundle01 >= numBunBlock) && (maxBundle01 >= numBunBlocks02)){
                maxBundleP = maxBundle01;
                numBundleLength = xlengthPlengthB;
                numBundleWidth = zwidthPwidthB;
                lengthPalletF = numBundleLength * lengthB
                widthPalletF = numBundleWidth * widthB
              }else if ((maxBundle02 >= maxBundle01) && (maxBundle02 >= numBunBlock) && (maxBundle02 >= numBunBlocks02)){
                maxBundleP = maxBundle02;
                numBundleLength = wlengthPwidthB;
                numBundleWidth = ywidthPlengthB;
                lengthPalletF = numBundleLength * widthB;
                widthPalletF = numBundleWidth * lengthB;
              }else if ((numBunBlock >= maxBundle01) && (numBunBlock >= maxBundle02) && (numBunBlock >= numBunBlocks02)){
                maxBundleP = numBunBlock;
                numBundleLength = wlengthPwidthB;
                numBundleWidth = 2 * blocksB;
                lengthPalletF = numBundleLength * widthB;
                widthPalletF = blocksB * (lengthB + widthB);
              }else if ((numBunBlocks02 >= maxBundle01) && (numBunBlocks02 >= maxBundle02) && (numBunBlocks02 >= numBunBlock)){
                maxBundleP = numBunBlocks02;
                numBundleLength = wlengthPwidthB;
                numBundleWidth = 3 * blocks02B;
                lengthPalletF = numBundleLength * widthB
                widthPalletF = blocks02B * (lengthB + (2 * widthB))
              };
            }else if (((lengthB + widthB) > widthP) && (widthB <= (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
              numBundleLength = wlengthPwidthB;
              numBundleWidth = ywidthPlengthB;
              maxBundleP = numBundleLength + numBundleWidth;
              widthPalletF = 2 * widthB;
              lengthPalletF = lengthB + widthB;
            }else if (((lengthB + widthB) > widthP) && (widthB > (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
              numBundleLength = wlengthPwidthB;
              numBundleWidth = ywidthPlengthB;
              maxBundleP = numBundleLength * numBundleWidth;
              widthPalletF = numBundleLength * widthB;
              lengthPalletF = numBundleWidth * lengthB;
            }else if (((lengthB + widthB) > lengthP) && (lengthB < lengthP)) {
              maxBundleP = wlengthPwidthB;
              numBundleLength = wlengthPwidthB;
              numBundleWidth = ywidthPlengthB;
              lengthPalletF = maxBundleP * widthB;
              widthPalletF = lengthB; 
            }else if (((lengthB + widthB) > lengthP) && (lengthB > lengthP)){
              maxBundleP = wlengthPwidthB;
              numBundleLength = wlengthPwidthB;
              numBundleWidth = ywidthPlengthB;
              lengthPalletF = lengthB;
              widthPalletF = maxBundleP * widthB; 
            };
            //test orientation of pallet on the truck
          if((lengthPalletF <= 1.2) && (lengthPalletF > widthPalletF)){
            let newlengthPalletF: number = widthPalletF;
            widthPalletF = lengthPalletF;
            lengthPalletF = newlengthPalletF;
          };
          //ende test
          if(maxBundleP==0){
            maxBundleP=1;
          };
            boxPallet = (maxBundleP * this.formData.Layer * layerP);
            this.quoteService.numPalletCargo = Math.ceil(this.quoteService.formData.BoxShipment / (boxPallet))
            this.formData.Pallet = false;
            /* if(this.quoteService.editUsoCapacityTruck == true){
              this.quoteService.formData.UsedCapacityTruck = Math.ceil(volCargo * 100 / this.truckTypeList[indexTruckSelected].CapacityM3);
            }; */
          
          if(volCargo > this.truckTypeList[0].CapacityM3){
            this.toastr.error('La cantidad de piezas sobrepasa la capadidad de la unidad mas grande: FULL, '+ 
            'por favor consulte en la etiqueta TRANSPORTE cuánto puede embarcar por cada tipo de unidad ' +
            'para este símbolo:', 'Corrugated Quote.');
            this.quoteService.formData.UsedCapacityTruck = Math.ceil(volCargo * 100 / this.truckTypeList[0].CapacityM3)
          };
  
          //end test
        }else {  
          if (this.quoteService.formData.CargoType == "Sin Tarima"){
            widthP = 1.15;
            lengthP = 1.20;
            heightP = 1.20;
            layerP = Math.round(heightP / heightBundle);
            this.formData.Layer = layerP;
            numberBundle = Math.ceil(this.quoteService.formData.BoxShipment / this.formData.Layer)

            heightPalletF = parseFloat((layerP * heightBundle).toFixed(3));
            lengthB = Math.max(this.quoteService.lengthBundle, this.quoteService.widthBundle);
            widthB = Math.min(this.quoteService.lengthBundle, this.quoteService.widthBundle);
  
              areaBundle = this.quoteService.lengthBundle * this.quoteService.widthBundle;
              xlengthPlengthB = Math.floor(lengthP / lengthB);
              ywidthPlengthB = Math.floor(widthP / lengthB);
              wlengthPwidthB = Math.floor(lengthP / widthB);
              zwidthPwidthB = Math.floor(widthP / widthB);
  
              //number maximun boundle per pallet
              maxBundle01 = xlengthPlengthB * zwidthPwidthB;
              maxBundle02 = ywidthPlengthB * wlengthPwidthB;
  
              //end
              if ((lengthB + widthB) <= widthP) {
                blocksB = Math.floor(widthP / (lengthB + widthB))
                blocks02B = Math.floor(widthP / (lengthB + (2 * widthB)))
                numBunBlock = blocksB * (xlengthPlengthB + wlengthPwidthB)
                numBunBlocks02 = blocks02B * (wlengthPwidthB + (2 * xlengthPlengthB))
                maxBundleP = Math.max(numBunBlock, numBunBlocks02, maxBundle01, maxBundle02)
  
  
                if ((maxBundle01 >= maxBundle02) && (maxBundle01 >= numBunBlock) && (maxBundle01 >= numBunBlocks02)){
                  maxBundleP = maxBundle01;
                  numBundleLength = xlengthPlengthB;
                  numBundleWidth = zwidthPwidthB;
                  lengthPalletF = numBundleLength * lengthB
                  widthPalletF = numBundleWidth * widthB
                }else if ((maxBundle02 >= maxBundle01) && (maxBundle02 >= numBunBlock) && (maxBundle02 >= numBunBlocks02)){
                  maxBundleP = maxBundle02;
                  numBundleLength = wlengthPwidthB;
                  numBundleWidth = ywidthPlengthB;
                  lengthPalletF = numBundleLength * widthB;
                  widthPalletF = numBundleWidth * lengthB;
                }else if ((numBunBlock >= maxBundle01) && (numBunBlock >= maxBundle02) && (numBunBlock >= numBunBlocks02)){
                  maxBundleP = numBunBlock;
                  numBundleLength = wlengthPwidthB;
                  numBundleWidth = 2 * blocksB;
                  lengthPalletF = numBundleLength * widthB;
                  widthPalletF = blocksB * (lengthB + widthB);
                }else if ((numBunBlocks02 >= maxBundle01) && (numBunBlocks02 >= maxBundle02) && (numBunBlocks02 >= numBunBlock)){
                  maxBundleP = numBunBlocks02;
                  numBundleLength = wlengthPwidthB;
                  numBundleWidth = 3 * blocks02B;
                  lengthPalletF = numBundleLength * widthB
                  widthPalletF = blocks02B * (lengthB + (2 * widthB))
                };
              }else if (((lengthB + widthB) > widthP) && (widthB <= (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
                numBundleLength = wlengthPwidthB;
                numBundleWidth = ywidthPlengthB;
                maxBundleP = numBundleLength + numBundleWidth;
                widthPalletF = 2 * widthB;
                lengthPalletF = lengthB + widthB;
              }else if (((lengthB + widthB) > widthP) && (widthB > (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
                numBundleLength = wlengthPwidthB;
                numBundleWidth = ywidthPlengthB;
                maxBundleP = numBundleLength * numBundleWidth;
                widthPalletF = numBundleLength * widthB;
                lengthPalletF = numBundleWidth * lengthB;
              }else if (((lengthB + widthB) > lengthP) && (lengthB < lengthP)) {
                maxBundleP = wlengthPwidthB;
                numBundleLength = wlengthPwidthB;
                numBundleWidth = ywidthPlengthB;
                lengthPalletF = maxBundleP * widthB;
                widthPalletF = lengthB; 
              }else if (((lengthB + widthB) > lengthP) && (lengthB > lengthP)){
                maxBundleP = wlengthPwidthB;
                numBundleLength = wlengthPwidthB;
                numBundleWidth = ywidthPlengthB;
                lengthPalletF = lengthB;
                widthPalletF = maxBundleP * widthB; 
              };
              //test orientation of pallet on the truck
              if((lengthPalletF <= 1.2) && (lengthPalletF > widthPalletF)){
                let newlengthPalletF: number = widthPalletF;
                widthPalletF = lengthPalletF;
                lengthPalletF = newlengthPalletF;
              };
          //ende test
              this.formData.Pallet = false;
          }else if (this.quoteService.formData.CargoType == "Con Tarima"){
            //this.onTypeCargoTarima();
            //this.quoteService.widthPallet = this.palletList.find(listPallet => listPallet.PalletID == this.quoteService.formData.PalletID).Width;
            //this.quoteService.lengthPallet = this.palletList.find(listPallet => listPallet.PalletID == this.quoteService.formData.PalletID).Length;
            //this.quoteService.heightPallet = this.palletList.find(listPallet => listPallet.PalletID == this.quoteService.formData.PalletID).Height;
            widthP = this.quoteService.widthPallet / 100;
            lengthP = this.quoteService.lengthPallet / 100;
            heightP = 1.2 - (this.quoteService.heightPallet / 100);
            widthPalletF = widthP;
            lengthPalletF = lengthP;
            //test orientation of pallet on the truck
            if((lengthPalletF <= 1.2) && (lengthPalletF > widthPalletF)){
              let newlengthPalletF: number = widthPalletF;
              widthPalletF = lengthPalletF;
              lengthPalletF = newlengthPalletF;
            };
          //ende test
            layerP = Math.round(heightP / heightBundle);
            this.formData.Layer = layerP;
            numberBundle = Math.ceil(this.quoteService.formData.BoxShipment / this.formData.Layer)

            heightPalletF = parseFloat((layerP * heightBundle).toFixed(3));
            lengthB = Math.max(this.quoteService.lengthBundle, this.quoteService.widthBundle);
            widthB = Math.min(this.quoteService.lengthBundle, this.quoteService.widthBundle);
  
            if((lengthB > lengthP) || (widthB > widthP)){
              this.toastr.error('Revise por favor favor el tipo de TARIMA o las MEDIDAS de la Lámina, '+ 
                'las dimensiones finales de las Pilas sobrepasan las medidas de la TARIMA ' +
                'para este símbolo:', 'Corrugated Quote.');
                exit();
            };
  
              areaBundle = this.quoteService.lengthBundle * this.quoteService.widthBundle;
              xlengthPlengthB = Math.floor(lengthP / lengthB);
              ywidthPlengthB = Math.floor(widthP / lengthB);
              wlengthPwidthB = Math.floor(lengthP / widthB);
              zwidthPwidthB = Math.floor(widthP / widthB);
  
              //number maximun boundle per pallet
              maxBundle01 = xlengthPlengthB * zwidthPwidthB;
              maxBundle02 = ywidthPlengthB * wlengthPwidthB;
  
              //end
              if ((lengthB + widthB) <= widthP) {
                blocksB = Math.floor(widthP / (lengthB + widthB))
                blocks02B = Math.floor(widthP / (lengthB + (2 * widthB)))
                numBunBlock = blocksB * (xlengthPlengthB + wlengthPwidthB)
                numBunBlocks02 = blocks02B * (wlengthPwidthB + (2 * xlengthPlengthB))
                maxBundleP = Math.max(numBunBlock, numBunBlocks02, maxBundle01, maxBundle02)
  
  
                if ((maxBundle01 >= maxBundle02) && (maxBundle01 >= numBunBlock) && (maxBundle01 >= numBunBlocks02)){
                  maxBundleP = maxBundle01;
                  numBundleLength = xlengthPlengthB;
                  numBundleWidth = zwidthPwidthB;
                  
                }else if ((maxBundle02 >= maxBundle01) && (maxBundle02 >= numBunBlock) && (maxBundle02 >= numBunBlocks02)){
                  maxBundleP = maxBundle02;
                  numBundleLength = wlengthPwidthB;
                  numBundleWidth = ywidthPlengthB;
                
                }else if ((numBunBlock >= maxBundle01) && (numBunBlock >= maxBundle02) && (numBunBlock >= numBunBlocks02)){
                  maxBundleP = numBunBlock;
                  numBundleLength = wlengthPwidthB;
                  numBundleWidth = 2 * blocksB;
                
                }else if ((numBunBlocks02 >= maxBundle01) && (numBunBlocks02 >= maxBundle02) && (numBunBlocks02 >= numBunBlock)){
                  maxBundleP = numBunBlocks02;
                  numBundleLength = wlengthPwidthB;
                  numBundleWidth = 3 * blocks02B;
                  
                };
              }else if (((lengthB + widthB) > widthP) && (widthB <= (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
                numBundleLength = wlengthPwidthB;
                numBundleWidth = ywidthPlengthB;
                maxBundleP = numBundleLength + numBundleWidth;
              }else if (((lengthB + widthB) > widthP) && (widthB > (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
                numBundleLength = wlengthPwidthB;
                numBundleWidth = ywidthPlengthB;
                maxBundleP = numBundleLength * numBundleWidth;
              }else if (((lengthB + widthB) > lengthP) && (lengthB < lengthP)) {
                maxBundleP = wlengthPwidthB;
                numBundleLength = wlengthPwidthB;
                numBundleWidth = ywidthPlengthB;
              };
          };
            
            boxPallet = (maxBundleP * this.formData.Layer);
            this.quoteService.numPalletCargo = Math.ceil(this.quoteService.formData.BoxShipment / (boxPallet))
            lengthCargo = Math.ceil(this.quoteService.numPalletCargo / 4) * lengthPalletF;
            
            if(lengthCargo <= 2){
              indexTruck = 4;
            }else if (lengthCargo > 2 && lengthCargo <= 6.4){
              indexTruck = 3;
            }else if (lengthCargo > 6.4 && lengthCargo <= 6.9){
              indexTruck = 2;
            }else if (lengthCargo > 6.9 && lengthCargo <= 14.4){
              indexTruck = 1;
            }else if (lengthCargo > 14.4 && lengthCargo <= 24){
              indexTruck = 0;
            };
            this.quoteService.formData.TruckName = this.truckTypeList[indexTruck].TruckName;
            volCargo = Math.floor(this.truckTypeList[indexTruck].Length / lengthPalletF) * 4
            this.quoteService.formData.TruckID = this.truckTypeList[indexTruck].TruckID;
            this.quoteService.formData.IdealTruck = this.quoteService.formData.TruckName;
            this.quoteService.formData.UsedCapacityTruck = Math.ceil(this.quoteService.numPalletCargo * 100 / volCargo);
            //test
            this.quoteService.fullCapacity = Math.floor(this.truckTypeList[0].Length / lengthPalletF) * 4 * boxPallet
            this.quoteService.trailerCapacity = Math.floor(this.truckTypeList[1].Length / lengthPalletF) * 4 * boxPallet
            this.quoteService.tortonCapacity = Math.floor(this.truckTypeList[2].Length / lengthPalletF) * 4 * boxPallet
            this.quoteService.rabonCapacity = Math.floor(this.truckTypeList[3].Length / lengthPalletF) * 4 * boxPallet
            this.quoteService.camionetaCapacity = Math.floor(this.truckTypeList[4].Length / lengthPalletF) * 4 * boxPallet
            //end test
           /*  if(this.quoteService.editUsoCapacityTruck == true){
              this.quoteService.formData.UsedCapacityTruck = Math.ceil(volCargo * 100 / (Math.ceil(this.truckTypeList[indexTruck].Length / lengthPalletF) * 4));
            }; */
            
            if(this.quoteService.formData.UsedCapacityTruck > 100){
              this.toastr.error('La cantidad de piezas sobrepasa la capadidad de la unidad mas grande: FULL, '+ 
              'por favor consulte en la etiqueta TRANSPORTE cuánto puede embarcar por cada tipo de unidad ' +
              'para este símbolo:', 'Corrugated Quote.');
            };
  
        };
        this.formData.BundleAlongPallet = numBundleLength;
        this.formData.BundleWidthPallet = numBundleWidth;
        //this.service.quoteItems[0].Layer = layerP;
        this.quoteService.layerBundle = maxBundleP;
        this.quoteService.originalQuantityShipment = this.quoteService.formData.BoxShipment;
        this.quoteService.formData.PalletLength = lengthPalletF;
        this.quoteService.formData.PalletWidth = widthPalletF;
        this.quoteService.formData.PalletHeight = heightPalletF;
        this.quoteService.formData.PalletProduced = Math.ceil(this.quoteService.formData.AverageOrder / (boxPallet))
        this.quoteService.formData.PalletNumber = this.quoteService.numPalletCargo;
        this.quoteService.formData.BundleVolum = volBundle;
        this.quoteService.formData.BundlePallet = (boxPallet / this.formData.Layer);
        this.quoteService.editUsoCapacityTruck = true;
    };
  }
