<h3>Buscar Proveedor:</h3> 
<div class="form-row">
    <div class="form-group col-md-4" style="margin-top: 5px;">
        <input name="SupplierNameFilter" id="SupplierNameFilter" class="form-control" placeholder="Digite el Proveedor a buscar..." 
        (keyup)="onFilterSupplierName($event.target.value)">
    </div>
</div>

<table class="table table-hover">
    <thead class="thead-light">
        <th>ID</th>
        <th >RFC</th>
        <th>Razón Social</th>
        <th hidden = "true">Nombre Comercial</th>
        <th>Contacto</th>
        <th hidden = "true">Puesto de Contacto</th>
        <th hidden = "true">IDPaís</th>
        <th>País</th>
        <th hidden = "true">Estado</th>
        <th hidden = "true">Dirección</th>
        <th hidden = "true">Cod. Postal</th>
        <th style="text-align: center;">Teléfono</th>
        <th>Email</th>
        <th style="text-align: right;">
            <a class="btn btn-outline-success" (click)="AddOrEditSupplierItem(null, dataSupplier.dataSupplier.SupplierID)"><i class="fa fa-plus-square"></i>  Nuevo</a>
        </th>
    </thead>
    <tbody>
        <tr *ngFor="let item of paginatedData; let i=index;">
            <th (click)="AddOrEditSupplierItem(((currentPage-1)*itemsPerPage)+i, item.SupplierID)">{{item.SupplierID}}</th>
            <td>{{item.SupplierRFC}}</td>
            <td>{{item.SupplierName}}</td>
            <td hidden = "true">{{item.SupplierTradeName}}</td>
            <td>{{item.ContactName}}</td>
            <td hidden = "true">{{item.ContactPosition}}</td>
            <td hidden = "true">{{item.CountryID}}</td>
            <td>{{item.country}}</td>
            <td hidden = "true">{{item.StateID}}</td>
            <td hidden = "true">{{item.Address}}</td>
            <td hidden = "true">{{item.ZipCode}}</td>
            <td style="text-align: center;">{{item.Telephone}}</td>
            <td>{{item.Email}}</td>
            <td style="text-align: right;">
                <a class="btn btn-sm btn-info text-white" (click)="AddOrEditSupplierItem(((currentPage-1)*itemsPerPage)+i, item.SupplierID)"><i class="fa fa-pencil"></i></a>
                <a class="btn btn-sm btn-danger text-white ml-1" (click)="onDeleteSupplierItem(item.SupplierID)"><i class="fa fa-trash"></i></a>
            </td>
        </tr>
    </tbody>
</table>
<!--codigo de paginación-->
<nav>
    <ul class="pagination"> <!--controla el recuadro de anterior-->
        <li class="page-item" style="cursor: pointer;">
            <a class="page-link"
            [ngClass]="{disabled:currentPage ===1 }" 
            (click)="pageClicked(currentPage - 1)">Anterior</a>
        </li>
        <li (click)="pageClicked(page)"
            *ngFor="let page of pages" 
            class="page-item" 
            [ngClass]="{ active: page == currentPage }">
            <a class="page-link" href="javascript:void(0)">{{ page }}</a><!--se pocisiona en la pagina y activa el cuadrito.-->
        </li>
        <li class="page-item" style="cursor: pointer;"> <!--controla el recuadro de siguiente-->
            <a class="page-link" 
            [ngClass]="{ disabled: currentPage === totalPages }" 
            (click)="pageClicked(currentPage + 1)">Siguiente</a>
        </li>
    </ul>
</nav>
