<h3>Lista de Máquinas</h3>
<table class="table table-hover">
    <thead class="thead-light">
        <th>ID</th>
        <th>Nombre</th>
        <th>Tipo</th>
        <th hidden = "true">ManufacturedBy</th>
        <th hidden = "true">Brand</th>
        <th>Modelo</th>
        <th style="text-align: center;">Trim Corrugadora cm</th>
        <th>Colores</th>
        <th hidden = "true">WidthMin</th>
        <th style="text-align: center;">Ancho cm</th>
        <th hidden = "true">LenghtMin</th>
        <th style="text-align: center;">Largo cm</th>
        <th hidden = "true">SpeedRegHigDenHig</th>
        <th hidden = "true">SpeedRegHigDenMed</th>
        <th hidden = "true">SpeedRegHigDenLow</th>
        <th hidden = "true">SpeedRegMedDenHig</th>
        <th hidden = "true">SpeedRegMedDenMed</th>
        <th hidden = "true">SpeedRegMedDenLow</th>
        <th hidden = "true">SpeedRegLowDenHig</th>
        <th hidden = "true">SpeedRegLowDenMed</th>
        <th hidden = "true">SpeedRegLowDenLow</th>
        <th style="text-align: center;">Eficiencia Operativa %</th>
        <th style="text-align: right;">
            <a class="btn btn-outline-success" (click)="AddOrEditWorkstationItem(null, dataWorkstation.dataWorkstation.MachineID)"><i class="fa fa-plus-square"></i> Nuevo</a>
        </th>
    </thead>
    <tbody>
        <tr *ngFor="let item of dataWorkstation.dataWorkstation; let i=index;">
            <th (click)="AddOrEditWorkstationItem(i, item.MachineID)">{{item.MachineID}}</th>
            <td>{{item.MachineName}}</td>
            <td>{{item.Type}}</td>
            <td hidden = "true">{{item.ManufacturedBy}}</td>
            <td hidden = "true">{{item.Brand}}</td>
            <td>{{item.Model}}</td>
            <td style="text-align: center;">{{item.TrimCorrugator}}</td>
            <td style="text-align: center;">{{item.NumColor}}</td>
            <td hidden = "true">{{item.WidthMin}}</td>
            <td style="text-align: center;">{{item.WidthMax}}</td>
            <td hidden = "true">{{item.LenghtMin}}</td>
            <td style="text-align: center;">{{item.LenghtMax}}</td>
            <td hidden = "true">{{item.SpeedRegHigDenHig}}</td>
            <td hidden = "true">{{item.SpeedRegHigDenMed}}</td>
            <td hidden = "true">{{item.SpeedRegHigDenLow}}</td>
            <td hidden = "true">{{item.SpeedRegMedDenHig}}</td>
            <td hidden = "true">{{item.SpeedRegMedDenMed}}</td>
            <td hidden = "true">{{item.SpeedRegMedDenLow}}</td>
            <td hidden = "true">{{item.SpeedRegLowDenHig}}</td>
            <td hidden = "true">{{item.SpeedRegLowDenMed}}</td>
            <td hidden = "true">{{item.SpeedRegLowDenLow}}</td>
            <td style="text-align: center;">{{item.OperatingEfficiency}}</td>
            <td style="text-align: right;">
                <a class="btn btn-sm btn-info text-white" (click)="AddOrEditWorkstationItem(i, item.MachineID)"><i class="fa fa-pencil"></i></a>
                <!-- <a class="btn btn-sm btn-danger text-white ml-1" (click)="onDeleteWorkstationItem(item.MachineID)"><i class="fa fa-trash"></i></a> -->
            </td>
        </tr>
    </tbody>
</table>

