<!---->
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <!--<a class="navbar-brand" href="#">Corrugated Quote App.</a>-->
  
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" 
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="/quotehome">Inicio <span class="sr-only">(current)</span></a>
      </li>
      <!--li class="nav-item">
        <a class="nav-link" href="#">Mí Cuenta</a>
      </li-->
      <li class="nav-item">
        <a class="nav-link" href="/quote">Cotizador</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/quotes">Cotizaciones</a>
      </li>

      <!--  Menu administradores -->
        <!-- uid=='Victor Ruiz -> victor.ruiz'   uid=='TIC -> ticcd' -->
      <li class="nav-item dropdown" *ngIf="uid=='d7f04f23-ff18-4e59-a205-9f832f032781'  ||  uid=='f550631c-52f2-4cc3-b12e-546f81c16113'">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Mantenimiento
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="/customer">Clientes</a>
          <a class="dropdown-item" href="/supplier">Proveedores</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/paper">Papeles</a>
          <a class="dropdown-item" href="/board">Resistencias</a>
          <a class="dropdown-item" href="/ink">Tintas</a>
          <a class="dropdown-item" href="/auxiliary">Auxiliares</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/trucktype">Unidades de Transporte</a>
          <a class="dropdown-item" href="/pallet">Tarimas</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/freight">Fletes</a>
          <a class="dropdown-item" href="/destiny">Destinos</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/workstation">Máquinas</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/costrate">Tarifas</a>
        </div>
      </li>

      <!--  Menu Vendeores -->
      <!-- uid=='Fabiola Meneses -> fmeneses'   uid=='Miguel TIC -> miguelh' -->

      <li class="nav-item dropdown" *ngIf="uid=='76afb7ec-6b46-4d52-a979-a39a162c1f06' || uid=='9f5c9926-03df-4134-8fc9-fe962effa1e3'">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Mantenimiento
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="/customer">Clientes</a>
          <!--a class="dropdown-item" href="/supplier">Proveedores</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/paper">Papeles</a>
          <a class="dropdown-item" href="/board">Resistencias</a>
          <a class="dropdown-item" href="/ink">Tintas</a>
          <a class="dropdown-item" href="/auxiliary">Auxiliares</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/trucktype">Unidades de Transporte</a>
          <a class="dropdown-item" href="/pallet">Tarimas</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/freight">Fletes</a>
          <a class="dropdown-item" href="/destiny">Destinos</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/workstation">Máquinas</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/costrate">Tarifas</a-->
        </div>
      </li>

      <!--li class="nav-item">
        <a class="nav-link" href="/contact">Contacto</a>
      </li-->
     <!-- <li class="nav-item">
        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
      </li>-->
      <li class="nav-item">
        <!--a class="nav-link" href="/" (click)="logout()">Cerrar Sesión</a-->
        <button type="button" class="nav-link" (click)="logout()">Cerrar Sesión</button>
      </li>
    </ul>
    <!--<form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form>
    -->
  </div>
</nav>
<div class="jumbotron jumbotron-fluid">
  <div class="container" style="max-width: 90%;">
    <div class="row justify-content-end">
      <div class="col-md-12 offset-md-1">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<!--
<div class="jumbotron jumbotron-fluid">
  <h3 class="display-4 text-center"><img src="/assets/img/box.png" width="60px" height="60px"> Corrugated Quote App.</h3>
  <div class="container">
    <div class="row justify-content-end">
      <div class="col-md-12 offset-md-1">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
-->