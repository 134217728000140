<h3>Lista de Tarifas</h3>
<table class="table table-hover">
    <thead class="thead-light">
        <th>Máquina</th>
        <th style="text-align: center;">Mano Obra dir.</th>
        <th style="text-align: center;">Mano Obra Ind.</th>
        <th style="text-align: center;">Corporativo</th>
        <th style="text-align: center;">Mantenimiento</th>
        <th style="text-align: center;">Energía</th>
        <th style="text-align: center;">Agua</th>
        <th style="text-align: center;">Combustible</th>
        <th style="text-align: center;">Gastos Ind. Fab.</th>
        <th style="text-align: right;">
            <a class="btn btn-outline-success"><i class="fa fa-plus-square"></i> Editar</a>
        </th>
    </thead>
    <tbody>
        <tr *ngFor="let item of dataCostRate.dataCostRate; let i=index;">
            <th (click)="AddOrEditCostRateItem(i, item.MachineID)">{{item.MachineID}}</th>
            <td style="text-align: center;">{{item.DirLaborCostHR}}</td>
            <td style="text-align: center;">{{item.IndLaborCostHR}}</td>
            <td style="text-align: center;">{{item.CorpMiscCostHR}}</td>
            <td style="text-align: center;">{{item.MaintenanceCostHR}}</td>
            <td style="text-align: center;">{{item.EnergyCostoHR}}</td>
            <td style="text-align: center;">{{item.WaterCostHR}}</td>
            <td style="text-align: center;">{{item.GasCostHR}}</td>
            <td style="text-align: center;">{{item.IndManCostHR}}</td>
            
            <td style="text-align: center;">
                <a class="btn btn-sm btn-info text-white" (click)="AddOrEditCostRateItem(i, item.MachineID)"><i class="fa fa-pencil"></i></a>
                <!-- <a class="btn btn-sm btn-danger text-white ml-1" (click)="onDeleteWorkstationItem(item.MachineID)"><i class="fa fa-trash"></i></a> -->
            </td>
        </tr>
    </tbody>
</table>


