import { DOCUMENT } from '@angular/common';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { cursorTo } from 'readline';
import { Board } from 'src/app/shared/board.model';
import { BoardService } from 'src/app/shared/board.service';
import { Boardcost } from 'src/app/shared/boardcost.model';
import { BoardcostService } from 'src/app/shared/boardcost.service';
import { BoardItemsComponent } from './board-items.component';
import { BoardcostItemsComponent } from './boardcost-items.component';
import { DialogConfirm } from "../../quotes/quote/confirm/dialog-confirm-component";

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styles: []
})
export class BoardComponent implements OnInit {

    //paginación declaracion de variables
  itemsPerPage=12;
  currentPage=1;
  totalPages = 0;
  totalItems=0;
  data1;
  totales;
  pages: number [] = [];
  @Output() onClick: EventEmitter<number> = new EventEmitter();//captura el evento click y se mueva

  constructor(private dialog: MatDialog, public dataBoard: BoardService, 
    public boardCostService: BoardcostService, private toastr: ToastrService) { }

  ngOnInit(): void {

    this.dataBoard.getBoardList().then(res => this.dataBoard.dataBoard = res as Board);
  }
  //paginación funciones
  get paginatedData(){
    this.data1 = JSON.stringify(this.dataBoard.dataBoard);
    this.data1 = JSON.parse(this.data1);
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.totalItems = this.data1.length;
      return this.data1.slice(start,end);
  }

  ngAfterViewInit(){
  this.updateItems();
  }

  updateItems(){
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.pages = Array.from({length: this.totalPages}, (_, i) => i + 1);
    //console.log(this.pages);
  }

  pageClicked(page: number){
    if(page > this.totalPages) return;
    this.onClick.emit(page);
    this.changePage(page);
  }

  changePage(page: number){
  this.currentPage=page;
  }

  refreshList(){
    //this.dataBoard.dataBoard.BoardIDFilter = "";
    document.getElementById("BoardIDFilter").focus();
    var formBoardID = <HTMLInputElement> document.getElementById("BoardIDFilter");
    //formBoardID.value = ''
    if ( formBoardID.value == '' || formBoardID.value == undefined){
      this.dataBoard.getBoardList().then(res => this.dataBoard.dataBoard = res as Board);
    } else {
      this.dataBoard.getBoardId(formBoardID.value).then(res => this.dataBoard.dataBoard = res as Board);
    }
  };

  AddOrEditBoardItem(boardItemIndex, BoardID){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="80%";
    dialogConfig.height = "70%";
    dialogConfig.data = { boardItemIndex, BoardID };
    this.dialog.open(BoardItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });
  };

  onEditBoardCostItem(boardCostItemIndex, BoardID){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="70%";
    dialogConfig.height = "70%";
    dialogConfig.data = { boardCostItemIndex, BoardID };
    this.dialog.open(BoardcostItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });

      //this.toastr.success('Requiere permisos de Administrador para acceder a esta información!!', 'Corrugated Quote.');
  };

  onDeleteBoardItem(id: string){
    //this.confirmDisplayDelete(id);
  if (id.slice(-1) != '1'){
    this.confirmDisplayDelete(id);
      /*if (confirm('Estas seguro de querer eliminar la Resistencia seleccionada?')) {
        this.dataBoard.deleteBoard(id).then(() => {
          this.refreshList();
          this.toastr.warning('Borrado exitosamente', 'Corrugated Quote.');
        }, () =>{
            this.toastr.error('La Resistencia seleccionada no se puede eliminar, tiene registros activos con otras tablas', 'Corrugated Quote.');
        });
      };*/
    }else {
      this.toastr.error('La Resistencia seleccionada no se puede eliminar, es parte vital para el Cotizador', 'Corrugated Quote.');
    };
    
    
    
    
    /* if (confirm('Estas seguro de querer eliminar el registro?')){
      this.dataBoard.deleteBoard(id).then(res => {
        this.dataBoard.deleteBoardCost(id).then();
        this.refreshList();
        this.toastr.warning('Borrado exitosamente!', 'Corrugated Quote.');
      });
    }; */
  };

  confirmDisplayDelete(id: string): void {
    this.dialog
      .open(DialogConfirm, {
        data: '¿Estas seguro de querer eliminar la resistencia?',
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
            this.dataBoard.deleteBoard(id).then(res => {
            this.refreshList();
            this.toastr.warning('Borrado exitoso!', 'Corrugated Quote.');
          })
        }
      });
  }

  onUpdateBoardCost(){
    //i need to prove with 01 boardid and review.
    //this.dataBoard.getBoardList().then(res => this.dataBoard.dataBoard = res as Board);
    if (confirm('Estas seguro de querer actualizar los costos?')){
      
      for (let i in this.dataBoard.dataBoard){
        this.dataBoard.putBoardCost(this.dataBoard.dataBoard[i].BoardID, "UpdateBoardCost").subscribe();
      };
        this.toastr.success('Actualizacion exitosa!!', 'Corrugated Quote.');
      };
    //for (let i in this.dataBoard.dataBoard){
      //this.dataBoard.putBoardCost(this.dataBoard.dataBoard[i].BoardID, "UpdateBoardCost").subscribe();
    //}
  }
  
  onFilterBoardID(boardID: string){
    this.dataBoard.getBoardId(boardID).then(res => this.dataBoard.dataBoard = res as Board);
    this.updateItems();
  };

  getformFocus(formID: string) {
    document.getElementById(formID).focus();
    var formBoardID = <HTMLInputElement> document.getElementById(formID);
    formBoardID.value = ''
  };
}
