import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pallet } from './pallet.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PalletService {
  palletList: Pallet;
  dataPallet: Pallet;

  constructor(private http: HttpClient) { }

  getPalletList(){
    return this.http.get(environment.apiURL + '/Pallet').toPromise();
  }

  getPalletListId(palletName: string){
    return this.http.get(environment.apiURL + '/Pallet/'+ palletName).toPromise();
  }

  deletePallet(palletID: number) {
    return this.http.delete(environment.apiURL + '/Pallet/' + palletID).toPromise();
  };

  putPalletID(id: number, pallet: Pallet) {
    return this.http.put(environment.apiURL + '/Pallet/' + id, pallet);
  };

  postPallet() {
    return this.http.post(environment.apiURL + '/Pallet', this.palletList);
  };
}
