import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Costrate } from './costrate.model';

@Injectable({
  providedIn: 'root'
})
export class CostrateService {

  costRateList: Costrate;
  dataCostRate: Costrate;
  constructor(private http: HttpClient) { }

  getCostRateList(){
    return this.http.get(environment.apiURL + '/CostRate').toPromise();
  }

  getCostRateListId(MachineID: string){
    return this.http.get(environment.apiURL + '/CostRate/'+ MachineID).toPromise();
  }

  putCostRateID(id: string, costrate: Costrate) {
    return this.http.put(environment.apiURL + '/CostRate/' + id, costrate);
  };
  
}
