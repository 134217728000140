<h4 class="display-4">Mantenimiento de Proveedores </h4>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <div class="form-row">
        <div class="form-group col-md-2">
            <label>ID Proveedor</label>
            <input name="SupplierID" #SupplierID="ngModel" [(ngModel)]="supplierService.supplierList.SupplierID" class="form-control"
            readonly>  
        </div>
        <div class="form-group col-md-2">
            <label>RFC</label>
            <input name="SupplierRFC" #SupplierRFC="ngModel" [(ngModel)]="supplierService.supplierList.SupplierRFC" 
            class="form-control" style="text-transform:uppercase" placeholder="HHHH-123456-HH2" (keyup)="onvalidateRFC($event.target.value)" maxlength="13">  
        </div>
        <div class="form-group col-md-4" ng-init="otp=true">
            <label>Razón Social</label>
            <input name="SupplierName" id="SupplierName" #SupplierName="ngModel" [(ngModel)]="supplierService.supplierList.SupplierName" 
            class="form-control">  
        </div>         
        <div class="form-group col-md-4">
            <label>Nombre Comercial</label>
            <input name="SupplierTradeName" #SupplierTradeName="ngModel" [(ngModel)]="supplierService.supplierList.SupplierTradeName" 
            class="form-control">  
        </div>

    </div>  

    <div class="form-row">
        <div class="form-group col-md-4">
            <label>Nombre de Contacto</label>
            <input name="ContactName" #ContactName="ngModel" [(ngModel)]="supplierService.supplierList.ContactName" class="form-control">  
        </div>
        <div class="form-group col-md-4">
            <label>Puesto de Contacto</label>
            <input name="ContactPosition" id="ContactPosition" #ContactPosition="ngModel" [(ngModel)]="supplierService.supplierList.ContactPosition" class="form-control">  
        </div>
        <div class="form-group col-md-2">
            <label>País</label>
            <select name="CountryID" id="CountryID" #CountryID="ngModel" [(ngModel)]="supplierService.supplierList.CountryID" class="form-control"
                (change)="onSelectCountry($event.target.value)">
                <option value=0>-Select-</option>
                <option *ngFor="let item of countryList" value="{{item.CountryID}}">{{item.CountryName}}</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label>Estado</label>
            <select name="StateID" id="StateID" #StateID="ngModel" [(ngModel)]="supplierService.supplierList.StateID" class="form-control">
                <option value=0>-Select-</option>
                <option *ngFor="let item of stateList" value="{{item.StateID}}">{{item.StateName}}</option>
            </select>   
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-md-4">
            <label>Dirección</label>
            <input name="Address" id="Address" #Address="ngModel" [(ngModel)]="supplierService.supplierList.Address" class="form-control" placeholder="Calle, Numero, Municipio" >  
        </div>
        <div class="form-group col-md-2">
            <label>Código Postal</label>
            <input name="ZipCode" id="ZipCode" #ZipCode="ngModel" [(ngModel)]="supplierService.supplierList.ZipCode" class="form-control">  
        </div>
        <div class="form-group col-md-2">
            <label>Teléfono</label>
            <input name="Telephone" id="Telephone" #Telephone="ngModel" [(ngModel)]="supplierService.supplierList.Telephone" class="form-control" 
            type="text" showMaskTyped = "true" mask = "(000) 000-0000">  
        </div> 
        <div class="form-group col-md-4">
            <label>E-mail</label>
            <input name="Email" id="Email" #Email="ngModel" [(ngModel)]="supplierService.supplierList.Email" 
            class="form-control" type="email" placeholder="name@example.com">  
        </div>
    </div>
    <div class="form-group">
        <button type="submit" class="btn btn-dark"><i class="fa fa-database"></i> Guardar</button>
        <button type="submit" class="btn btn-outline-dark ml-1" (click)="onClose()"><i class="fa fa-close"></i> Cerrar</button>
    </div>
</form>
 