<h4 class="display-4">Mantenimiento de Unidades</h4>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <div class="form-row">
        <div class="form-group col-md-3">
            <label>ID</label>
            <input name="TruckID" #TruckID="ngModel" [(ngModel)]="trucktypeService.trucktypeList.TruckID" class="form-control"
            readonly>  
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-4">
            <label>Capacidad</label>
            <div class="input-group">
                <input name="CapacityM3" id="CapacityM3" #CapacityM3="ngModel" [(ngModel)]="trucktypeService.trucktypeList.CapacityM3" 
                class="form-control" type="number" min="0" style="text-align: right;" readonly>
                <div class="input-group-prepend">
                    <div class="input-group-text">m3</div>
                </div> 
            </div>  
        </div>
        <div class="form-group col-md-8">
            <label>Tipo</label>
            <input name="TruckName" id="TruckName" #TruckName="ngModel" [(ngModel)]="trucktypeService.trucktypeList.TruckName" 
            class="form-control">  
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-4">
            <label>Largo</label>
            <div class="input-group">
                <input name="Length" id="Length" #Length="ngModel" [(ngModel)]="trucktypeService.trucktypeList.Length" 
                class="form-control" type="number" min="0" style="text-align: right;">
                <div class="input-group-prepend">
                    <div class="input-group-text">m</div>
                </div> 
            </div>  
        </div>
        <div class="form-group col-md-4">
            <label>Ancho</label>
            <div class="input-group">
                <input name="Width" id="Width" #Width="ngModel" [(ngModel)]="trucktypeService.trucktypeList.Width" 
                class="form-control" type="number" min="0" style="text-align: right;">
                <div class="input-group-prepend">
                    <div class="input-group-text">m</div>
                </div> 
            </div>  
        </div>
        <div class="form-group col-md-4">
            <label>Altura</label>
            <div class="input-group">
                <input name="Height" id="Height" #Height="ngModel" [(ngModel)]="trucktypeService.trucktypeList.Height" 
                class="form-control" type="number" min="0" style="text-align: right;">
                <div class="input-group-prepend">
                    <div class="input-group-text">m</div>
                </div> 
            </div>  
        </div>
    </div>  
    <div class="form-group">
        <button type="submit" class="btn btn-dark"><i class="fa fa-database"></i> Guardar</button>
        <button type="submit" class="btn btn-outline-dark ml-1" (click)="onClose()"><i class="fa fa-close"></i> Cerrar</button>
    </div>
</form>
