import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuoteItem } from 'src/app/shared/quote-item.model';
import { QuoteService } from 'src/app/shared/quote.service';
import { Board } from 'src/app/shared/board.model';
import { BoardService } from 'src/app/shared/board.service';
import { Boardcost } from 'src/app/shared/boardcost.model';
import { BoardcostService } from 'src/app/shared/boardcost.service';
import { Style } from 'src/app/shared/style.model';
import { Closure } from 'src/app/shared/closure.model';
import { DevelopmentService } from 'src/app/shared/development.service';
import { Development } from 'src/app/shared/development.model';
import { InkService } from 'src/app/shared/ink.service';
import { Ink } from 'src/app/shared/ink.model';
import { TapeService } from 'src/app/shared/tape.service';
import { Tape } from 'src/app/shared/tape.model';
import { WorkstationService } from 'src/app/shared/workstation.service';
import { Workstation } from 'src/app/shared/workstation.model';
import { CostrateService } from 'src/app/shared/costrate.service';
import { Costrate } from 'src/app/shared/costrate.model';
import { AuxiliaryService } from 'src/app/shared/auxiliary.service';
import { Auxiliary } from 'src/app/shared/auxiliary.model';
import { Product } from 'src/app/shared/product.model';
import { ProductService } from 'src/app/shared/product.service';

import { NgForm } from '@angular/forms';
//import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { ToastrService } from 'ngx-toastr';
//import { QuoteComponent } from '../quote/quote.component';
import { exit } from 'process';
import { StyleService } from 'src/app/shared/style.service';
import { ClosureService } from 'src/app/shared/closure.service';
import { TruckTypeService } from 'src/app/shared/TruckType.service';
import { TruckType } from 'src/app/shared/TruckType.model';

@Component({
  selector: 'app-quote-items',
  templateUrl: './quote-items.component.html',
  styles: []
})
export class QuoteItemsComponent implements OnInit {
  formData: QuoteItem;
  boardList: Board [];
  boardCost: Boardcost;
  styleList: Style [];
  closureList: Closure [];
  developmentList: Development [];
  tapeList: Tape [];
  inkList: Ink [];
  costrateList: Costrate [];
  workstationList: Workstation [];
  auxiliaryList: Auxiliary [];
  productList: Product [];
  truckTypeList: TruckType [];

  isValid: boolean = true;
  mostrarPallet: boolean;
  /* mostrarInkNumber02: boolean;
  mostrarInkNumber03: boolean;
  mostrarInkNumber04: boolean;
  mostrarInkNumber05: boolean;
  mostrarInkNumber06: boolean; */
  mostrarClosureID: boolean;
  viewDepth: boolean;
  viewOutput: boolean;
  outPutDRC: number = 0;
  outPutRDCL: number;
  outPutRDCW: number;
  symbolCustomer: string;
  mostrarOverlap: boolean;
  inknumber2: number;
  //indexSelected: number;


  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data,
    public dialogRef: MatDialogRef<QuoteItemsComponent>,
    public quoteService: QuoteService, private boardService: BoardService, 
    private truckTypeService: TruckTypeService,
    private styleService: StyleService, private closureService: ClosureService, 
    private developmentService: DevelopmentService, private inkService: InkService, 
    private tapeService: TapeService, private boardcostService: BoardcostService, 
    private workstationService: WorkstationService, private costrateService: CostrateService, 
    private auxiliaryService: AuxiliaryService, private productService: ProductService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    //this.boardService.getBoardList().then(res => this.boardList = res as Board[]);
    this.boardService.getBoardListOneAlternative("quoteSales", 1).then(res => this.boardList = res as Board[]);
    this.styleService.getStyleList().then(res => this.styleList = res as Style[]);
    this.closureService.getClosureList().then(res => this.closureList = res as Closure[]);
    this.tapeService.getTapeList().then(res => this.tapeList = res as Tape[]);
    this.inkService.getInkList().then(res => this.inkList = res as Ink[]);
    this.workstationService.getWorkstationList().then(res => this.workstationList = res as Workstation[]);
    this.costrateService.getCostRateList().then(res => this.costrateList = res as Costrate[]);
    this.auxiliaryService.getAuxiliaryList().then(res => this.auxiliaryList = res as Auxiliary[]);
    this.productService.getProductList().then(res => this.productList = res as Product[]);
    this.truckTypeService.getTruckTypeList().then(res => this.truckTypeList = res as TruckType[]);
    /* if (this.quoteService.quoteItems.length > 0){
      this.outPutDRC = this.formData.Output;
    }; */
    if(this.quoteService.quoteItems.length>0){
      this.inknumber2=this.quoteService.quoteItems[0].InkNumber;
      console.log(this.inknumber2);
    }
    if (this.quoteService.quoteItems.length == 0){
      this.developmentService.getDevelopmentList(1, 1, 1).subscribe(developments => {
        this.developmentList = developments;
        console.log(developments);
      });
    } else {
      if (this.quoteService.quoteItems[0].StyleID == 5 || this.quoteService.quoteItems[0].StyleID == 7){
        this.developmentService.getDevelopmentList(5, this.quoteService.quoteItems[0].FluteID, 1).subscribe(developments => {
          this.developmentList = developments;
          console.log(developments);
        });
      }else {
        this.developmentService.getDevelopmentList(this.quoteService.quoteItems[0].StyleID, this.quoteService.quoteItems[0].FluteID, this.quoteService.quoteItems[0].ClosureID).subscribe(developments => {
          this.developmentList = developments;
          console.log(developments);
        });
      };
    };
    if(this.quoteService.formData.CargoType == "Con Tarima"){
      this.mostrarPallet = true;
    } else{
      this.mostrarPallet = false;
    };

    if (this.data.quoteItemIndex==null)
      this.formData = {
        PartitionKit: null,
        QuoteItemID: null, 
        QuoteID: this.data.QuoteID,
        FluteID: 0,
        BoardID: null,
        StyleID: 0,
        StyleName: "",
        Overlap: 0,
        ClosureID: 2,
        Register:"Bajo",
        DieType: '',
        TapeID: 1,
        TapeQuantity: 0,
        Width: 0,
        Length: 0,
        Depth: 0,
        Output: 0,
        outPutRDCL: 0,
        outPutRDCW: 0,
        Score: '',
        WidthBoard: 0,
        LengthBoard: 0,
        AddTrim:0,
        BoxArea: 0,
        DensityBoard: 0,
        BoxWeight: 0,
        AreaShipment: 0,
        TotalAreaRequired: 0,
        TotalWeightRequired: 0,
        InkNumber: 0,
        InkID01: 0,
        PerInk01: 0,
        PerPrintingPlate01: 0,
        InkID02: 0,
        PerInk02: 0,
        PerPrintingPlate02: 0,
        InkID03: 0,
        PerInk03: 0,
        PerPrintingPlate03: 0,
        InkID04: 0,
        PerInk04: 0,
        PerPrintingPlate04: 0,
        InkID05: 0,
        PerInk05: 0,
        PerPrintingPlate05: 0,
        InkID06: 0,
        PerInk06: 0,
        PerPrintingPlate06: 0,
        BoxBundle: 25,
        StrapDirection: "al ancho del atado",
        StrapNumber: 2,
        BundleWidthPallet: 1,
        BundleAlongPallet: 1,
        Layer: 6,
        StrechFilm: false,
        Pallet: false,
        PlateDie: false,
        ProductionTimeCorr: 0,
        ProductionTimeConv: 0,
        CostMPNeto: 0,
        CostMP: 0,
        CostAuxCorr: 0,
        CostCorrToConv: 0,
        CostFixed: 0,
        SubTotCorrugator: 0,
        CostMacConverting: 0,
        CostInk: 0,
        CostPlateTotal: 0,
        CostDieTotal: 0,
        CostPlate: 0,
        CostDie: 0,
        CostPVA: 0,
        CostTape: 0,
        CostCover: 0,
        CostFilm: 0,
        CostBoardPackaging: 0,
        CostPallet: 0,
        CostStrapping5mm: 0,
        CostStrapping12mm: 0,
        CostWasteConv: 0,
        SubTotConverting: 0,
        //consumptions materials
        ConsStarch: 0,
        ConsCoating: 0,
        ConsPVA: 0,
        ConsDieRDC: 0,
        ConsDieFDC: 0,
        ConsStringKing: 0,
        ConsOpenSesame: 0,
        ConsCover: 0,
        ConsInk01: 0,
        ConsInk02: 0,
        ConsInk03: 0,
        ConsInk04: 0,
        ConsInk05: 0,
        ConsInk06: 0,
        ConsPlate01: 0,
        ConsPlate02: 0,
        ConsPlate03: 0,
        ConsPlate04: 0,
        ConsPlate05: 0,
        ConsPlate06: 0,
        ConsStrapping5mm: 0,
        ConsStrapping12mm: 0,
        ConsStrechFilm: 0,
        ConsPallet: 0,
        ConsBoardPackaging: 0,
        ConsWater: 0,
        ConsGasForklift: 0,
        ConsGasBoiler: 0,
        //fields for partitions 20/11/2023
         //fields for partitions 20/11/2023
         QuantityDivLong: 0,
         QuantityDivShort: 0,
         BoardIDPartition: '',
         TypeMachPartition: '',
         AssemblyPartition: false,
         WidthDivLong: 0,
         LengthDivLong: 0,
         WidthDivShort: 0,
         LengthDivShort: 0,
         BoxAreaDivLong: 0,
         BoxAreaDivShort: 0,
         WidthBoardDivLong: 0,
         LengthBoardDivLong: 0,
         WidthBoardDivShort: 0,
         LengthBoardDivShort: 0,
         OutputDivLong: 0,
         OutputDivShort: 0,
         DensityBoardPartition: 0,
         TotalAreaRequiredDivLong: 0,
         TotalAreaRequiredDivShort: 0,
         //separadores horizontales
         SeparatorTop: false,
         SeparatorCenter: false,
         SeparatorDown: false,
         QuantitySepTop: 0,
         QuantitySepCenter: 0,
         QuantitySepDown: 0,
         BoardIDSepTop: '',
         BoardIDSepCenter: '',
         BoardIDSepDown: '',
         TypeMachSepTop: '',
         TypeMachSepCenter: '',
         TypeMachSepDown: '',
         WidthSepTop: 0,
         LengthSepTop: 0,
         WidthSepCenter: 0,
         LengthSepCenter: 0,
         WidthSepDown: 0,
         LengthSepDown: 0,
         BoxAreaSepTop: 0,
         BoxAreaSepCenter: 0,
         BoxAreaSepDown: 0,
         WidthBoardSepTop: 0,
         LengthBoardSepTop: 0,
         WidthBoardSepCenter: 0,
         LengthBoardSepCenter: 0,
         WidthBoardSepDown: 0,
         LengthBoardSepDown: 0,
         OutputSepTop: 0,
         OutputSepCenter: 0,
         OutputSepDown: 0,
         DensityBoardSetTop: 0,
         DensityBoardSetCenter: 0,
         DensityBoardSetDown: 0,
         TotalAreaRequiredSepTop: 0,
         TotalAreaRequiredSepCenter: 0,
         TotalAreaRequiredSepDown: 0,
  //end fields partitions
        Total:0,
      }
     else
        this.formData = Object.assign({}, this.quoteService.quoteItems[this.data.quoteItemIndex]);
        this.mostrarClosureEdit();
        if (this.formData.BoardID == null){
          this.boardcostService.getBoardCostListId("C-19-K-MI-AI-XX-1").subscribe((boardCosts: Boardcost) => {
            this.boardCost = boardCosts}, error => console.error(error));
        } else {
          this.boardcostService.getBoardCostListId(this.formData.BoardID).subscribe((boardCosts: Boardcost) => {
            this.boardCost = boardCosts}, error => console.error(error));
        };
        this.outPutDRC = this.formData.Output
        this.outPutRDCL = this.formData.outPutRDCL;
        this.outPutRDCW = this.formData.outPutRDCW;
    //this.outPutDRC = this.formData.Output;
    //this.outPutRDCW = this.formData.outPutRDCW;
    //this.outPutRDCL = this.formData.outPutRDCL;
  }
  // despues de la vista hacer
  ngAfterViewInit(){
        if(this.inknumber2==0){
        (<HTMLInputElement> document.getElementById("InkID01")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk01")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate01")).disabled = true;

        (<HTMLInputElement> document.getElementById("InkID02")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk02")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate02")).disabled = true;

        (<HTMLInputElement> document.getElementById("InkID03")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk03")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate03")).disabled = true;

        (<HTMLInputElement> document.getElementById("InkID04")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk04")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate04")).disabled = true;

        (<HTMLInputElement> document.getElementById("InkID05")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk05")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate05")).disabled = true;

        (<HTMLInputElement> document.getElementById("InkID06")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk06")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).disabled = true;
      }else if(this.inknumber2==1){
        (<HTMLInputElement> document.getElementById("InkID02")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk02")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate02")).disabled = true;

        (<HTMLInputElement> document.getElementById("InkID03")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk03")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate03")).disabled = true;

        (<HTMLInputElement> document.getElementById("InkID04")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk04")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate04")).disabled = true;

        (<HTMLInputElement> document.getElementById("InkID05")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk05")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate05")).disabled = true;

        (<HTMLInputElement> document.getElementById("InkID06")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk06")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).disabled = true;
      }else if(this.inknumber2==2){

        (<HTMLInputElement> document.getElementById("InkID03")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk03")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate03")).disabled = true;

        (<HTMLInputElement> document.getElementById("InkID04")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk04")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate04")).disabled = true;

        (<HTMLInputElement> document.getElementById("InkID05")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk05")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate05")).disabled = true;

        (<HTMLInputElement> document.getElementById("InkID06")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk06")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).disabled = true;
      }else if(this.inknumber2==3){
        (<HTMLInputElement> document.getElementById("InkID04")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk04")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate04")).disabled = true;

        (<HTMLInputElement> document.getElementById("InkID05")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk05")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate05")).disabled = true;

        (<HTMLInputElement> document.getElementById("InkID06")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk06")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).disabled = true;
      }else if(this.inknumber2==4){
        (<HTMLInputElement> document.getElementById("InkID05")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk05")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate05")).disabled = true;

        (<HTMLInputElement> document.getElementById("InkID06")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk06")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).disabled = true;
      }else if(this.inknumber2==5){
        (<HTMLInputElement> document.getElementById("InkID06")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerInk06")).disabled = true;
        (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).disabled = true;
      }
  }

  onSubmit (form: NgForm){

    this.onClosureBox();
    if (this.validateForm(form.value)){
      if(this.formData.PlateDie == false){
        this.formData.CostDie = 0;
        this.formData.CostPlate = 0;
      }
      if (this.data.quoteItemIndex == null)
        //this.quoteService.quoteItems.push(form.value);
        this.quoteService.quoteItems.push(this.formData);
      else
        //this.quoteService.quoteItems[this.data.quoteItemIndex] = form.value;
        this.quoteService.quoteItems[this.data.quoteItemIndex] = this.formData;
        this.dialogRef.close();
        this.onNewOrEditQuote();
    };
    this.isValid = true;
    //test
  };
  
//validateForm(formData: QuoteItem){
  validateForm(formData: QuoteItem){
    this.onvalidateForm();
    if (this.isValid == false){
      exit;
    }else {
      this.isValid = true;
      this.onvalidatePrintPlate();
      this.formData.PerPrintingPlate01 = +this.formData.PerPrintingPlate01;
      this.formData.PerPrintingPlate02 = +this.formData.PerPrintingPlate02;
      this.formData.PerPrintingPlate03 = +this.formData.PerPrintingPlate03;
      this.formData.PerPrintingPlate04 = +this.formData.PerPrintingPlate04;
      this.formData.PerPrintingPlate05 = +this.formData.PerPrintingPlate05;
      this.formData.PerPrintingPlate06 = +this.formData.PerPrintingPlate06;
      this.quoteService.formData.BoardID = this.formData.BoardID;
      this.onCargoType();
      this.onBoardCost(); 
      if(this.formData.InkNumber == 0){
        this.formData.InkID01 = 31;
        this.formData.PerInk01 = 1;
        this.formData.PerPrintingPlate01 = 1;
      };         
    };
    return this.isValid;
  };

  updateToleranceBoard(tolerance){ 
    if (tolerance.selectedIndex == 0 ) {

      this.formData.FluteID = 0;
      this.formData.BoardID = '';
    } 
    else {
      this.formData.FluteID = this.boardList[tolerance.selectedIndex-1].FluteID;
      this.formData.BoardID = this.boardList[tolerance.selectedIndex-1].BoardID;
      this.formData.DensityBoard = this.boardList[tolerance.selectedIndex-1].Density;
      this.ongetDevelopmentList();
      this.ongetBoardCost();
    };
  };
  
  updateToleranceClosure(tolerance, form: NgForm){ 
    if (tolerance.selectedIndex != 0 && this.formData.StyleID == 0 || this.formData.BoardID == null) {
      this.toastr.error('Por favor, selecciona un Estilo y/o Resistencia', 'Corrugated Quote.');
      this.validateForm(form.value);
    } 
    else {
      this.ongetDevelopmentList();
    }
  };

  onDevelopmentBox (){
    let outPuts: number;
    //this.outPutRDCW = this.formData.outPutRDCW;
    //this.outPutRDCL = this.formData.outPutRDCL;

    if (this.developmentList?.length != null){
      if(this.formData.StyleID == 1){
        this.formData.LengthBoard = (2 * (+this.formData.Length + +this.formData.Width)) + +this.developmentList[this.developmentList?.length-1]?.LT;
        this.formData.WidthBoard = (2 * (+this.formData.Width/2)) + +this.formData.Depth + +this.developmentList[this.developmentList?.length-1]?.WT;
        this.formData.BoxArea = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard)/1000000).toFixed(4));
        this.formData.BoxWeight = parseFloat((this.formData.DensityBoard * this.formData.BoxArea).toFixed(2));
        this.formData.AreaShipment = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard * this.quoteService.formData.BoxShipment)/1000000000).toFixed(4));
        this.formData.TotalAreaRequired = parseFloat((this.formData.LengthBoard * this.formData.WidthBoard/1000000 * (1 + (this.formData.AddTrim/100))).toFixed(4));
        this.formData.TotalWeightRequired = parseFloat(((this.formData.DensityBoard * this.formData.LengthBoard * this.formData.WidthBoard /1000000) * (1 + (this.formData.AddTrim/100))).toFixed(2));
      }else if (this.formData.StyleID ==2){
        this.formData.LengthBoard = (2 * (+this.formData.Length + +this.formData.Width)) + +this.developmentList[this.developmentList?.length-1]?.LT;
        this.formData.WidthBoard = (+this.formData.Width/2) + +this.formData.Depth + +this.developmentList[this.developmentList?.length-1]?.WT;
        this.formData.BoxArea = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard)/1000000).toFixed(4));
        this.formData.BoxWeight = parseFloat((this.formData.DensityBoard * this.formData.BoxArea).toFixed(2));
        this.formData.AreaShipment = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard * this.quoteService.formData.BoxShipment)/1000000000).toFixed(4));
        this.formData.TotalAreaRequired = parseFloat((this.formData.LengthBoard * this.formData.WidthBoard/1000000 * (1 + (this.formData.AddTrim/100))).toFixed(4));
        this.formData.TotalWeightRequired = parseFloat(((this.formData.DensityBoard * this.formData.LengthBoard * this.formData.WidthBoard /1000000) * (1 + (this.formData.AddTrim/100))).toFixed(2));
      }else if (this.formData.StyleID == 3){
        this.formData.LengthBoard = (2 * (+this.formData.Length + +this.formData.Width)) + +this.developmentList[this.developmentList?.length-1]?.LT;
        this.formData.WidthBoard = (1.5 *(+this.formData.Width)) + +this.formData.Depth + +this.developmentList[this.developmentList?.length-1]?.WT;
        this.formData.BoxArea = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard)/1000000).toFixed(4));
        this.formData.BoxWeight = parseFloat((this.formData.DensityBoard * this.formData.BoxArea).toFixed(2));
        this.formData.AreaShipment = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard * this.quoteService.formData.BoxShipment)/1000000000).toFixed(4));
        this.formData.TotalAreaRequired = parseFloat((this.formData.LengthBoard * this.formData.WidthBoard/1000000 * (1 + (this.formData.AddTrim/100))).toFixed(4));
        this.formData.TotalWeightRequired = parseFloat(((this.formData.DensityBoard * this.formData.LengthBoard * this.formData.WidthBoard /1000000) * (1 + (this.formData.AddTrim/100))).toFixed(2));
      }else if (this.formData.StyleID == 4){
        this.formData.LengthBoard = (2 * (+this.formData.Length + +this.formData.Width)) + +this.developmentList[this.developmentList?.length-1]?.LT;
        this.formData.WidthBoard = (2*(+this.formData.Width)) + +this.formData.Depth + +this.developmentList[this.developmentList?.length-1]?.WT;
        this.formData.BoxArea = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard)/1000000).toFixed(4));
        this.formData.BoxWeight = parseFloat((this.formData.DensityBoard * this.formData.BoxArea).toFixed(2));
        this.formData.AreaShipment = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard * this.quoteService.formData.BoxShipment)/1000000000).toFixed(4));
        this.formData.TotalAreaRequired = parseFloat((this.formData.LengthBoard * this.formData.WidthBoard/1000000 * (1 + (this.formData.AddTrim/100))).toFixed(4));
        this.formData.TotalWeightRequired = parseFloat(((this.formData.DensityBoard * this.formData.LengthBoard * this.formData.WidthBoard /1000000) * (1 + (this.formData.AddTrim/100))).toFixed(2));
      }else if (this.formData.StyleID == 5){
          if ((this.workstationList[2].LenghtMax * 10 / this.formData.Length) > 2 && (this.workstationList[2].WidthMax * 10 / this.formData.Width) > 5){
            this.formData.Output = 10;
            outPuts = 2;
            this.outPutRDCL = 5;
            this.outPutRDCW = 2;
          }else if ((this.workstationList[2].LenghtMax * 10 / this.formData.Length) < 2 && (this.workstationList[2].WidthMax * 10 / this.formData.Width) > 5){
            this.formData.Output = 5;
            outPuts = 1;
            this.outPutRDCL = 5;
            this.outPutRDCW = 1;
          }else if((this.workstationList[2].LenghtMax * 10 / this.formData.Length) > 2 && (this.workstationList[2].WidthMax * 10 / this.formData.Width) < 5){
            this.formData.Output = Math.floor(this.workstationList[2].WidthMax * 10 / this.formData.Width) * Math.floor(this.workstationList[2].LenghtMax * 10 / this.formData.Length);
            outPuts = Math.floor(this.workstationList[2].WidthMax * 10 / this.formData.Width);
            this.outPutRDCL = Math.floor(this.workstationList[2].LenghtMax * 10 / this.formData.Length);
            this.outPutRDCW = outPuts;
          }else if ((this.workstationList[2].LenghtMax * 10 / this.formData.Length) < 2 && (this.workstationList[2].WidthMax * 10 / this.formData.Width) < 5){
            this.formData.Output = Math.floor(this.workstationList[2].WidthMax * 10 / this.formData.Width);
            outPuts = 1;
            this.outPutRDCL = 1;
            this.outPutRDCW = Math.floor(this.workstationList[2].WidthMax * 10 / this.formData.Width);
          };
          this.formData.LengthBoard = (this.formData.Length * this.outPutRDCL) + +this.developmentList[this.developmentList?.length-1]?.LT;
          this.formData.WidthBoard = (this.formData.Width * this.outPutRDCW) + +this.developmentList[this.developmentList?.length-1]?.WT;
          this.formData.BoxArea = parseFloat(((this.formData.Width * this.formData.Length)/1000000).toFixed(4));
          this.formData.BoxWeight = parseFloat((this.formData.DensityBoard * this.formData.BoxArea).toFixed(3));
          this.formData.AreaShipment = parseFloat(((this.formData.Width * this.formData.Length * this.quoteService.formData.BoxShipment)/1000000000).toFixed(4));
          this.formData.TotalAreaRequired = parseFloat((((this.formData.LengthBoard * this.formData.WidthBoard / 1000000) * (1 + (this.formData.AddTrim/100))) / this.formData.Output).toFixed(4));
          this.formData.TotalWeightRequired = parseFloat((((this.formData.DensityBoard * this.formData.LengthBoard * this.formData.WidthBoard /1000000) * (1 + (this.formData.AddTrim/100))) / this.formData.Output).toFixed(3));
          this.outPutDRC = this.formData.Output;
          this.formData.outPutRDCL = this.outPutRDCL;
          this.formData.outPutRDCW = this.outPutRDCW;
      } else if(this.formData.StyleID == 7){
          this.outPutRDCL = Math.floor(this.workstationList[4].LenghtMax * 10 / this.formData.Length);
          this.outPutRDCW = Math.floor(this.workstationList[4].WidthMax * 10 / this.formData.Width);
          this.formData.Output = this.outPutRDCL * this.outPutRDCW;
          this.formData.LengthBoard = (this.formData.Length * this.outPutRDCL) + 20;
          this.formData.WidthBoard = (this.formData.Width * this.outPutRDCW) + 20;
          this.formData.BoxArea = parseFloat(((this.formData.Width * this.formData.Length)/1000000).toFixed(4));
          this.formData.BoxWeight = parseFloat((this.formData.DensityBoard * this.formData.BoxArea).toFixed(3));
          this.formData.AreaShipment = parseFloat(((this.formData.Width * this.formData.Length * this.quoteService.formData.BoxShipment)/1000000000).toFixed(4));
          this.formData.TotalAreaRequired = parseFloat((((this.formData.LengthBoard * this.formData.WidthBoard / 1000000) * (1 + (this.formData.AddTrim/100))) / this.formData.Output).toFixed(4));
          this.formData.TotalWeightRequired = parseFloat((((this.formData.DensityBoard * this.formData.LengthBoard * this.formData.WidthBoard /1000000) * (1 + (this.formData.AddTrim/100))) / this.formData.Output).toFixed(3));
          this.formData.outPutRDCL = this.outPutRDCL;
          this.formData.outPutRDCW = this.outPutRDCW;
        }else if(this.formData.StyleID == 8){
        this.formData.LengthBoard = (2 * (+this.formData.Length + +this.formData.Width)) + +this.developmentList[this.developmentList?.length-1]?.LT;
        this.formData.WidthBoard = (2 * (+this.formData.Width/2)) + +this.formData.Depth + +(this.formData.Overlap / 2) + +this.developmentList[this.developmentList?.length-1]?.WT;
        this.formData.BoxArea = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard)/1000000).toFixed(4));
        this.formData.BoxWeight = parseFloat((this.formData.DensityBoard * this.formData.BoxArea).toFixed(2));
        this.formData.AreaShipment = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard * this.quoteService.formData.BoxShipment)/1000000000).toFixed(4));
        this.formData.TotalAreaRequired = parseFloat((this.formData.LengthBoard * this.formData.WidthBoard/1000000 * (1 + (this.formData.AddTrim/100))).toFixed(4));
        this.formData.TotalWeightRequired = parseFloat(((this.formData.DensityBoard * this.formData.LengthBoard * this.formData.WidthBoard /1000000) * (1 + (this.formData.AddTrim/100))).toFixed(2));
      }else if(this.formData.StyleID == 9){
        this.formData.LengthBoard = (2 * (+this.formData.Length + +this.formData.Width)) + +this.developmentList[this.developmentList?.length-1]?.LT;
        this.formData.WidthBoard = (2 * (+this.formData.Width/2)) + +this.formData.Depth + +this.formData.Overlap + +this.developmentList[this.developmentList?.length-1]?.WT;
        this.formData.BoxArea = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard)/1000000).toFixed(4));
        this.formData.BoxWeight = parseFloat((this.formData.DensityBoard * this.formData.BoxArea).toFixed(2));
        this.formData.AreaShipment = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard * this.quoteService.formData.BoxShipment)/1000000000).toFixed(4));
        this.formData.TotalAreaRequired = parseFloat((this.formData.LengthBoard * this.formData.WidthBoard/1000000 * (1 + (this.formData.AddTrim/100))).toFixed(4));
        this.formData.TotalWeightRequired = parseFloat(((this.formData.DensityBoard * this.formData.LengthBoard * this.formData.WidthBoard /1000000) * (1 + (this.formData.AddTrim/100))).toFixed(2));
      }else if(this.formData.StyleID == 10){
        this.formData.LengthBoard = (2 * (+this.formData.Length + +this.formData.Width)) + +this.developmentList[this.developmentList?.length-1]?.LT;
        this.formData.WidthBoard = (2 * (+this.formData.Width/2)) + +this.formData.Depth + +this.developmentList[this.developmentList?.length-1]?.WT;
        this.formData.BoxArea = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard)/1000000).toFixed(4));
        this.formData.BoxWeight = parseFloat((this.formData.DensityBoard * this.formData.BoxArea).toFixed(2));
        this.formData.AreaShipment = parseFloat(((this.formData.LengthBoard * this.formData.WidthBoard * this.quoteService.formData.BoxShipment)/1000000000).toFixed(4));
        this.formData.TotalAreaRequired = parseFloat((this.formData.LengthBoard * this.formData.WidthBoard/1000000 * (1 + (this.formData.AddTrim/100))).toFixed(4));
        this.formData.TotalWeightRequired = parseFloat(((this.formData.DensityBoard * this.formData.LengthBoard * this.formData.WidthBoard /1000000) * (1 + (this.formData.AddTrim/100))).toFixed(2));
      }
        this.onCargoType();
        //this.onPattern();
        //this.onLayer();
        this.ongetBoardCost();
    }; 
  };

  onDevelopmentBoxTrim (){
    //let outPuts: number; let areaLayer: number; let numberBundle: number;
    if (this.developmentList?.length != null){
      if(this.formData.StyleID <= 4 || this.formData.StyleID >= 8){
        this.formData.TotalAreaRequired = parseFloat((this.formData.LengthBoard * this.formData.WidthBoard/1000000 * (1 + (this.formData.AddTrim/100))).toFixed(4));
        this.formData.TotalWeightRequired = parseFloat(((this.formData.DensityBoard * this.formData.LengthBoard * this.formData.WidthBoard /1000000) * (1 + (this.formData.AddTrim/100))).toFixed(2));
      }else {
          this.formData.TotalAreaRequired = parseFloat((((this.formData.LengthBoard * this.formData.WidthBoard / 1000000) * (1 + (this.formData.AddTrim/100))) / this.formData.Output).toFixed(4));
          this.formData.TotalWeightRequired = parseFloat((((this.formData.DensityBoard * this.formData.LengthBoard * this.formData.WidthBoard /1000000) * (1 + (this.formData.AddTrim/100))) / this.formData.Output).toFixed(3));
          this.outPutDRC = this.formData.Output;
      };
      this.onCargoType();
      //this.onPattern();
      //this.onLayer();
      this.ongetBoardCost();
      }; 
  };
  //we are gonna do a codigo for RDC development
  onDevelopmentBoxRDC (){
    let outPuts: number;
    let outPutL: number; let outPutW: number;
    //this.formData.outPutRDCW = this.outPutRDCW;
    //this.formData.outPutRDCL = this.outPutRDCL; 
    if (this.developmentList?.length != null){
      if(this.formData.StyleID == 5){
        if(this.formData.Output <= this.outPutDRC){
          outPuts = this.formData.Output;
          if (this.outPutRDCW == 1 && this.outPutRDCL <= 5) {
            this.formData.WidthBoard = (this.formData.Width) + +this.developmentList[this.developmentList?.length-1]?.LT;
            this.formData.LengthBoard = (this.formData.Length * this.formData.Output) + +this.developmentList[this.developmentList?.length-1]?.WT;
          }else if(this.outPutRDCW == 2 && this.outPutRDCL == 5){
            if(outPuts == 7 || outPuts == 9 ){
              if(this.quoteService.quoteItems.length == 0){
                this.getformFocusOutPut("Output", "Cajas por golpe pares")
              }else {
                this.getformFocusOutPut("Length", "Cajas por golpe pares, ingrese nuevamente el largo y/o ancho de la caja")
              };
              
            } else if(outPuts == 6 || outPuts == 8 || outPuts == 10){
              this.formData.LengthBoard = (this.formData.Length * this.formData.Output / 2) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 2) + +this.developmentList[this.developmentList?.length-1]?.WT;
            } else if(outPuts <= 5){
              this.formData.WidthBoard = (this.formData.Width) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.LengthBoard = (this.formData.Length * this.formData.Output) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }
          }else if(this.outPutRDCW == 2 && this.outPutRDCL == 4){
            if(outPuts == 5 || outPuts == 7 ){
              if(this.quoteService.quoteItems.length == 0){
                this.getformFocusOutPut("Output", "Cajas por golpe pares")
              } else {
                this.getformFocusOutPut("Length", "Cajas por golpe pares, ingrese nuevamente el largo y/o ancho de la caja")
              };
            } else if(outPuts == 6 || outPuts == 8){
              this.formData.LengthBoard = (this.formData.Length * this.formData.Output / 2) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 2) + +this.developmentList[this.developmentList?.length-1]?.WT;
            } else if(outPuts <= 4){
              this.formData.LengthBoard = (this.formData.Length * this.formData.Output) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }
          }else if(this.outPutRDCW == 2 && this.outPutRDCL == 3){
            if(outPuts == 5){
              if(this.quoteService.quoteItems.length == 0){
                this.getformFocusOutPut("Output", "Cajas por golpe pares")
              } else {
                this.getformFocusOutPut("Length", "Cajas por golpe pares, ingrese nuevamente el largo y/o ancho de la caja")
              };
            } else if(outPuts == 4 || outPuts == 6){
              this.formData.LengthBoard = (this.formData.Length * this.formData.Output / 2) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 2) + +this.developmentList[this.developmentList?.length-1]?.WT;
            } else if(outPuts <= 3){
              this.formData.LengthBoard = (this.formData.Length * this.formData.Output) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }
          }else if(this.outPutRDCW == 2 && this.outPutRDCL == 2){
            if(outPuts == 3){
              if(this.quoteService.quoteItems.length == 0){
                this.getformFocusOutPut("Output", "Cajas por golpe pares")
              } else {
                this.getformFocusOutPut("Length", "Cajas por golpe pares, ingrese nuevamente el largo y/o ancho de la caja")
              };
            } else if(outPuts == 2 || outPuts == 4){
              this.formData.LengthBoard = (this.formData.Length * this.formData.Output / 2) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 2) + +this.developmentList[this.developmentList?.length-1]?.WT;
            } else if(outPuts < 2){
              this.formData.LengthBoard = (this.formData.Length * this.formData.Output) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }
          }else if(this.outPutRDCW == 2 && this.outPutRDCL == 1){
            if(outPuts > 2){
              if(this.quoteService.quoteItems.length == 0){
                this.getformFocusOutPut("Output", "Cajas por golpe pares")
              } else {
                this.getformFocusOutPut("Length", "Cajas por golpe pares, ingrese nuevamente el largo y/o ancho de la caja")
              };
            } else if(outPuts == 2){
              this.formData.LengthBoard = (this.formData.Length) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 2) + +this.developmentList[this.developmentList?.length-1]?.WT;
            } else if(outPuts < 2){
              this.formData.LengthBoard = (this.formData.Length) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * this.formData.Output) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }
          }else if(this.outPutRDCW <= 5  && this.outPutRDCL == 1){
            if(outPuts > 5){
              if(this.quoteService.quoteItems.length == 0){
                this.getformFocusOutPut("Output", "Cajas por golpe pares")
              } else {
                this.getformFocusOutPut("Length", "Cajas por golpe pares, ingrese nuevamente el largo y/o ancho de la caja")
              };
            } else {
              this.formData.LengthBoard = (this.formData.Length) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * this.formData.Output) + +this.developmentList[this.developmentList?.length-1]?.WT;
            };
          }else if(this.outPutRDCW <= 3  && this.outPutRDCL == 2){
            if(outPuts == 3 || outPuts == 5 || outPuts > 6){
              if(this.quoteService.quoteItems.length == 0){
                this.getformFocusOutPut("Output", "Cajas por golpe pares")
              } else {
                this.getformFocusOutPut("Length", "Cajas por golpe pares, ingrese nuevamente el largo y/o ancho de la caja")
              };
            } else if(outPuts == 4){
              this.formData.LengthBoard = (this.formData.Length * 2) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 2) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }else if(outPuts == 2){
              this.formData.LengthBoard = (this.formData.Length * 2) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }else if(outPuts == 1){
              this.formData.LengthBoard = (this.formData.Length) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width) + +this.developmentList[this.developmentList?.length-1]?.WT;
            };
          }else if(this.outPutRDCW <= 3  && this.outPutRDCL == 3){
            if(outPuts == 5 || outPuts == 7 || outPuts == 8 || outPuts > 9){
              if(this.quoteService.quoteItems.length == 0){
                this.getformFocusOutPut("Output", "Cajas por golpe multiplos de 3 o menores a 3 ó 4")
              } else {
                this.getformFocusOutPut("Length", "Cajas por golpe multiplos de 3 o menores a 3 ó 4, ingrese nuevamente el largo y/o ancho de la caja")
              };
            } else if(outPuts <= 3){
              this.formData.LengthBoard = (this.formData.Length * outPuts) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }else if(outPuts == 4){
              this.formData.LengthBoard = (this.formData.Length * 2) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 2) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }else if(outPuts == 6){
              this.formData.LengthBoard = (this.formData.Length * 3) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 2) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }else if(outPuts == 9){
              this.formData.LengthBoard = (this.formData.Length * 3) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 3) + +this.developmentList[this.developmentList?.length-1]?.WT;
            };
          }else if(this.outPutRDCW <= 3  && this.outPutRDCL == 4){
            if(outPuts == 5 || outPuts == 7 || outPuts == 10 || outPuts == 11 || outPuts > 12){
              if(this.quoteService.quoteItems.length == 0){
                this.getformFocusOutPut("Output", "Cajas por golpe multiplos de 3 ó 4 y menores a 13")
              } else {
                this.getformFocusOutPut("Length", "Cajas por golpe multiplos de 3 ó 4 y menores a 13 o ingrese nuevamente el largo y/o ancho de la caja")
              };
            } else if(outPuts <= 4){
              this.formData.LengthBoard = (this.formData.Length * outPuts) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }else if(outPuts == 6){
              this.formData.LengthBoard = (this.formData.Length * 3) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 2) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }else if(outPuts == 8){
              this.formData.LengthBoard = (this.formData.Length * 4) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 2) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }else if(outPuts == 9){
              this.formData.LengthBoard = (this.formData.Length * 3) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 3) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }else if(outPuts == 12){
              this.formData.LengthBoard = (this.formData.Length * 4) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 3) + +this.developmentList[this.developmentList?.length-1]?.WT; 
            };
          }else if(this.outPutRDCW <= 3  && this.outPutRDCL == 5){
            if(outPuts == 7 || outPuts == 11 || outPuts == 13 || outPuts == 14 || outPuts > 15){
              if(this.quoteService.quoteItems.length == 0){
                this.getformFocusOutPut("Output", "Cajas por golpe multiplos de 3 ó 5 y menores a 16")
              } else {
                this.getformFocusOutPut("Length", "Cajas por golpe multiplos de 3 ó 5 y menores a 16 o ingrese nuevamente el largo y/o ancho de la caja")
              };
            } else if(outPuts <= 5){
              this.formData.LengthBoard = (this.formData.Length * outPuts) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }else if(outPuts == 6){
              this.formData.LengthBoard = (this.formData.Length * 3) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 2) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }else if(outPuts == 8){
              this.formData.LengthBoard = (this.formData.Length * 4) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 2) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }else if(outPuts == 9){
              this.formData.LengthBoard = (this.formData.Length * 3) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 3) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }else if(outPuts == 10){
              this.formData.LengthBoard = (this.formData.Length * 5) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 2) + +this.developmentList[this.developmentList?.length-1]?.WT;
            }else if(outPuts == 12){
              this.formData.LengthBoard = (this.formData.Length * 4) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 3) + +this.developmentList[this.developmentList?.length-1]?.WT; 
            }else if(outPuts == 15){
              this.formData.LengthBoard = (this.formData.Length * 5) + +this.developmentList[this.developmentList?.length-1]?.LT;
              this.formData.WidthBoard = (this.formData.Width * 3) + +this.developmentList[this.developmentList?.length-1]?.WT; 
            };
          };
          
          this.formData.BoxArea = parseFloat(((this.formData.Width * this.formData.Length)/1000000).toFixed(4));
          this.formData.BoxWeight = parseFloat((this.formData.DensityBoard * this.formData.BoxArea).toFixed(3));
          this.formData.AreaShipment = parseFloat(((this.formData.Width * this.formData.Length * this.quoteService.formData.BoxShipment)/1000000000).toFixed(4));
          this.formData.TotalAreaRequired = parseFloat((((this.formData.LengthBoard * this.formData.WidthBoard / 1000000) * (1 + (this.formData.AddTrim/100))) / this.formData.Output).toFixed(4));
          this.formData.TotalWeightRequired = parseFloat((((this.formData.DensityBoard * this.formData.LengthBoard * this.formData.WidthBoard /1000000) * (1 + (this.formData.AddTrim/100))) / this.formData.Output).toFixed(3));
          //we are gonna calculated palletizing.
        } else {
          this.formData.LengthBoard = 0;
          this.formData.WidthBoard = 0;
          this.formData.Output = 0;
          //this.formData.Length = this.formData.Length;
          if(this.quoteService.quoteItems.length == 0){
            this.getformFocusOutPut("Output", "Cajas por golpe validas")
          } else {
            this.getformFocusOutPut("Length", "Cajas por golpe validas, ingrese nuevamente el largo y/o ancho de la caja")
          };
          
          //this.onDevelopmentBox();
        }
      } else if(this.formData.StyleID == 7){

          if ((this.formData.Output > (this.outPutRDCL * this.outPutRDCW)) || (this.formData.Output < (this.outPutRDCL * this.outPutRDCW / 4))){
            this.formData.LengthBoard = 0;
            this.formData.WidthBoard = 0;
            this.formData.Output = 0;
            if(this.quoteService.quoteItems.length == 0){
              this.getformFocusOutPut("Output", "Divisiones por golpe validas")
            } else {
              this.getformFocusOutPut("Length", "Divisiones por golpe validas, ingrese nuevamente el largo y/o ancho")
            };
            
          } else {
            outPutL = Math.ceil((this.formData.Output / this.outPutRDCW))
            outPutW = (this.formData.Output / outPutL);
            if (outPutW != Math.ceil(outPutW)){
              if(this.quoteService.quoteItems.length == 0){
                this.getformFocusOutPut("Output", "el valor ideal para este campo seria: " + (Math.ceil(outPutW) * outPutL))
              } else {
                this.getformFocusOutPut("Length", "el valor ideal para este campo seria: " + (Math.ceil(outPutW) * outPutL) + ", ingrese nuevamente el largo y/o ancho")
              };
              
              this.formData.Output = 0;
            } else {            
              this.formData.LengthBoard = (this.formData.Length * outPutL) + 20;
              this.formData.WidthBoard = (this.formData.Width * outPutW) + 20;
              this.formData.BoxArea = parseFloat(((this.formData.Width * this.formData.Length)/1000000).toFixed(4));
              this.formData.BoxWeight = parseFloat((this.formData.DensityBoard * this.formData.BoxArea).toFixed(3));
              this.formData.AreaShipment = parseFloat(((this.formData.Width * this.formData.Length * this.quoteService.formData.BoxShipment)/1000000000).toFixed(4));
              this.formData.TotalAreaRequired = parseFloat((((this.formData.LengthBoard * this.formData.WidthBoard / 1000000) * (1 + (this.formData.AddTrim/100))) / this.formData.Output).toFixed(4));
              this.formData.TotalWeightRequired = parseFloat((((this.formData.DensityBoard * this.formData.LengthBoard * this.formData.WidthBoard /1000000) * (1 + (this.formData.AddTrim/100))) / this.formData.Output).toFixed(3));
            };
          };
      };
    };
    this.onCargoType();
    //this.onPattern();
    //this.onLayer();
    this.ongetBoardCost();
  };


  //call costs of Board
  onBoardCost(){
    let productionTimeCorr: number; let productionTimeConv: number; 
    let productionTimeFolding: number; let productionTimePartition: number;
    let costMacFolding: number =0; let costMacPartition: number = 0;
    let costInk01: number = 0; let costInk02: number = 0; let costInk03: number = 0; let costInk04: number = 0; 
    let costInk05: number = 0; let costInk06: number = 0; let inkCostTotalArea: number = 0;
    let costPVA01: number = 0; let costCover01: number = 0; let indexSelected: number;
    let costStrapping01: number = 0; let costStrapping02: number = 0; 
    let thickness: number = 0; let costPlateCM2: number = 0; let costPlate: number = 0;
    let costDieCM: number = 0; let costDie: number = 0; let boxArea: number = 0;
    let workstation: number; let workstationPartition: number = 4; let workstationFolding: number = 3;
    let plateRatCon: number = 0; let costTape: number = 0; let costFilm: number = 0; 
    let costBoardPackaging: number = 0; let costPallet: number = 0; let smOrder: number;
    let consDieCM: number = 0; let consTape: number = 0; let consStrapping5mm: number = 0;
    let consStrapping12mm: number = 0; let consCover: number = 0; let consPallet: number = 0;
    let consStrechFilm: number = 0; let consBoardPackaging: number = 0;

    smOrder = this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired
    //in this block, we are going to calculated the production Time for corrugator
    indexSelected = this.boardList.findIndex(typeDensity => typeDensity.BoardID == this.formData.BoardID);    
    if (this.boardList[indexSelected].TypeDensity == 0) {
      productionTimeCorr = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * (1 + (this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenLow * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
    } else if(this.boardList[indexSelected].TypeDensity == 1) {
      productionTimeCorr = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * (1 + +(this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenMed * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
    } else {
      productionTimeCorr = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * (1 + (this.boardList[indexSelected].WasteCorrugatorOtherApp / 100))) / (this.workstationList[0].SpeedRegHigDenHig * this.workstationList[0].OperatingEfficiency * this.workstationList[0].TrimCorrugator / 10000);
    };
    //in this block, we are going to calculated the production Time for converting
    //for now, we use the machine 2-IMP003, but next we should determine the manufacturing route.
    if (this.formData.StyleID == 1){
      workstation = 1;
      boxArea = this.formData.BoxArea
    } else if (this.formData.StyleID == 2){  
      workstation = 1;
      boxArea = this.formData.BoxArea
    } else if (this.formData.StyleID == 3){  
      workstation = 1;
      boxArea = this.formData.BoxArea
    } else if (this.formData.StyleID == 4){  
      workstation = 1;
      boxArea = this.formData.BoxArea
    } else if (this.formData.StyleID == 5){
      workstation = 2
      boxArea = (this.formData.LengthBoard * this.formData.WidthBoard / 1000000);
    } else if (this.formData.StyleID == 7){
      workstation = 2;
      workstationPartition = 4;
      boxArea = (this.formData.LengthBoard * this.formData.WidthBoard / 1000000);
    } else if (this.formData.StyleID == 8){  
      workstation = 1;
      boxArea = this.formData.BoxArea
    } else if (this.formData.StyleID == 9){  
      workstation = 1;
      boxArea = this.formData.BoxArea
    } else if (this.formData.StyleID == 10){
      workstation = 2
      workstationFolding = 3;
      boxArea = (this.formData.LengthBoard * this.formData.WidthBoard / 1000000);
    };
    if (this.boardCost.BoardID != null){
      if (this.boardList[indexSelected].TypeDensity == 0 && this.formData.Register =='Bajo' ){
        productionTimeConv = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegLowDenLow * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegLowDenLow * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegLowDenLow * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 0 && this.formData.Register =='Medio'){
        productionTimeConv = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegMedDenLow * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegMedDenLow * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegMedDenLow * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 0 && this.formData.Register =='Alto'){
        productionTimeConv = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegHigDenLow * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegHigDenLow * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegHigDenLow * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if (this.boardList[indexSelected].TypeDensity == 1 && this.formData.Register =='Bajo' ){
        productionTimeConv = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegLowDenMed * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegLowDenMed * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegLowDenMed * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 1 && this.formData.Register =='Medio'){
        productionTimeConv = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegMedDenMed * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegMedDenMed * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegMedDenMed * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 1 && this.formData.Register =='Alto'){
        productionTimeConv = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegHigDenMed * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegHigDenMed * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegHigDenMed * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if (this.boardList[indexSelected].TypeDensity == 2 && this.formData.Register =='Bajo' ){
        productionTimeConv = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegLowDenHig * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegLowDenHig * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegLowDenHig * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 2 && this.formData.Register =='Medio'){
        productionTimeConv = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegMedDenHig * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegMedDenHig * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegMedDenHig * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      } else if(this.boardList[indexSelected].TypeDensity == 2 && this.formData.Register =='Alto'){
        productionTimeConv = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstation].SpeedRegHigDenHig * this.workstationList[workstation].OperatingEfficiency * boxArea);
        productionTimePartition = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationPartition].SpeedRegHigDenHig * this.workstationList[workstationPartition].OperatingEfficiency * boxArea);
        productionTimeFolding = (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder * 100) / (this.workstationList[workstationFolding].SpeedRegHigDenHig * this.workstationList[workstationFolding].OperatingEfficiency * boxArea);
      };
      productionTimeCorr = productionTimeCorr + 1;
      productionTimeConv = productionTimeConv + 10;
      //in this block, we will calculate production cost
      this.formData.CostMP = parseFloat((this.boardCost.BoardPaperCostMM2 * (1 + (this.boardList[indexSelected].WasteCorrugatorOtherApp / 100)) * this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder / 1000).toFixed(4));
      this.formData.CostMPNeto = this.formData.CostMP;
      if (smOrder <= 1000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeCorr01;
        productionTimeConv = productionTimeConv + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeConv01;
        this.formData.CostMP = this.formData.CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 1).Waste01 / 100));
      } else if(smOrder <= 2000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeCorr02;
        productionTimeConv = productionTimeConv + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeConv02;
        this.formData.CostMP = this.formData.CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 1).Waste02 / 100));
      } else if(smOrder <= 3000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeCorr03;
        productionTimeConv = productionTimeConv + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeConv03;
        this.formData.CostMP = this.formData.CostMP * (1 + (this.productList.find(smorder => smorder.ProductID == 1).Waste03 / 100));
      } else if(smOrder <= 4000){
        productionTimeCorr = productionTimeCorr + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeCorr04;
        productionTimeConv = productionTimeConv + +this.productList.find(smorder => smorder.ProductID == 1).WasteTimeConv04;
        this.formData.CostMP = this.formData.CostMP * (1 + (+this.productList.find(smorder => smorder.ProductID == 1).Waste04 / 100));
      };
      //The CostAuxCorrM2 is the cost by production sheetboard and sales sheetboard.
      this.formData.CostAuxCorr = parseFloat(((this.boardCost.StarchCostMM2 + +this.boardCost.CoatingCostMM2 + +this.boardCost.OtherAuxCostMM2) * (this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder / 1000)).toFixed(4));        
      this.formData.CostCorrToConv = parseFloat(((this.costrateList[0].DirLaborCostHR + +this.costrateList[0].IndLaborCostHR + +this.costrateList[0].MaintenanceCostHR + +this.costrateList[0].EnergyCostoHR + +this.costrateList[0].WaterCostHR + +this.costrateList[0].GasCostHR + +this.costrateList[0].IndManCostHR) * (productionTimeCorr / 60)).toFixed(4));
      this.formData.CostFixed = parseFloat((this.costrateList[0].CorpMiscCostHR * productionTimeCorr / 60).toFixed(4));
      if(this.formData.StyleID == 7){
        if(this.formData.InkNumber == 0){
          if (this.formData.DieType == "Plano desplegado" || this.formData.DieType == "Plano desplegado complejo"){
            costMacPartition = parseFloat(((this.costrateList[workstationPartition].DirLaborCostHR + +this.costrateList[workstationPartition].IndLaborCostHR + +this.costrateList[workstationPartition].MaintenanceCostHR + +this.costrateList[workstationPartition].EnergyCostoHR + +this.costrateList[workstationPartition].WaterCostHR + +this.costrateList[workstationPartition].GasCostHR + +this.costrateList[workstationPartition].IndManCostHR + +this.costrateList[workstationPartition].CorpMiscCostHR) * (productionTimePartition / 60)).toFixed(4));        
            costMacFolding = 0;
            productionTimeFolding = 0;
            productionTimeConv = 30;
            this.formData.CostMacConverting = costMacPartition;
          } else {
            productionTimeConv = 30;
            costMacPartition = parseFloat(((this.costrateList[workstationPartition].DirLaborCostHR + +this.costrateList[workstationPartition].IndLaborCostHR + +this.costrateList[workstationPartition].MaintenanceCostHR + +this.costrateList[workstationPartition].EnergyCostoHR + +this.costrateList[workstationPartition].WaterCostHR + +this.costrateList[workstationPartition].GasCostHR + +this.costrateList[workstationPartition].IndManCostHR + +this.costrateList[workstationPartition].CorpMiscCostHR) * (productionTimePartition / 60)).toFixed(4));        
            costMacFolding = parseFloat(((this.costrateList[workstationFolding].DirLaborCostHR + +this.costrateList[workstationFolding].IndLaborCostHR + +this.costrateList[workstationFolding].MaintenanceCostHR + +this.costrateList[workstationFolding].EnergyCostoHR + +this.costrateList[workstationFolding].WaterCostHR + +this.costrateList[workstationFolding].GasCostHR + +this.costrateList[workstationFolding].IndManCostHR + +this.costrateList[workstationFolding].CorpMiscCostHR) * (productionTimeFolding / 60)).toFixed(4));        
            this.formData.CostMacConverting = costMacPartition + costMacFolding;
          };
        } else {
            if (this.formData.DieType == "Plano desplegado" || this.formData.DieType == "Plano desplegado complejo"){
              costMacPartition = parseFloat(((this.costrateList[workstationPartition].DirLaborCostHR + +this.costrateList[workstationPartition].IndLaborCostHR + +this.costrateList[workstationPartition].MaintenanceCostHR + +this.costrateList[workstationPartition].EnergyCostoHR + +this.costrateList[workstationPartition].WaterCostHR + +this.costrateList[workstationPartition].GasCostHR + +this.costrateList[workstationPartition].IndManCostHR + +this.costrateList[workstationPartition].CorpMiscCostHR) * (productionTimePartition / 60)).toFixed(4));        
              costMacFolding = 0;
              productionTimeFolding = 0;
              this.formData.CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR + +this.costrateList[workstation].CorpMiscCostHR) * (productionTimeConv / 60)).toFixed(4)) + costMacPartition;        
            } else {
              costMacPartition = parseFloat(((this.costrateList[workstationPartition].DirLaborCostHR + +this.costrateList[workstationPartition].IndLaborCostHR + +this.costrateList[workstationPartition].MaintenanceCostHR + +this.costrateList[workstationPartition].EnergyCostoHR + +this.costrateList[workstationPartition].WaterCostHR + +this.costrateList[workstationPartition].GasCostHR + +this.costrateList[workstationPartition].IndManCostHR + +this.costrateList[workstationPartition].CorpMiscCostHR) * (productionTimePartition / 60)).toFixed(4));        
              costMacFolding = parseFloat(((this.costrateList[workstationFolding].DirLaborCostHR + +this.costrateList[workstationFolding].IndLaborCostHR + +this.costrateList[workstationFolding].MaintenanceCostHR + +this.costrateList[workstationFolding].EnergyCostoHR + +this.costrateList[workstationFolding].WaterCostHR + +this.costrateList[workstationFolding].GasCostHR + +this.costrateList[workstationFolding].IndManCostHR + +this.costrateList[workstationFolding].CorpMiscCostHR) * (productionTimeFolding / 60)).toFixed(4));        
              this.formData.CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR + +this.costrateList[workstation].CorpMiscCostHR) * (productionTimeConv / 60)).toFixed(4)) + costMacPartition + costMacFolding;        
            };
        };
      } else if (this.formData.StyleID == 5){
          if (this.formData.DieType == "Curvo pegado"){
            costMacPartition = 0;
            productionTimePartition = 0;
            costMacFolding = parseFloat(((this.costrateList[workstationFolding].DirLaborCostHR + +this.costrateList[workstationFolding].IndLaborCostHR + +this.costrateList[workstationFolding].MaintenanceCostHR + +this.costrateList[workstationFolding].EnergyCostoHR + +this.costrateList[workstationFolding].WaterCostHR + +this.costrateList[workstationFolding].GasCostHR + +this.costrateList[workstationFolding].IndManCostHR + +this.costrateList[workstationFolding].CorpMiscCostHR) * (productionTimeFolding / 60)).toFixed(4));        
            this.formData.CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR + +this.costrateList[workstation].CorpMiscCostHR) * (productionTimeConv / 60)).toFixed(4)) + costMacFolding;        
          } else {
            costMacPartition = 0;
            costMacFolding = 0;
            productionTimePartition = 0;
            productionTimeFolding = 0;
            this.formData.CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR + +this.costrateList[workstation].CorpMiscCostHR) * (productionTimeConv / 60)).toFixed(4));        
          };   
      } else if (this.formData.StyleID == 10){
          productionTimeConv = (productionTimeConv + 50);
          costMacPartition = 0;
          productionTimePartition = 0;
          costMacFolding = parseFloat(((this.costrateList[workstationFolding].DirLaborCostHR + +this.costrateList[workstationFolding].IndLaborCostHR + +this.costrateList[workstationFolding].MaintenanceCostHR + +this.costrateList[workstationFolding].EnergyCostoHR + +this.costrateList[workstationFolding].WaterCostHR + +this.costrateList[workstationFolding].GasCostHR + +this.costrateList[workstationFolding].IndManCostHR + +this.costrateList[workstationFolding].CorpMiscCostHR) * (productionTimeFolding / 60)).toFixed(4));        
          this.formData.CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR + +this.costrateList[workstation].CorpMiscCostHR) * (productionTimeConv / 60)).toFixed(4)) + costMacFolding;        
      } else {
        costMacPartition = 0;
        costMacFolding = 0;
        productionTimePartition = 0;
        productionTimeFolding = 0;
        this.formData.CostMacConverting = parseFloat(((this.costrateList[workstation].DirLaborCostHR + +this.costrateList[workstation].IndLaborCostHR + +this.costrateList[workstation].MaintenanceCostHR + +this.costrateList[workstation].EnergyCostoHR + +this.costrateList[workstation].WaterCostHR + +this.costrateList[workstation].GasCostHR + +this.costrateList[workstation].IndManCostHR + +this.costrateList[workstation].CorpMiscCostHR) * (productionTimeConv / 60)).toFixed(4));        
      };
      //productionTimeConv = productionTimeConv;
      this.formData.ProductionTimeCorr = productionTimeCorr;
      this.formData.ProductionTimeConv = productionTimeConv + productionTimePartition + productionTimeFolding;
      //in this block we are going to calculated the inks cost for each colors.
      if (this.formData.InkNumber == 1){
        //costInk01 = this.inkList.find(ink01 => ink01.InkID == this.formData.InkID01).Cost;
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.formData.InkID01).Cost;
        costInk02 == 0;
        costInk03 == 0;
        costInk04 == 0;
        costInk05 == 0;
        costInk06 == 0;
        this.formData.PerInk02 == 0;
        this.formData.PerInk03 == 0;
        this.formData.PerInk04 == 0;
        this.formData.PerInk05 == 0;
        this.formData.PerInk06 == 0;
        this.formData.PerPrintingPlate02 == 0;
        this.formData.PerPrintingPlate03 == 0;
        this.formData.PerPrintingPlate04 == 0;
        this.formData.PerPrintingPlate05 == 0;
        this.formData.PerPrintingPlate06 == 0;
      } else if(this.formData.InkNumber == 2){
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.formData.InkID01).Cost;
        costInk02 = this.inkList.find(ink02 => ink02.InkID == this.formData.InkID02).Cost;
        costInk03 == 0;
        costInk04 == 0;
        costInk05 == 0;
        costInk06 == 0;
        this.formData.PerInk03 == 0;
        this.formData.PerInk04 == 0;
        this.formData.PerInk05 == 0;
        this.formData.PerInk06 == 0;
        this.formData.PerPrintingPlate03 == 0;
        this.formData.PerPrintingPlate04 == 0;
        this.formData.PerPrintingPlate05 == 0;
        this.formData.PerPrintingPlate06 == 0;
      } else if(this.formData.InkNumber == 3){
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.formData.InkID01).Cost;
        costInk02 = this.inkList.find(ink02 => ink02.InkID == this.formData.InkID02).Cost;
        costInk03 = this.inkList.find(ink03 => ink03.InkID == this.formData.InkID03).Cost;
        costInk04 === 0;
        costInk05 === 0;
        costInk06 === 0;
        this.formData.PerInk04 == 0;
        this.formData.PerInk05 == 0;
        this.formData.PerInk06 == 0;
        this.formData.PerPrintingPlate04 == 0;
        this.formData.PerPrintingPlate05 == 0;
        this.formData.PerPrintingPlate06 == 0;
      } else if(this.formData.InkNumber == 4){
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.formData.InkID01).Cost;
        costInk02 = this.inkList.find(ink02 => ink02.InkID == this.formData.InkID02).Cost;
        costInk03 = this.inkList.find(ink03 => ink03.InkID == this.formData.InkID03).Cost;
        costInk04 = this.inkList.find(ink04 => ink04.InkID == this.formData.InkID04).Cost;
        costInk05 == 0;
        costInk06 == 0;
        this.formData.PerInk05 == 0;
        this.formData.PerInk06 == 0;
        this.formData.PerPrintingPlate05 == 0;
        this.formData.PerPrintingPlate06 == 0;
      } else if(this.formData.InkNumber == 5){
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.formData.InkID01).Cost;
        costInk02 = this.inkList.find(ink02 => ink02.InkID == this.formData.InkID02).Cost;
        costInk03 = this.inkList.find(ink03 => ink03.InkID == this.formData.InkID03).Cost;
        costInk04 = this.inkList.find(ink04 => ink04.InkID == this.formData.InkID04).Cost;
        costInk05 = this.inkList.find(ink05 => ink05.InkID == this.formData.InkID05).Cost;
        costInk06 == 0;
        this.formData.PerInk06 == 0;
        this.formData.PerPrintingPlate06 == 0;
      } else if(this.formData.InkNumber == 6){
        costInk01 = this.inkList.find(ink01 => ink01.InkID == this.formData.InkID01).Cost;
        costInk02 = this.inkList.find(ink02 => ink02.InkID == this.formData.InkID02).Cost;
        costInk03 = this.inkList.find(ink03 => ink03.InkID == this.formData.InkID03).Cost;
        costInk04 = this.inkList.find(ink04 => ink04.InkID == this.formData.InkID04).Cost;
        costInk05 = this.inkList.find(ink05 => ink05.InkID == this.formData.InkID05).Cost;
        costInk06 = this.inkList.find(ink06 => ink06.InkID == this.formData.InkID06).Cost;
      };
      //The number 2 represent the consumption per m2 (2gr/m2), and 1.1 is the waste factor, 10% over the consumption.
      inkCostTotalArea = 2 * this.formData.BoxArea * this.quoteService.formData.AverageOrder * 1.1 / 1000;//total consumption Kg / by order.
      this.formData.CostInk = parseFloat(((costInk01 * ((this.formData.PerInk01 * inkCostTotalArea / 100) + 3)) + (costInk02 * ((this.formData.PerInk02 * inkCostTotalArea / 100) + 3))  + (costInk03 * ((this.formData.PerInk03 * inkCostTotalArea / 100) + 3))  + (costInk04 * ((this.formData.PerInk04 * inkCostTotalArea / 100) + 3))  + (costInk05 * ((this.formData.PerInk05 * inkCostTotalArea / 100) + 3))  + (costInk06 * ((this.formData.PerInk06 * inkCostTotalArea / 100) + 3))).toFixed(4));
      //in this block we are going to calculated the cost plate and Die Cuter
      //Plate Cost / cm2 and Die Cost / cm
      costPlateCM2 = (this.auxiliaryList.find(costplate => costplate.AuxMatID == 10).AuxMatCost) * (1 + ((this.auxiliaryList.find(costplate => costplate.AuxMatID == 10).AuxMatWaste)/100));
      //Total Plate Cost, all color and total Die
      costPlate = parseFloat((((+this.formData.PerPrintingPlate01 + +this.formData.PerPrintingPlate02 + +this.formData.PerPrintingPlate03 + +this.formData.PerPrintingPlate04 + +this.formData.PerPrintingPlate05 + +this.formData.PerPrintingPlate06)/100) * ((this.formData.WidthBoard * this.formData.LengthBoard / 100) * costPlateCM2) / (this.auxiliaryList.find(costplate => costplate.AuxMatID == 10).AuxLifeTime)).toFixed(4))
      this.formData.CostPlateTotal = parseFloat((((+this.formData.PerPrintingPlate01 + +this.formData.PerPrintingPlate02 + +this.formData.PerPrintingPlate03 + +this.formData.PerPrintingPlate04 + +this.formData.PerPrintingPlate05 + +this.formData.PerPrintingPlate06)/100) * ((this.formData.WidthBoard * this.formData.LengthBoard / 100) * costPlateCM2)).toFixed(4));
      //section costDie
      if(this.formData.StyleID == 5){
        costDieCM = (this.auxiliaryList.find(costdie => costdie.AuxMatID == 11).AuxMatCost) * (1 + ((this.auxiliaryList.find(costdie => costdie.AuxMatID == 11).AuxMatWaste)/100));
        consDieCM = parseFloat((((2 * this.formData.Length / 10) + (2 * this.formData.Width / 10)) * (this.formData.Output)).toFixed(4));
        costDie = parseFloat(((consDieCM * this.formData.Output * costDieCM) / (this.auxiliaryList.find(costdie => costdie.AuxMatID == 11).AuxLifeTime)).toFixed(4))
        this.formData.CostDieTotal = parseFloat((consDieCM * this.formData.Output * costDieCM).toFixed(4))
        if(this.formData.DieType == "Curvo desplegado complejo"){
          costDie = costDie * 1.50;
          consDieCM = consDieCM * 1.50;
          this.formData.CostDieTotal = this.formData.CostDieTotal * 1.50;
        } else if (this.formData.DieType == "Curvo pegado"){
          costDie = costDie * 1.20;
          consDieCM = consDieCM * 1.20;
          this.formData.CostDieTotal = this.formData.CostDieTotal * 1.20;
        };
      } else if(this.formData.StyleID == 7) {
        costDieCM = (this.auxiliaryList.find(costdie => costdie.AuxMatID == 12).AuxMatCost) * (1 + ((this.auxiliaryList.find(costdie => costdie.AuxMatID == 12).AuxMatWaste)/100));
        consDieCM = parseFloat((((2 * this.formData.Length / 10) + (2 * this.formData.Width / 10)) * (this.formData.Output)).toFixed(4));
        costDie = parseFloat(((consDieCM * this.formData.Output * costDieCM) / (this.auxiliaryList.find(costdie => costdie.AuxMatID == 12).AuxLifeTime)).toFixed(4))
        this.formData.CostDieTotal = parseFloat((consDieCM * this.formData.Output * costDieCM).toFixed(4))
        if(this.formData.DieType == "Plano desplegado complejo"){
          costDie = costDie * 1.50;
          consDieCM = consDieCM * 1.50;
          this.formData.CostDieTotal = this.formData.CostDieTotal * 1.50;
        } else if (this.formData.DieType == "Plano pegado"){
          costDie = costDie * 1.20;
          consDieCM = consDieCM * 1.20;
          this.formData.CostDieTotal = this.formData.CostDieTotal * 1.20;
        };
      } else if(this.formData.StyleID == 10) {
        costDieCM = (this.auxiliaryList.find(costdie => costdie.AuxMatID == 12).AuxMatCost) * (1 + ((this.auxiliaryList.find(costdie => costdie.AuxMatID == 12).AuxMatWaste)/100));
        consDieCM = parseFloat(((2 * this.formData.LengthBoard / 10) + (2 * this.formData.WidthBoard / 10)).toFixed(4));
        costDie = parseFloat(((consDieCM * costDieCM) / (this.auxiliaryList.find(costdie => costdie.AuxMatID == 12).AuxLifeTime)).toFixed(4))
        this.formData.CostDieTotal = parseFloat((consDieCM * costDieCM).toFixed(4))
      } else {
        costDieCM = 0;
        costDie = 0;
        consDieCM = 0;
      };
      //Total Plate Cost per order
      plateRatCon = this.auxiliaryList.find(costplate => costplate.AuxMatID == 10).AuxMatRatCon;
      if (plateRatCon <= this.quoteService.formData.MonthlyOrder){
        this.formData.CostPlate = (costPlate / plateRatCon) * this.quoteService.formData.AverageOrder;
        this.formData.CostDie = (costDie / plateRatCon) * this.quoteService.formData.AverageOrder;
      } else {
        this.formData.CostPlate = (costPlate / this.quoteService.formData.MonthlyOrder) * this.quoteService.formData.AverageOrder;
        this.formData.CostDie = (costDie / this.quoteService.formData.MonthlyOrder) * this.quoteService.formData.AverageOrder;
      };
      //in this block we are going to calculated the PVA cost and rate consuptiom.
      if (this.formData.ClosureID > 1){
        costPVA01 = this.auxiliaryList.find(PVA01 => PVA01.AuxMatID == 8).AuxMatCost * this.auxiliaryList.find(PVA01 => PVA01.AuxMatID == 8).AuxMatRatCon * (1 + (this.auxiliaryList.find(PVA01 => PVA01.AuxMatID == 8).AuxMatWaste / 100));
        this.formData.CostPVA = parseFloat((costPVA01 * this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder / 1000).toFixed(4));
      }else {
        this.formData.CostPVA = 0;
      };
      //in this block, we are going to calculated the Strech Film cost.
      if (this.formData.StrechFilm == true){
        consStrechFilm =  (this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatRatCon) * (1 + (this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatWaste / 100)) * (this.quoteService.formData.PalletProduced);
        costFilm = this.auxiliaryList.find(Film => Film.AuxMatID == 3).AuxMatCost;
        this.formData.CostFilm = parseFloat((costFilm * consStrechFilm).toFixed(4));
      }else {
        this.formData.CostFilm = 0;
        consStrechFilm = 0;
      };

      //in this block, we are gonna calculated the Board for Packaging cost
      consBoardPackaging = this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatRatCon * (1 + (this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatWaste / 100)) * (this.quoteService.formData.PalletProduced);
      costBoardPackaging = this.auxiliaryList.find(boarPackaging => boarPackaging.AuxMatID == 7).AuxMatCost;
      this.formData.CostBoardPackaging = parseFloat((costBoardPackaging * consBoardPackaging).toFixed(4));
      //in this block, we are gonna calculated the Pallet cost
      if (this.formData.Pallet == true){
        consPallet = Math.ceil(this.quoteService.formData.PalletProduced);
        costPallet = this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatCost * this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatRatCon * (1 + (this.auxiliaryList.find(Pallet => Pallet.AuxMatID == 6).AuxMatWaste / 100));
        this.formData.CostPallet = parseFloat((costPallet * consPallet).toFixed(4));
      }else {
        this.formData.CostPallet = 0;
        consPallet = 0;
      };
      
      //in this block we are calculated the Tape
      if (this.formData.TapeID != 1){
        consTape = this.auxiliaryList.find(constape => constape.AuxMatID == this.formData.TapeID).AuxMatRatCon * (1 + (this.auxiliaryList.find(constape => constape.AuxMatID == this.formData.TapeID).AuxMatWaste / 100));
        costTape = this.auxiliaryList.find(tape => tape.AuxMatID == this.formData.TapeID).AuxMatCost;
        this.formData.CostTape = parseFloat((costTape * this.formData.LengthBoard * this.quoteService.formData.AverageOrder * this.formData.TapeQuantity / 1000).toFixed(4));
        if ((this.formData.StyleID <= 4) || (this.formData.StyleID >= 8)){
          this.formData.CostTape = parseFloat((costTape * this.formData.LengthBoard * this.quoteService.formData.AverageOrder * this.formData.TapeQuantity / 1000).toFixed(4));
          consTape = parseFloat((consTape * this.formData.LengthBoard * this.quoteService.formData.AverageOrder * this.formData.TapeQuantity / 1000).toFixed(4));
        } else if((this.formData.StyleID == 5) || (this.formData.StyleID == 7)){
          this.formData.CostTape = parseFloat((costTape * this.formData.LengthBoard * (this.quoteService.formData.AverageOrder / this.formData.Output) * this.formData.TapeQuantity / 1000).toFixed(4));
          consTape = parseFloat((consTape * this.formData.LengthBoard * (this.quoteService.formData.AverageOrder / this.formData.Output) * this.formData.TapeQuantity / 1000).toFixed(4));
        }
      }else{
        consTape = 0;
        this.formData.CostTape = 0;
        this.formData.TapeQuantity = 0;
      };

      //in this block we are going to calculated the Cover.
      costCover01 = this.auxiliaryList.find(Cover01 => Cover01.AuxMatID == 9).AuxMatCost
      if ((this.formData.StyleID <= 4) || (this.formData.StyleID == 6) || (this.formData.StyleID >= 8)){
        this.formData.CostCover = 0;
        consCover = 0;
      } else {
        this.formData.CostCover = parseFloat((costCover01 * this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder / 1000).toFixed(4)); 
        consCover = parseFloat((this.formData.TotalAreaRequired * this.quoteService.formData.AverageOrder / 1000).toFixed(4)); 
      };
           
      //in this block we are going to calculated the Strapping of 5 and 12 mm.
      costStrapping01 = this.auxiliaryList.find(Strapping01 => Strapping01.AuxMatID == 1).AuxMatCost * (1 + (this.auxiliaryList.find(Strapping01 => Strapping01.AuxMatID == 1).AuxMatWaste / 100))
      thickness = this.boardList.find(thicknes => thicknes.BoardID == this.formData.BoardID).Thickness;
      
      if(this.formData.StrapDirection == "al ancho del atado"){
        consStrapping5mm = parseFloat((((2 * this.quoteService.widthBundle * this.formData.StrapNumber / 1000) + (4 * thickness * this.formData.BoxBundle * this.formData.StrapNumber / 1000)) * (this.quoteService.formData.AverageOrder/this.formData.BoxBundle)).toFixed(2)); 
      }else if(this.formData.StrapDirection == "al largo del atado"){
        consStrapping5mm = parseFloat((((2 * this.quoteService.lengthBundle * this.formData.StrapNumber / 1000) + (4 * thickness * this.formData.BoxBundle * this.formData.StrapNumber / 1000)) * (this.quoteService.formData.AverageOrder/this.formData.BoxBundle)).toFixed(2));
      }else{
        consStrapping5mm = 0;
      };
      this.formData.CostStrapping5mm = parseFloat((costStrapping01 * consStrapping5mm / 1000).toFixed(4));        
      costStrapping02 = this.auxiliaryList.find(Strapping02 => Strapping02.AuxMatID == 2).AuxMatCost * (1 + (this.auxiliaryList.find(Strapping02 => Strapping02.AuxMatID == 2).AuxMatWaste / 100))
      consStrapping12mm = parseFloat((20 * this.quoteService.formData.PalletProduced).toFixed(2));        
      this.formData.CostStrapping12mm = parseFloat((costStrapping02 * consStrapping12mm / 1000).toFixed(4));        
      this.formData.SubTotCorrugator = parseFloat((this.formData.CostMP + +this.formData.CostAuxCorr + +this.formData.CostCorrToConv + +this.formData.CostFixed + +this.formData.CostTape).toFixed(4));        
      this.formData.CostWasteConv = parseFloat((this.formData.SubTotCorrugator * 1.5 / 100).toFixed(4));
      if (this.formData.PlateDie == true){
        this.formData.SubTotConverting = parseFloat((this.formData.CostMacConverting + this.formData.CostInk + this.formData.CostPVA + this.formData.CostPlate + this.formData.CostDie + this.formData.CostCover + this.formData.CostFilm + this.formData.CostBoardPackaging + this.formData.CostPallet +this.formData.CostStrapping5mm + this.formData.CostStrapping12mm + this.formData.CostWasteConv).toFixed(4)); 
      }else {
        this.formData.SubTotConverting = parseFloat((this.formData.CostMacConverting + this.formData.CostInk + this.formData.CostPVA + this.formData.CostCover + this.formData.CostFilm + this.formData.CostBoardPackaging + this.formData.CostPallet +this.formData.CostStrapping5mm + this.formData.CostStrapping12mm + this.formData.CostWasteConv).toFixed(4)); 
      };     
      //this.formData.SubTotConverting = parseFloat((this.formData.CostMacConverting + this.formData.CostInk + this.formData.CostPVA + this.formData.CostPlate + this.formData.CostDie + this.formData.CostCover + this.formData.CostFilm + this.formData.CostBoardPackaging + this.formData.CostPallet +this.formData.CostStrapping5mm + this.formData.CostStrapping12mm + this.formData.CostWasteConv).toFixed(4)); 
      this.formData.Total = parseFloat((this.formData.SubTotConverting + this.formData.SubTotCorrugator).toFixed(2));
      
      //consumptions materials
      if (this.boardList[indexSelected].TypeStarch == "AI"){
        this.formData.ConsStarch = parseFloat(((this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired) * ((this.auxiliaryList.find(consstarch => consstarch.AuxMatID == 13).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(consstarch => consstarch.AuxMatID == 13).AuxMatWaste)/100))).toFixed(2));
      } else {
        this.formData.ConsStarch = parseFloat(((this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired) * ((this.auxiliaryList.find(consStarch => consStarch.AuxMatID == 14).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(consStarch => consStarch.AuxMatID == 14).AuxMatWaste)/100))).toFixed(2));
      };
      if (this.boardList[indexSelected].CoatingTo == "XX"){
          this.formData.ConsCoating = 0;
      } else if(this.boardList[indexSelected].CoatingTo == "RL"){
          this.formData.ConsCoating = parseFloat(((0.3334 * this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired) * ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatWaste)/100))).toFixed(2));
      } else if (this.boardList[indexSelected].CoatingTo == "RLM") {
          this.formData.ConsCoating = parseFloat(((0.6664 * this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired) * ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatWaste)/100))).toFixed(2));
      } else if(this.boardList[indexSelected].CoatingTo == "RLMM"){
        this.formData.ConsCoating = parseFloat(((this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired) * ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(conscoating => conscoating.AuxMatID == 15).AuxMatWaste)/100))).toFixed(2));
      };
      if (this.formData.ClosureID > 1){
        this.formData.ConsPVA = parseFloat(((this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired / 1000) * (this.auxiliaryList.find(conspva => conspva.AuxMatID == 8).AuxMatRatCon) * (1 + ((this.auxiliaryList.find(conspva => conspva.AuxMatID == 8).AuxMatWaste)/100))).toFixed(2));
      } else {
        this.formData.ConsPVA = 0;
      };
      if(this.formData.StyleID == 5){
        this.formData.ConsDieRDC = parseFloat((consDieCM * (1 + ((this.auxiliaryList.find(consdie => consdie.AuxMatID == 11).AuxMatWaste)/100))).toFixed(2));
        this.formData.ConsDieFDC = 0;
      }else if(this.formData.StyleID == 7){
        this.formData.ConsDieRDC = 0;
        this.formData.ConsDieFDC = parseFloat((consDieCM * (1 + ((this.auxiliaryList.find(consdie => consdie.AuxMatID == 12).AuxMatWaste)/100))).toFixed(2));
      }else if(this.formData.StyleID == 10){
        this.formData.ConsDieRDC = parseFloat((consDieCM * (1 + ((this.auxiliaryList.find(consdie => consdie.AuxMatID == 11).AuxMatWaste)/100))).toFixed(2));
        this.formData.ConsDieFDC = 0;
      };
      if(this.formData.TapeID == 4){
        this.formData.ConsStringKing = 0;
        this.formData.ConsOpenSesame = parseFloat(consTape.toFixed(2));
      }else if (this.formData.TapeID == 5){
        this.formData.ConsOpenSesame = 0;
        this.formData.ConsStringKing = parseFloat(consTape.toFixed(2));
      } else {
        this.formData.ConsStringKing = 0;
        this.formData.ConsOpenSesame = 0;
      };
      this.formData.ConsCover = parseFloat(consCover.toFixed(2));
      if(this.formData.PerInk01 > 0){
        this.formData.ConsInk01 = parseFloat(((this.formData.PerInk01 * inkCostTotalArea / 100) + 3).toFixed(2));
      } else {
        this.formData.ConsInk01 = parseFloat(((this.formData.PerInk01 * inkCostTotalArea / 100) + 0).toFixed(2));
      };
      if(this.formData.PerInk02 > 0){
        this.formData.ConsInk02 = parseFloat(((this.formData.PerInk02 * inkCostTotalArea / 100) + 3).toFixed(2));
      } else {
        this.formData.ConsInk02 = parseFloat(((this.formData.PerInk02 * inkCostTotalArea / 100) + 0).toFixed(2));
      };
      if(this.formData.PerInk03 > 0){
        this.formData.ConsInk03 = parseFloat(((this.formData.PerInk03 * inkCostTotalArea / 100) + 3).toFixed(2));
      } else {
        this.formData.ConsInk03 = parseFloat(((this.formData.PerInk03 * inkCostTotalArea / 100) + 0).toFixed(2));
      };
      if(this.formData.PerInk04 > 0){
        this.formData.ConsInk04 = parseFloat(((this.formData.PerInk04 * inkCostTotalArea / 100) + 3).toFixed(2));
      } else {
        this.formData.ConsInk04 = parseFloat(((this.formData.PerInk04 * inkCostTotalArea / 100) + 0).toFixed(2));
      };
      if(this.formData.PerInk05 > 0){
        this.formData.ConsInk05 = parseFloat(((this.formData.PerInk05 * inkCostTotalArea / 100) + 3).toFixed(2));
      } else {
        this.formData.ConsInk05 = parseFloat(((this.formData.PerInk05 * inkCostTotalArea / 100) + 0).toFixed(2));
      };
      if(this.formData.PerInk06 > 0){
        this.formData.ConsInk06 = parseFloat(((this.formData.PerInk06 * inkCostTotalArea / 100) + 3).toFixed(2));
      } else {
        this.formData.ConsInk06 = parseFloat(((this.formData.PerInk06 * inkCostTotalArea / 100) + 0).toFixed(2));
      };
      this.formData.ConsPlate01 = parseFloat(((+this.formData.PerPrintingPlate01 / 100) * (this.formData.WidthBoard * this.formData.LengthBoard / 100)).toFixed(2));
      this.formData.ConsPlate02 = parseFloat(((+this.formData.PerPrintingPlate02 / 100) * (this.formData.WidthBoard * this.formData.LengthBoard / 100)).toFixed(2));
      this.formData.ConsPlate03 = parseFloat(((+this.formData.PerPrintingPlate03 / 100) * (this.formData.WidthBoard * this.formData.LengthBoard / 100)).toFixed(2));
      this.formData.ConsPlate04 = parseFloat(((+this.formData.PerPrintingPlate04 / 100) * (this.formData.WidthBoard * this.formData.LengthBoard / 100)).toFixed(2));
      this.formData.ConsPlate05 = parseFloat(((+this.formData.PerPrintingPlate05 / 100) * (this.formData.WidthBoard * this.formData.LengthBoard / 100)).toFixed(2));
      this.formData.ConsPlate06 = parseFloat(((+this.formData.PerPrintingPlate06 / 100) * (this.formData.WidthBoard * this.formData.LengthBoard / 100)).toFixed(2));
      this.formData.ConsStrapping5mm = consStrapping5mm;
      this.formData.ConsStrapping12mm = consStrapping12mm;
      this.formData.ConsStrechFilm = consStrechFilm;
      this.formData.ConsPallet = consPallet;
      this.formData.ConsBoardPackaging = consBoardPackaging;
      this.formData.ConsWater = parseFloat(((this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired) * ((this.auxiliaryList.find(conswater => conswater.AuxMatID == 16).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(conswater => conswater.AuxMatID == 16).AuxMatWaste)/100)) / 1000).toFixed(2));
      this.formData.ConsGasForklift = parseFloat(((this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired) * ((this.auxiliaryList.find(consgasforklift => consgasforklift.AuxMatID == 17).AuxMatRatCon) / 1000) * (1 + ((this.auxiliaryList.find(consgasforklift => consgasforklift.AuxMatID == 17).AuxMatWaste)/100))).toFixed(2));
      this.formData.ConsGasBoiler = parseFloat(((this.quoteService.formData.AverageOrder * this.formData.TotalAreaRequired / 1000) * ((this.auxiliaryList.find(consgasboiler => consgasboiler.AuxMatID == 18).AuxMatRatCon)) * (1 + ((this.auxiliaryList.find(consgasboiler => consgasboiler.AuxMatID == 18).AuxMatWaste)/100))).toFixed(2));
    };
  };

  ongetBoardCost(){
    if(this.formData.BoardID != '')
    this.boardcostService.getBoardCostListId(this.formData.BoardID).subscribe((boardCosts: Boardcost) => {
      this.boardCost = boardCosts; this.onBoardCost()}, error => console.error(error));
      ;
  };

  //call list of development
  ongetDevelopmentList(){
    let closure: number;
    if (this.formData.StyleID == 5 || this.formData.StyleID == 7){
      closure = 1;
      //this.formData.ClosureID =1;
    }else {
      closure = this.formData.ClosureID;
    };

    if (this.formData.StyleID !=0 && closure !=0 && this.formData.BoardID !='')
      this.developmentService.getDevelopmentList(+this.formData.StyleID, this.formData.FluteID, closure).subscribe(developments => {
        this.developmentList = developments; console.log(developments);
        this.onDevelopmentBox ();        
      });
  };

//call cost when the value change en checkbox
  ongetCheckChange() {
     this.ongetBoardCost();
  };

//call onBoardCost
  onChangeTape(){
    this.ongetBoardCost();
    if(this.formData.TapeID ==1){
      this.formData.TapeQuantity = 0
    };
  };

  //call list of speed and Operating Efficiency

  /* activarInkNumber(){
    if (this.formData.InkNumber == 0){
      this.mostrarInkNumber01 = true;
      this.mostrarInkNumber02 = true;
      this.mostrarInkNumber03 = true;
      this.mostrarInkNumber04 = true;
      this.mostrarInkNumber05 = true;
      this.mostrarInkNumber06 = true;
    } else if (this.formData.InkNumber == 1) {
      this.mostrarInkNumber01 = false;
      this.mostrarInkNumber02 = true;
      this.mostrarInkNumber03 = true;
      this.mostrarInkNumber04 = true;
      this.mostrarInkNumber05 = true;
      this.mostrarInkNumber06 = true;
    } else if (this.formData.InkNumber == 2) {
      this.mostrarInkNumber01 = false;
      this.mostrarInkNumber02 = false;
      this.mostrarInkNumber03 = true;
      this.mostrarInkNumber04 = true;
      this.mostrarInkNumber05 = true;
      this.mostrarInkNumber06 = true;
    } else if (this.formData.InkNumber == 3) {
      this.mostrarInkNumber01 = false;
      this.mostrarInkNumber02 = false;
      this.mostrarInkNumber03 = false;
      this.mostrarInkNumber04 = true;
      this.mostrarInkNumber05 = true;
      this.mostrarInkNumber06 = true;
    } else if (this.formData.InkNumber == 4) {
      this.mostrarInkNumber01 = false;
      this.mostrarInkNumber02 = false;
      this.mostrarInkNumber03 = false;
      this.mostrarInkNumber04 = false;
      this.mostrarInkNumber05 = true;
      this.mostrarInkNumber06 = true;
    } else if (this.formData.InkNumber == 5) {
      this.mostrarInkNumber01 = false;
      this.mostrarInkNumber02 = false;
      this.mostrarInkNumber03 = false;
      this.mostrarInkNumber04 = false;
      this.mostrarInkNumber05 = false;
      this.mostrarInkNumber06 = true;
    } else if (this.formData.InkNumber == 6) {
      this.mostrarInkNumber01 = false;
      this.mostrarInkNumber02 = false;
      this.mostrarInkNumber03 = false;
      this.mostrarInkNumber04 = false;
      this.mostrarInkNumber05 = false;
      this.mostrarInkNumber06 = false;
    }
  }; */

  mostrarClosure(){
    if(this.formData.StyleID == 5 || this.formData.StyleID == 7){
      this.mostrarClosureID = true;
      //this.formData.ClosureID = 1;
      this.viewOutput = true;
      this.viewDepth = false;
      this.formData.DieType = "";
      
    } else if(this.formData.StyleID <= 4 || this.formData.StyleID == 10){
      this.mostrarClosureID = false;
      this.viewOutput = false;
      this.viewDepth = true;
      this.formData.DieType = "NA";
    };
    this.formData.BoardID = null;
    this.formData.StyleName = this.styleList.find(styleName=> styleName.StyleID == this.formData.StyleID).StyleName;
  };

  onmostrarOverlap(){
    if(this.formData.StyleID >= 8 && this.formData.StyleID < 10){
      this.mostrarOverlap = true;
      this.mostrarClosureID = false;  
    } else {
      this.mostrarOverlap = false;
    };
  };

  mostrarClosureEdit(){
    if(this.formData.StyleID == 5 || this.formData.StyleID ==7){
      this.mostrarClosureID = true;
      this.mostrarOverlap = false;
      //this.formData.ClosureID = 1
      this.viewOutput = true
      this.viewDepth = false;
    } else if(this.formData.StyleID <= 4){
      this.mostrarClosureID = false;
      this.mostrarOverlap = false;
      this.viewOutput = false;
      this.viewDepth = true;
    } else if(this.formData.StyleID >= 8 && this.formData.StyleID < 10){
      this.mostrarOverlap = true;
      this.viewOutput = false;
      this.viewDepth = true;
    };
  };

  onvalidateForm(){
    if (this.formData.StyleID == 0){
      this.getformFocus("StyleID", "Estilo")
    };
    if (this.formData.BoardID == null){
      this.getformFocus("BoardID", "Resistencia")
    };
    if (this.formData.ClosureID == 0){
      this.getformFocus("ClosureID", "Tipo de cierre")
    };
    if (this.formData.Register == ""){
      this.getformFocus("Register", "Tipo de registro")
    };
    if (this.formData.Width <= 0){
      this.getformFocus("Width", "Ancho de la caja")
    };
    if (this.formData.Length <= 0){
      this.getformFocus("Length", "Largo de la caja")
    };
    if (this.viewDepth == true){
      if (this.formData.Depth <= 0){
        this.getformFocus("Depth", "Altura de la caja")
      };
    }
    if (this.viewOutput == true){
      if (this.formData.Output <= 0){
        this.getformFocus("Output", "Cajas por golpe")
      };
    }
    this.onvalidateInk();
  };

  onvalidateInk(){
    if (this.formData.InkNumber == 1){
      if(this.formData.InkID01 == 0){
        this.getformFocus("InkID01", "Tinta")
      };
      if(this.formData.PerInk01 == 0 || this.formData.PerInk01 > 100){
        this.getformFocus("PerInk01", "Tinta")
      };
    } else if(this.formData.InkNumber == 2){
        if(this.formData.InkID01 ==0  ){
          this.getformFocus("InkID01", "Tinta")
        };
        if(this.formData.InkID02 ==0  ){
          this.getformFocus("InkID02", "Tinta")
        };
        if(this.formData.PerInk01 == 0 || this.formData.PerInk01 > 100){
          this.getformFocus("PerInk01", "Tinta")
        };
        if(this.formData.PerInk02 == 0 || this.formData.PerInk02 > 100){
          this.getformFocus("PerInk02", "Tinta")
        };
    } else if(this.formData.InkNumber == 3){
        if(this.formData.InkID01 ==0  ){
          this.getformFocus("InkID01", "Tinta")
        };
        if(this.formData.InkID02 ==0  ){
          this.getformFocus("InkID02", "Tinta")
        };
        if(this.formData.InkID03 ==0  ){
          this.getformFocus("InkID03", "Tinta")
        };
        if(this.formData.PerInk01 == 0 || this.formData.PerInk01 > 100){
          this.getformFocus("PerInk01", "Tinta")
        };
        if(this.formData.PerInk02 == 0 || this.formData.PerInk02 > 100){
          this.getformFocus("PerInk02", "Tinta")
        };
        if(this.formData.PerInk03 == 0 || this.formData.PerInk03 > 100){
          this.getformFocus("PerInk03", "Tinta")
        };
    } else if(this.formData.InkNumber == 4){
        if(this.formData.InkID01 ==0  ){
          this.getformFocus("InkID01", "Tinta")
        };
        if(this.formData.InkID02 ==0  ){
          this.getformFocus("InkID02", "Tinta")
        };
        if(this.formData.InkID03 ==0  ){
          this.getformFocus("InkID03", "Tinta")
        };
        if(this.formData.InkID04 ==0  ){
          this.getformFocus("InkID04", "Tinta")
        };
        if(this.formData.PerInk01 == 0 || this.formData.PerInk01 > 100){
          this.getformFocus("PerInk01", "Tinta")
        };
        if(this.formData.PerInk02 == 0 || this.formData.PerInk02 > 100){
          this.getformFocus("PerInk02", "Tinta")
        };
        if(this.formData.PerInk03 == 0 || this.formData.PerInk03 > 100){
          this.getformFocus("PerInk03", "Tinta")
        };
        if(this.formData.PerInk04 == 0 || this.formData.PerInk04 > 100){
          this.getformFocus("PerInk04", "Tinta")
        };
    } else if(this.formData.InkNumber == 5){
        if(this.formData.InkID01 ==0  ){
          this.getformFocus("InkID01", "Tinta")
        };
        if(this.formData.InkID02 ==0  ){
          this.getformFocus("InkID02", "Tinta")
        };
        if(this.formData.InkID03 ==0  ){
          this.getformFocus("InkID03", "Tinta")
        };
        if(this.formData.InkID04 ==0  ){
          this.getformFocus("InkID04", "Tinta")
        };
        if(this.formData.InkID05 ==0  ){
          this.getformFocus("InkID05", "Tinta")
        };
        if(this.formData.PerInk01 == 0 || this.formData.PerInk01 > 100){
          this.getformFocus("PerInk01", "Tinta")
        };
        if(this.formData.PerInk02 == 0 || this.formData.PerInk02 > 100){
          this.getformFocus("PerInk02", "Tinta")
        };
        if(this.formData.PerInk03 == 0 || this.formData.PerInk03 > 100){
          this.getformFocus("PerInk03", "Tinta")
        };
        if(this.formData.PerInk04 == 0 || this.formData.PerInk04 > 100){
          this.getformFocus("PerInk04", "Tinta")
        };
        if(this.formData.PerInk05 == 0 || this.formData.PerInk05 > 100){
          this.getformFocus("PerInk05", "Tinta")
        };
    } else if(this.formData.InkNumber == 6){
        if(this.formData.InkID01 ==0  ){
          this.getformFocus("InkID01", "Tinta")
        };
        if(this.formData.InkID02 ==0  ){
          this.getformFocus("InkID02", "Tinta")
        };
        if(this.formData.InkID03 ==0  ){
          this.getformFocus("InkID03", "Tinta")
        };
        if(this.formData.InkID04 ==0  ){
          this.getformFocus("InkID04", "Tinta")
        };
        if(this.formData.InkID05 ==0  ){
          this.getformFocus("InkID05", "Tinta")
        };
        if(this.formData.InkID06 ==0  ){
          this.getformFocus("InkID06", "Tinta")
        };
        if(this.formData.PerInk01 == 0 || this.formData.PerInk01 > 100){
          this.getformFocus("PerInk01", "Tinta")
        };
        if(this.formData.PerInk02 == 0 || this.formData.PerInk02 > 100){
          this.getformFocus("PerInk02", "Tinta")
        };
        if(this.formData.PerInk03 == 0 || this.formData.PerInk03 > 100){
          this.getformFocus("PerInk03", "Tinta")
        };
        if(this.formData.PerInk04 == 0 || this.formData.PerInk04 > 100){
          this.getformFocus("PerInk04", "Tinta")
        };
        if(this.formData.PerInk05 == 0 || this.formData.PerInk05 > 100){
          this.getformFocus("PerInk05", "Tinta")
        };
        if(this.formData.PerInk06 == 0 || this.formData.PerInk06 > 100){
          this.getformFocus("PerInk06", "Tinta")
        };
    };
  };

  onvalidatePrintPlate(){
    if (this.formData.InkNumber == 1){
      if(this.formData.PerPrintingPlate01 == 0 || this.formData.PerPrintingPlate01 > 110){
        this.formData.PerPrintingPlate01 = +this.formData.PerInk01 + 10;
      };
    } else if(this.formData.InkNumber == 2){
        if(this.formData.PerPrintingPlate01 == 0 || this.formData.PerPrintingPlate01 > 110){
          this.formData.PerPrintingPlate01 = +this.formData.PerInk01 + 10;
        };
        if(this.formData.PerPrintingPlate02 == 0 || this.formData.PerPrintingPlate02 > 110){
          this.formData.PerPrintingPlate02 = +this.formData.PerInk02 + 10;
        };
    } else if(this.formData.InkNumber == 3){
        if(this.formData.PerPrintingPlate01 == 0 || this.formData.PerPrintingPlate01 > 110){
          this.formData.PerPrintingPlate01 = +this.formData.PerInk01 + 10;
        };
        if(this.formData.PerPrintingPlate02 == 0 || this.formData.PerPrintingPlate02 > 110){
          this.formData.PerPrintingPlate02 = +this.formData.PerInk02 + 10;
        };
        if(this.formData.PerPrintingPlate03 == 0 || this.formData.PerPrintingPlate03 > 110){
          this.formData.PerPrintingPlate03 = +this.formData.PerInk03 + 10;
        };
    } else if(this.formData.InkNumber == 4){
        if(this.formData.PerPrintingPlate01 == 0 || this.formData.PerPrintingPlate01 > 110){
          this.formData.PerPrintingPlate01 = +this.formData.PerInk01 + 10;
        };
        if(this.formData.PerPrintingPlate02 == 0 || this.formData.PerPrintingPlate02 > 110){
          this.formData.PerPrintingPlate02 = +this.formData.PerInk02 + 10;
        };
        if(this.formData.PerPrintingPlate03 == 0 || this.formData.PerPrintingPlate03 > 110){
          this.formData.PerPrintingPlate03 = +this.formData.PerInk03 + 10;
        };
        if(this.formData.PerPrintingPlate04 == 0 || this.formData.PerPrintingPlate04 > 110){
          this.formData.PerPrintingPlate04 = +this.formData.PerInk04 + 10;
        };
    } else if(this.formData.InkNumber == 5){
        if(this.formData.PerPrintingPlate01 == 0 || this.formData.PerPrintingPlate01 > 110){
          this.formData.PerPrintingPlate01 = +this.formData.PerInk01 + 10;
        };
        if(this.formData.PerPrintingPlate02 == 0 || this.formData.PerPrintingPlate02 > 110){
          this.formData.PerPrintingPlate02 = +this.formData.PerInk02 + 10;
        };
        if(this.formData.PerPrintingPlate03 == 0 || this.formData.PerPrintingPlate03 > 110){
          this.formData.PerPrintingPlate03 = +this.formData.PerInk03 + 10;
        };
        if(this.formData.PerPrintingPlate04 == 0 || this.formData.PerPrintingPlate04 > 110){
          this.formData.PerPrintingPlate04 = +this.formData.PerInk04 + 10;
        };
        if(this.formData.PerPrintingPlate05 == 0 || this.formData.PerPrintingPlate05 > 110){
          this.formData.PerPrintingPlate05 = +this.formData.PerInk05 + 10;
        };
    } else if(this.formData.InkNumber == 6){
        if(this.formData.PerPrintingPlate01 == 0 || this.formData.PerPrintingPlate01 > 110){
          this.formData.PerPrintingPlate01 = +this.formData.PerInk01 + 10;
        };
        if(this.formData.PerPrintingPlate02 == 0 || this.formData.PerPrintingPlate02 > 110){
          this.formData.PerPrintingPlate02 = +this.formData.PerInk02 + 10;
        };
        if(this.formData.PerPrintingPlate03 == 0 || this.formData.PerPrintingPlate03 > 110){
          this.formData.PerPrintingPlate03 = +this.formData.PerInk03 + 10;
        };
        if(this.formData.PerPrintingPlate04 == 0 || this.formData.PerPrintingPlate04 > 110){
          this.formData.PerPrintingPlate04 = +this.formData.PerInk04 + 10;
        };
        if(this.formData.PerPrintingPlate05 == 0 || this.formData.PerPrintingPlate05 > 110){
          this.formData.PerPrintingPlate05 = +this.formData.PerInk05 + 10;
        };
        if(this.formData.PerPrintingPlate06 == 0 || this.formData.PerPrintingPlate06 > 110){
          this.formData.PerPrintingPlate06 = +this.formData.PerInk06 + 10;
        };
    };
  };

  getformFocus(formID: string, nameForm: string) {
    this.toastr.error('Por favor, seleccione y/o ingrese el porcentaje correcto de tinta ' + nameForm, 'Corrugated Quote.');
    document.getElementById(formID).focus();
    this.isValid = false;
    return this.isValid;
  };

  getformFocusOutPut(formID: string, nameForm: string) {
    this.toastr.error('Por favor, ingrese ' + nameForm, 'Corrugated Quote.');
    document.getElementById(formID).focus();
    this.formData.Output = 0;
    this.formData.LengthBoard = 0;
    this.formData.WidthBoard = 0;
    this.isValid = false;
    return this.isValid;
  };

  onStrapping(){
    if(this.formData.StrapDirection == "Sin Fleje")
    this.formData.StrapNumber = 0;
  };

  onClosureBox(){
    if((this.formData.DieType == "Curvo pegado" || this.formData.DieType == "Plano pegado") && this.formData.ClosureID == 1){
      this.formData.ClosureID = 2
    }
  }

  onCargoType(){
    let areaBundle: number; let numberBundle: number; let volBundle: number = 0;
    let heightBundle: number; let thickness: number; let volCargo: number;
    //Variables paletizations
    let xlengthPlengthB: number;  let ywidthPlengthB: number; 
    let wlengthPwidthB: number;  let zwidthPwidthB: number;
    let lengthB: number; let widthB: number;
    let lengthP: number = 1.2; let widthP: number = 1.05; let heightP: number = 1.2;
    let layerP: number; 
    let maxBundle01: number; let maxBundle02: number; let maxBundleP: number;
    let numBundleWidth: number; let numBundleLength: number;
    let lengthPalletF: number; let widthPalletF: number; let heightPalletF: number;
    let blocksB: number; let blocks02B: number;
    let numBunBlock: number; let numBunBlocks02: number;
    let lengthCargo: number; let boxPallet: number; 
    let indexTruck: number = 0; let layerPallet: number = 4;
    let palletArrangement: number = 0;
    //end
    //lineal meters, caliper.
    thickness = this.boardList.find(thicknes => thicknes.BoardID == this.formData.BoardID).Thickness / 1000;
    if((this.formData.StyleID <= 4) || (this.formData.StyleID >= 8 && this.formData.StyleID <= 10)){
      this.quoteService.lengthBundle = parseFloat((((this.formData.LengthBoard - 40) / 2) / 1000).toFixed(3));
      this.quoteService.widthBundle = parseFloat((this.formData.WidthBoard / 1000).toFixed(3));
      areaBundle = parseFloat((this.quoteService.lengthBundle * this.quoteService.widthBundle).toFixed(3));
      heightBundle = parseFloat((2 * thickness * this.formData.BoxBundle).toFixed(3));
    }else if(this.formData.StyleID == 5 || this.formData.StyleID == 7){
      if(this.formData.DieType == "Curvo pegado" || this.formData.DieType == "Plano pegado"){
        this.quoteService.lengthBundle = parseFloat((((this.formData.Length - 35) / 2) / 1000).toFixed(3));
        this.quoteService.widthBundle = parseFloat((this.formData.Width / 1000).toFixed(3));
        areaBundle = parseFloat((this.quoteService.lengthBundle * this.quoteService.widthBundle).toFixed(3));
        heightBundle = parseFloat((2 * thickness * this.formData.BoxBundle).toFixed(3));
      }else {
        this.quoteService.lengthBundle = parseFloat((this.formData.Length / 1000).toFixed(3));
        this.quoteService.widthBundle = parseFloat((this.formData.Width / 1000).toFixed(3));
        areaBundle = parseFloat((this.quoteService.lengthBundle * this.quoteService.widthBundle).toFixed(3));
        heightBundle = parseFloat((thickness * this.formData.BoxBundle).toFixed(3));
      };
    };
      
    //Volum one bundle
    volBundle = parseFloat((areaBundle * heightBundle).toFixed(3));
    //number bundle for shipment/freigh
    numberBundle = Math.floor(this.quoteService.formData.BoxShipment / this.formData.BoxBundle)
    //unitization
    if (this.quoteService.formData.CargoType == "Granel"){
      volCargo = volBundle * numberBundle
      //this part is for determineted the Ideal Track
      let index: number = 0;
      for(let index = 0 ; index < this.truckTypeList.length && volCargo <= this.truckTypeList[index].CapacityM3; index++){
        this.quoteService.formData.IdealTruck = this.truckTypeList[index].TruckName;
        this.quoteService.formData.UsedCapacityTruck = Math.floor(volCargo * 100 / this.truckTypeList[index].CapacityM3);
        this.quoteService.formData.TruckID = this.truckTypeList[index].TruckID;
      };
      this.quoteService.formData.PalletID = 0
      //test
      this.quoteService.fullCapacity = Math.floor(this.truckTypeList[0].CapacityM3 / volBundle) * this.formData.BoxBundle;
      this.quoteService.trailerCapacity = Math.floor(this.truckTypeList[1].CapacityM3 / volBundle) * this.formData.BoxBundle;
      this.quoteService.tortonCapacity = Math.floor(this.truckTypeList[2].CapacityM3 / volBundle) * this.formData.BoxBundle;
      this.quoteService.rabonCapacity = Math.floor(this.truckTypeList[3].CapacityM3 / volBundle) * this.formData.BoxBundle;
      this.quoteService.camionetaCapacity = Math.floor(this.truckTypeList[4].CapacityM3 / volBundle) * this.formData.BoxBundle;
      //end test
      widthP = 1.15;
      lengthP = 1.20;
      heightP = 1.20;
      layerP = Math.round(heightP / heightBundle);
      heightPalletF = parseFloat((layerP * heightBundle).toFixed(3));
      lengthB = Math.max(this.quoteService.lengthBundle, this.quoteService.widthBundle);
      widthB = Math.min(this.quoteService.lengthBundle, this.quoteService.widthBundle);

      areaBundle = this.quoteService.lengthBundle * this.quoteService.widthBundle;
      xlengthPlengthB = Math.floor(lengthP / lengthB);
      ywidthPlengthB = Math.floor(widthP / lengthB);
      wlengthPwidthB = Math.floor(lengthP / widthB);
      zwidthPwidthB = Math.floor(widthP / widthB);
      if(xlengthPlengthB == 0 && ywidthPlengthB == 0 && wlengthPwidthB == 0 && zwidthPwidthB == 0 ){
        ywidthPlengthB = 1;
        wlengthPwidthB = 1;
      };
      if(widthB > 1.25 && lengthB > widthB){
        layerPallet = 2;
      }else {
        layerPallet = 4;
      };
      //number maximun boundle per pallet
      maxBundle01 = xlengthPlengthB * zwidthPwidthB;
      maxBundle02 = ywidthPlengthB * wlengthPwidthB;

      //end
      if ((lengthB + widthB) <= widthP) {
        blocksB = Math.floor(widthP / (lengthB + widthB))
        blocks02B = Math.floor(widthP / (lengthB + (2 * widthB)))
        numBunBlock = blocksB * (xlengthPlengthB + wlengthPwidthB)
        numBunBlocks02 = blocks02B * (wlengthPwidthB + (2 * xlengthPlengthB))
        maxBundleP = Math.max(numBunBlock, numBunBlocks02, maxBundle01, maxBundle02)

        if ((maxBundle01 >= maxBundle02) && (maxBundle01 >= numBunBlock) && (maxBundle01 >= numBunBlocks02)){
          maxBundleP = maxBundle01;
          numBundleLength = xlengthPlengthB;
          numBundleWidth = zwidthPwidthB;
          lengthPalletF = numBundleLength * lengthB
          widthPalletF = numBundleWidth * widthB
        }else if ((maxBundle02 >= maxBundle01) && (maxBundle02 >= numBunBlock) && (maxBundle02 >= numBunBlocks02)){
          maxBundleP = maxBundle02;
          numBundleLength = wlengthPwidthB;
          numBundleWidth = ywidthPlengthB;
          lengthPalletF = numBundleLength * widthB;
          widthPalletF = numBundleWidth * lengthB;
        }else if ((numBunBlock >= maxBundle01) && (numBunBlock >= maxBundle02) && (numBunBlock >= numBunBlocks02)){
          maxBundleP = numBunBlock;
          numBundleLength = wlengthPwidthB;
          numBundleWidth = 2 * blocksB;
          lengthPalletF = numBundleLength * widthB;
          widthPalletF = blocksB * (lengthB + widthB);
        }else if ((numBunBlocks02 >= maxBundle01) && (numBunBlocks02 >= maxBundle02) && (numBunBlocks02 >= numBunBlock)){
          maxBundleP = numBunBlocks02;
          numBundleLength = wlengthPwidthB;
          numBundleWidth = 3 * blocks02B;
          lengthPalletF = numBundleLength * widthB
          widthPalletF = blocks02B * (lengthB + (2 * widthB))
        };
      }else if (((lengthB + widthB) > widthP) && (widthB <= (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
        numBundleLength = wlengthPwidthB;
        numBundleWidth = ywidthPlengthB;
        maxBundleP = numBundleLength + numBundleWidth;
        widthPalletF = 2 * widthB;
        lengthPalletF = lengthB + widthB;
      }else if (((lengthB + widthB) > widthP) && (widthB > (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
        numBundleLength = wlengthPwidthB;
        numBundleWidth = ywidthPlengthB;
        maxBundleP = numBundleLength * numBundleWidth;
        widthPalletF = numBundleLength * widthB;
        lengthPalletF = numBundleWidth * lengthB;
      }else if (((lengthB + widthB) > lengthP) && (lengthB < lengthP)) {
        maxBundleP = wlengthPwidthB;
        numBundleLength = wlengthPwidthB;
        numBundleWidth = ywidthPlengthB;
        lengthPalletF = maxBundleP * widthB;
        widthPalletF = lengthB; 
      }else if (((lengthB + widthB) > lengthP) && (lengthB > lengthP)){
        maxBundleP = wlengthPwidthB;
        numBundleLength = wlengthPwidthB;
        numBundleWidth = ywidthPlengthB;
        if(widthB > 1.25){
          lengthPalletF = maxBundleP * widthB;
          widthPalletF =  lengthB;
        }else {
          lengthPalletF = lengthB;
          widthPalletF = maxBundleP * widthB; 
        };
      };
        //test orientation of pallet for width an length
      if((lengthPalletF <= 1.2) && (lengthPalletF > widthPalletF)){
        let newlengthPalletF: number = widthPalletF;
        widthPalletF = lengthPalletF;
        lengthPalletF = newlengthPalletF;
      };
        //end
      boxPallet = (maxBundleP * this.formData.BoxBundle * layerP);
      this.quoteService.numPalletCargo = Math.ceil(this.quoteService.formData.BoxShipment / (boxPallet))
      this.formData.Pallet = false;
      if(volCargo > this.truckTypeList[0].CapacityM3){
        this.toastr.error('La cantidad de piezas sobrepasa la capadidad de la unidad mas grande: FULL, '+ 
        'por favor consulte en la etiqueta TRANSPORTE cuánto puede embarcar por cada tipo de unidad ' +
        'para este símbolo:', 'Corrugated Quote.');
        this.quoteService.formData.UsedCapacityTruck = Math.floor(volCargo * 100 / this.truckTypeList[0].CapacityM3)
      };
      //end test
    }else {  
      if (this.quoteService.formData.CargoType == "Sin Tarima"){
        widthP = 1.15;
        lengthP = 1.20;
        heightP = 1.20;
        layerP = Math.round(heightP / heightBundle);
        heightPalletF = parseFloat((layerP * heightBundle).toFixed(3));
        lengthB = Math.max(this.quoteService.lengthBundle, this.quoteService.widthBundle);
        widthB = Math.min(this.quoteService.lengthBundle, this.quoteService.widthBundle);

        areaBundle = this.quoteService.lengthBundle * this.quoteService.widthBundle;
        xlengthPlengthB = Math.floor(lengthP / lengthB);
        ywidthPlengthB = Math.floor(widthP / lengthB);
        wlengthPwidthB = Math.floor(lengthP / widthB);
        zwidthPwidthB = Math.floor(widthP / widthB);
        //if x,y,w,z are 0, then 
        if(xlengthPlengthB == 0 && ywidthPlengthB == 0 && wlengthPwidthB == 0 && zwidthPwidthB == 0 ){
          ywidthPlengthB = 1;
          wlengthPwidthB = 1;
        };
        if(widthB > 1.25 && lengthB > widthB){
          layerPallet = 2;
        }else {
          layerPallet = 4;
        };
        //end
        //number maximun boundle per pallet
        maxBundle01 = xlengthPlengthB * zwidthPwidthB;
        maxBundle02 = ywidthPlengthB * wlengthPwidthB;
        //end
        if ((lengthB + widthB) <= widthP) {
          blocksB = Math.floor(widthP / (lengthB + widthB))
          blocks02B = Math.floor(widthP / (lengthB + (2 * widthB)))
          numBunBlock = blocksB * (xlengthPlengthB + wlengthPwidthB)
          numBunBlocks02 = blocks02B * (wlengthPwidthB + (2 * xlengthPlengthB))
          maxBundleP = Math.max(numBunBlock, numBunBlocks02, maxBundle01, maxBundle02)

          if ((maxBundle01 >= maxBundle02) && (maxBundle01 >= numBunBlock) && (maxBundle01 >= numBunBlocks02)){
            maxBundleP = maxBundle01;
            numBundleLength = xlengthPlengthB;
            numBundleWidth = zwidthPwidthB;
            lengthPalletF = numBundleLength * lengthB
            widthPalletF = numBundleWidth * widthB
          }else if ((maxBundle02 >= maxBundle01) && (maxBundle02 >= numBunBlock) && (maxBundle02 >= numBunBlocks02)){
            maxBundleP = maxBundle02;
            numBundleLength = wlengthPwidthB;
            numBundleWidth = ywidthPlengthB;
            lengthPalletF = numBundleLength * widthB;
            widthPalletF = numBundleWidth * lengthB;
          }else if ((numBunBlock >= maxBundle01) && (numBunBlock >= maxBundle02) && (numBunBlock >= numBunBlocks02)){
            maxBundleP = numBunBlock;
            numBundleLength = wlengthPwidthB;
            numBundleWidth = 2 * blocksB;
            lengthPalletF = numBundleLength * widthB;
            widthPalletF = blocksB * (lengthB + widthB);
          }else if ((numBunBlocks02 >= maxBundle01) && (numBunBlocks02 >= maxBundle02) && (numBunBlocks02 >= numBunBlock)){
            maxBundleP = numBunBlocks02;
            numBundleLength = wlengthPwidthB;
            numBundleWidth = 3 * blocks02B;
            lengthPalletF = numBundleLength * widthB
            widthPalletF = blocks02B * (lengthB + (2 * widthB))
          };
        }else if (((lengthB + widthB) > widthP) && (widthB <= (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
          numBundleLength = wlengthPwidthB;
          numBundleWidth = ywidthPlengthB;
          maxBundleP = numBundleLength + numBundleWidth;
          widthPalletF = 2 * widthB;
          lengthPalletF = lengthB + widthB;
        }else if (((lengthB + widthB) > widthP) && (widthB > (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
          numBundleLength = wlengthPwidthB;
          numBundleWidth = ywidthPlengthB;
          maxBundleP = numBundleLength * numBundleWidth;
          widthPalletF = numBundleLength * widthB;
          lengthPalletF = numBundleWidth * lengthB;
        }else if (((lengthB + widthB) > lengthP) && (lengthB < lengthP)) {
          maxBundleP = wlengthPwidthB;
          numBundleLength = wlengthPwidthB;
          numBundleWidth = ywidthPlengthB;
          lengthPalletF = maxBundleP * widthB;
          widthPalletF = lengthB; 
        }else if (((lengthB + widthB) > lengthP) && (lengthB > lengthP)){
          maxBundleP = wlengthPwidthB;
          numBundleLength = wlengthPwidthB;
          numBundleWidth = ywidthPlengthB;
          if(widthB > 1.25){
            lengthPalletF = maxBundleP * widthB;
            widthPalletF =  lengthB;
          }else {
            lengthPalletF = lengthB;
            widthPalletF = maxBundleP * widthB; 
          };
        };
          //test orientation of pallet on the truck
        if((lengthPalletF <= 1.2) && (lengthPalletF > widthPalletF)){
          let newlengthPalletF: number = widthPalletF;
          widthPalletF = lengthPalletF;
          lengthPalletF = newlengthPalletF;
        };
          //ende test
        this.formData.Pallet = false;
      }else if (this.quoteService.formData.CargoType == "Con Tarima"){
        widthP = this.quoteService.widthPallet / 100;
        lengthP = this.quoteService.lengthPallet / 100;
        heightP = 1.2 - (this.quoteService.heightPallet / 100);
        widthPalletF = widthP;
        lengthPalletF = lengthP;
          //test orientation of pallet on the truck
        if((lengthPalletF <= 1.2) && (lengthPalletF > widthPalletF)){
          let newlengthPalletF: number = widthPalletF;
          widthPalletF = lengthPalletF;
          lengthPalletF = newlengthPalletF;
        };
          //ende test
        layerP = Math.round(heightP / heightBundle);
        heightPalletF = parseFloat((layerP * heightBundle).toFixed(3));
        lengthB = Math.max(this.quoteService.lengthBundle, this.quoteService.widthBundle);
        widthB = Math.min(this.quoteService.lengthBundle, this.quoteService.widthBundle);

        if((lengthB > lengthP) || (widthB > widthP)){
          this.toastr.error('Revise por favor favor el tipo de TARIMA o las MEDIDAS de la caja, '+ 
          'las dimensiones finales del ATADO sobrepasan las medidas de la TARIMA ' +
          'para este símbolo:', 'Corrugated Quote.');
          exit();
        };

        areaBundle = this.quoteService.lengthBundle * this.quoteService.widthBundle;
        xlengthPlengthB = Math.floor(lengthP / lengthB);
        ywidthPlengthB = Math.floor(widthP / lengthB);
        wlengthPwidthB = Math.floor(lengthP / widthB);
        zwidthPwidthB = Math.floor(widthP / widthB);
        if(xlengthPlengthB == 0 && ywidthPlengthB == 0 && wlengthPwidthB == 0 && zwidthPwidthB == 0 ){
          ywidthPlengthB = 1;
          wlengthPwidthB = 1;
        };
        if(widthB > 1.25 && lengthB > widthB){
          layerPallet = 2;
        }else {
          layerPallet = 4;
        };
          //number maximun boundle per pallet
        maxBundle01 = xlengthPlengthB * zwidthPwidthB;
        maxBundle02 = ywidthPlengthB * wlengthPwidthB;
          //end
        if ((lengthB + widthB) <= widthP) {
          blocksB = Math.floor(widthP / (lengthB + widthB))
          blocks02B = Math.floor(widthP / (lengthB + (2 * widthB)))
          numBunBlock = blocksB * (xlengthPlengthB + wlengthPwidthB)
          numBunBlocks02 = blocks02B * (wlengthPwidthB + (2 * xlengthPlengthB))
          maxBundleP = Math.max(numBunBlock, numBunBlocks02, maxBundle01, maxBundle02)

          if ((maxBundle01 >= maxBundle02) && (maxBundle01 >= numBunBlock) && (maxBundle01 >= numBunBlocks02)){
            maxBundleP = maxBundle01;
            numBundleLength = xlengthPlengthB;
            numBundleWidth = zwidthPwidthB;  
          }else if ((maxBundle02 >= maxBundle01) && (maxBundle02 >= numBunBlock) && (maxBundle02 >= numBunBlocks02)){
            maxBundleP = maxBundle02;
            numBundleLength = wlengthPwidthB;
            numBundleWidth = ywidthPlengthB; 
          }else if ((numBunBlock >= maxBundle01) && (numBunBlock >= maxBundle02) && (numBunBlock >= numBunBlocks02)){
            maxBundleP = numBunBlock;
            numBundleLength = wlengthPwidthB;
            numBundleWidth = 2 * blocksB;
          }else if ((numBunBlocks02 >= maxBundle01) && (numBunBlocks02 >= maxBundle02) && (numBunBlocks02 >= numBunBlock)){
            maxBundleP = numBunBlocks02;
            numBundleLength = wlengthPwidthB;
            numBundleWidth = 3 * blocks02B;  
          };
        }else if (((lengthB + widthB) > widthP) && (widthB <= (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
          numBundleLength = wlengthPwidthB;
          numBundleWidth = ywidthPlengthB;
          maxBundleP = numBundleLength + numBundleWidth;
          }else if (((lengthB + widthB) > widthP) && (widthB > (widthP / 2)) && ((lengthB + widthB) <= lengthP)){
          numBundleLength = wlengthPwidthB;
          numBundleWidth = ywidthPlengthB;
          maxBundleP = numBundleLength * numBundleWidth;
        }else if (((lengthB + widthB) > lengthP) && (lengthB < lengthP)) {
          maxBundleP = wlengthPwidthB;
          numBundleLength = wlengthPwidthB;
          numBundleWidth = ywidthPlengthB;
        };
      };
      boxPallet = (maxBundleP * this.formData.BoxBundle * layerP);
      this.quoteService.numPalletCargo = Math.ceil(this.quoteService.formData.BoxShipment / (boxPallet))
      lengthCargo = Math.ceil(this.quoteService.numPalletCargo / layerPallet) * lengthPalletF;
      //test orientation of pallet on the trucks
      if(((2 * widthPalletF) < (widthPalletF + lengthPalletF)) && ((widthPalletF + lengthPalletF) <= (2.4))){
        let sideWidth: number = 0; let sideLength: number = 0;
        let newPalletComb: number = 0; let lengthCargoWidth: number = 0; 
        let numPalletWidth: number = 0; let numPalletLength: number = 0; 
        palletArrangement = 1;
        lengthCargo = 0;
        for(let indexPallet: number = 1; newPalletComb <= (this.quoteService.numPalletCargo - layerPallet); indexPallet++){
          sideWidth = sideWidth + (boxPallet * layerPallet / 2);
          sideLength = sideLength + (boxPallet * layerPallet / 2);
          newPalletComb = newPalletComb + layerPallet;
          lengthCargoWidth = lengthCargoWidth + widthPalletF
          lengthCargo = lengthCargo + lengthPalletF
          if ((lengthCargo - lengthCargoWidth) > widthPalletF) {
            sideWidth = sideWidth + (boxPallet * layerPallet / 2);
            newPalletComb = newPalletComb + 1;
            lengthCargoWidth = lengthCargoWidth + widthPalletF;
          };
        };
        numPalletWidth = sideWidth / boxPallet;
        numPalletLength = sideLength / boxPallet;
        let leftPallet: number = 0;
        leftPallet = this.quoteService.numPalletCargo - (numPalletWidth + numPalletLength);
        if(leftPallet < 0){
          if((leftPallet == -4) || (leftPallet == -3)){
            lengthCargo = ((numPalletLength - 2) / (layerPallet / 2)) * lengthPalletF;
            lengthCargoWidth = ((numPalletWidth - 2) / (layerPallet / 2)) * widthPalletF;
          }else if((leftPallet == -2) || (leftPallet == -1)) {
            lengthCargoWidth = lengthCargoWidth;
            lengthCargo = lengthCargoWidth;
          };
        };
        if(leftPallet > 0){
          if((leftPallet > 2) && ((lengthCargo - lengthCargoWidth) < widthPalletF)){
            lengthCargo = lengthCargo + lengthPalletF;
            lengthCargoWidth = lengthCargoWidth + widthPalletF;
          }else if((leftPallet > 2) && ((lengthCargo - lengthCargoWidth) > widthPalletF)) {
            lengthCargoWidth = lengthCargoWidth + (2 * widthPalletF);
            lengthCargo = lengthCargoWidth;
          }else {
            lengthCargoWidth = lengthCargoWidth + widthPalletF;
            lengthCargo = lengthCargoWidth;
          };
        };
      }; 
      //end

      if(lengthCargo <= 2){
        indexTruck = 4;
      }else if (lengthCargo > 2 && lengthCargo <= 6.4){
        indexTruck = 3;
      }else if (lengthCargo > 6.4 && lengthCargo <= 6.9){
        indexTruck = 2;
      }else if (lengthCargo > 6.9 && lengthCargo <= 14.4){
        indexTruck = 1;
      }else if (lengthCargo > 14.4 && lengthCargo <= 24){
        indexTruck = 0;
      };
      this.quoteService.formData.TruckName = this.truckTypeList[indexTruck].TruckName;
      //quantity pallets
      volCargo = Math.floor(this.truckTypeList[indexTruck].Length / lengthPalletF) * layerPallet;
      //test volum when you need aprovechar the capacity truck
      if (palletArrangement == 1){
        //volCargo = (Math.floor(this.truckTypeList[indexTruck].Length / lengthPalletF) * (layerPallet / 2)) + (Math.floor(this.truckTypeList[indexTruck].Length / widthPalletF) * (layerPallet / 2));
        //volCargo = (layerPallet / 2) * (Math.floor(this.truckTypeList[indexTruck].Length / lengthPalletF) + Math.floor(this.truckTypeList[indexTruck].Length / widthPalletF));
        volCargo =  Math.floor(this.quoteService.numPalletCargo * this.truckTypeList[indexTruck].Length / lengthCargo);
      };
      //end
      this.quoteService.formData.TruckID = this.truckTypeList[indexTruck].TruckID;
      this.quoteService.formData.IdealTruck = this.quoteService.formData.TruckName;
      this.quoteService.formData.UsedCapacityTruck = Math.ceil(this.quoteService.numPalletCargo * 100 / volCargo);
      //test
      this.quoteService.fullCapacity = Math.floor(this.truckTypeList[0].Length / lengthPalletF) * layerPallet * boxPallet;
      this.quoteService.trailerCapacity = Math.floor(this.truckTypeList[1].Length / lengthPalletF) * layerPallet * boxPallet;
      this.quoteService.tortonCapacity = Math.floor(this.truckTypeList[2].Length / lengthPalletF) * layerPallet * boxPallet;
      this.quoteService.rabonCapacity = Math.floor(this.truckTypeList[3].Length / lengthPalletF) * layerPallet * boxPallet;
      this.quoteService.camionetaCapacity = Math.floor(this.truckTypeList[4].Length / lengthPalletF) * layerPallet * boxPallet;
      //end test
      if (palletArrangement == 1){
        this.quoteService.fullCapacity = (layerPallet / 2) * boxPallet * (Math.floor(this.truckTypeList[0].Length / lengthPalletF) + Math.floor(this.truckTypeList[0].Length / widthPalletF));
        this.quoteService.trailerCapacity = (layerPallet / 2) * boxPallet * (Math.floor(this.truckTypeList[1].Length / lengthPalletF) + Math.floor(this.truckTypeList[1].Length / widthPalletF));
        this.quoteService.tortonCapacity = (layerPallet / 2) * boxPallet * (Math.floor(this.truckTypeList[2].Length / lengthPalletF) + Math.floor(this.truckTypeList[2].Length / widthPalletF));
        this.quoteService.rabonCapacity = (layerPallet / 2) * boxPallet * (Math.floor(this.truckTypeList[3].Length / lengthPalletF) + Math.floor(this.truckTypeList[3].Length / widthPalletF));
        this.quoteService.camionetaCapacity = (layerPallet / 2) * boxPallet * (Math.floor(this.truckTypeList[4].Length / lengthPalletF) + Math.floor(this.truckTypeList[4].Length / widthPalletF));
      };
      if(this.quoteService.formData.UsedCapacityTruck > 100){
        this.toastr.error('La cantidad de piezas sobrepasa la capadidad de la unidad mas grande: FULL, '+ 
        'por favor consulte en la etiqueta TRANSPORTE cuánto puede embarcar por cada tipo de unidad ' +
        'para este símbolo:', 'Corrugated Quote.');
      };
    };
    this.formData.BundleAlongPallet = numBundleLength;
    this.formData.BundleWidthPallet = numBundleWidth;
    this.formData.Layer = layerP;
    this.quoteService.layerBundle = maxBundleP;
    this.quoteService.originalQuantityShipment = this.quoteService.formData.BoxShipment;
    this.quoteService.formData.PalletLength = lengthPalletF;
    this.quoteService.formData.PalletWidth = widthPalletF;
    this.quoteService.formData.PalletHeight = heightPalletF;
    this.quoteService.formData.PalletProduced = Math.ceil(this.quoteService.formData.AverageOrder / (boxPallet))
    this.quoteService.formData.PalletNumber = this.quoteService.numPalletCargo;
    this.quoteService.formData.BundleVolum = volBundle;
    this.quoteService.formData.BundlePallet = (boxPallet / this.formData.BoxBundle);
  };

  onNewOrEditQuote(){
    if(this.quoteService.formData.QuoteID == null && this.quoteService.NewOrEdit == false){
      this.quoteService.NewOrEdit = true;
    };
  };

  onNewOrEditCancel(){
    if(this.quoteService.formData.QuoteID != null && this.quoteService.NewOrEdit == true){
      this.quoteService.NewOrEdit = false;
    };
  };

  //valida cuando seleccionas el numero de tintas
  onValidateInk(inkNum){   
    if(inkNum.value==0){
      (<HTMLInputElement> document.getElementById("InkID01")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk01")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate01")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID01")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk01")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate01")).value = "0";
      
      (<HTMLInputElement> document.getElementById("InkID02")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk02")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate02")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID02")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk02")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate02")).value = "0";

      (<HTMLInputElement> document.getElementById("InkID03")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk03")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate03")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID03")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk03")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate03")).value = "0";

      (<HTMLInputElement> document.getElementById("InkID04")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk04")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate04")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID04")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk04")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate04")).value = "0";

      (<HTMLInputElement> document.getElementById("InkID05")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk05")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate05")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID05")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk05")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate05")).value = "0";

      (<HTMLInputElement> document.getElementById("InkID06")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk06")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID06")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk06")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).value = "0";

    }else if(inkNum.value==1){
      (<HTMLInputElement> document.getElementById("InkID01")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk01")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate01")).disabled = false;
      
      (<HTMLInputElement> document.getElementById("InkID02")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk02")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate02")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID02")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk02")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate02")).value = "0";

      (<HTMLInputElement> document.getElementById("InkID03")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk03")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate03")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID03")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk03")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate03")).value = "0";

      (<HTMLInputElement> document.getElementById("InkID04")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk04")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate04")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID04")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk04")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate04")).value = "0";

      (<HTMLInputElement> document.getElementById("InkID05")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk05")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate05")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID05")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk05")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate05")).value = "0";

      (<HTMLInputElement> document.getElementById("InkID06")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk06")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID06")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk06")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).value = "0";

    }else if(inkNum.value==2){
      (<HTMLInputElement> document.getElementById("InkID01")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk01")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate01")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID02")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk02")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate02")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID03")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk03")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate03")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID03")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk03")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate03")).value = "0";

      (<HTMLInputElement> document.getElementById("InkID04")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk04")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate04")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID04")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk04")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate04")).value = "0";

      (<HTMLInputElement> document.getElementById("InkID05")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk05")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate05")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID05")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk05")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate05")).value = "0";

      (<HTMLInputElement> document.getElementById("InkID06")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk06")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID06")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk06")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).value = "0";
      
    }else if(inkNum.value==3){
      (<HTMLInputElement> document.getElementById("InkID01")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk01")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate01")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID02")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk02")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate02")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID03")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk03")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate03")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID04")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk04")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate04")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID04")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk04")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate04")).value = "0";

      (<HTMLInputElement> document.getElementById("InkID05")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk05")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate05")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID05")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk05")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate05")).value = "0";

      (<HTMLInputElement> document.getElementById("InkID06")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk06")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID06")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk06")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).value = "0";
      
    }else if(inkNum.value==4){
      (<HTMLInputElement> document.getElementById("InkID01")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk01")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate01")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID02")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk02")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate02")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID03")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk03")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate03")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID04")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk04")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate04")).disabled = false;
      
      (<HTMLInputElement> document.getElementById("InkID05")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk05")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate05")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID05")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk05")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate05")).value = "0";

      (<HTMLInputElement> document.getElementById("InkID06")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk06")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID06")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk06")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).value = "0";
      
    }else if(inkNum.value==5){
      (<HTMLInputElement> document.getElementById("InkID01")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk01")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate01")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID02")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk02")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate02")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID03")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk03")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate03")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID04")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk04")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate04")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID05")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk05")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate05")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID06")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerInk06")).disabled = true;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).disabled = true;
      (<HTMLInputElement> document.getElementById("InkID06")).value = "0";
      (<HTMLInputElement> document.getElementById("PerInk06")).value = "0";
      (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).value = "0";
      
    }else if(inkNum.value==6){

      (<HTMLInputElement> document.getElementById("InkID01")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk01")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate01")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID02")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk02")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate02")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID03")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk03")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate03")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID04")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk04")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate04")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID05")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk05")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate05")).disabled = false;

      (<HTMLInputElement> document.getElementById("InkID06")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerInk06")).disabled = false;
      (<HTMLInputElement> document.getElementById("PerPrintingPlate06")).disabled = false;
      
    }
  }
};
