import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { Board } from 'src/app/shared/board.model';
import { BoardService } from 'src/app/shared/board.service';
import { Boardcost } from 'src/app/shared/boardcost.model';
import { BoardcostService } from 'src/app/shared/boardcost.service';

@Component({
  selector: 'app-boardcost-items',
  templateUrl: './boardcost-items.component.html',
  styles: []
})
export class BoardcostItemsComponent implements OnInit {
  isValid: boolean = true;

  constructor(
  @Inject(MAT_DIALOG_DATA) 
  public data,
  public dialogRef: MatDialogRef<BoardcostItemsComponent>,
  public boardCostService: BoardcostService, private boardService: BoardService,
  private toastr: ToastrService) { }

  ngOnInit(): void {

    

    if (this.data.boardCostItemIndex==null){
      this.boardCostService.boardCostList = {
        BoardID: '', 
        BoardPaperCostMM2: 0, 
        BoardPaperCostTon: 0, 
        StarchCostMM2: 0,  
        StarchCostTon: 0,  
        CoatingCostMM2: 0, 
        CoatingCostTon: 0,
        WaterCostMM2: 0, 
        WaterCostTon: 0, 
        OtherAuxCostMM2: 0, 
        OtherAuxCostTon: 0,  
        ForkliftGasCostMM2: 0,
        ForkliftGasCostTon: 0  
      }
    }else {
        this.boardCostService.getBoardCostListId(this.data.BoardID).subscribe(res => this.boardCostService.boardCostList = res as Boardcost);
      };
  };

  onSubmit (form: NgForm){
    //if (this.validateForm(form.value)){
      //this.validateForm(form.value);
      if (this.isValid == false){
        this.dialogRef.close();
      }else {
        if (this.validateForm(form.value)){
            this.boardCostService.putBoardCostID(this.boardCostService.boardCostList.BoardID, this.boardCostService.boardCostList).subscribe(res => {
              this.toastr.success('Actualización exitosa', 'Corrugated Quote.');
            });
          this.dialogRef.close();
        };
        this.isValid = true;
      };
  };

  validateForm(formBoard: Board){
    this.onvalidateForm();
    if (this.isValid == false){
      exit;
    }else {
      this.isValid = true;
    };
    return this.isValid;
  };

  onClose(){
    this.isValid = false;
    this.dialogRef.close();//luego debere quitar esta linea de codigo.
  };

  onvalidateForm(){
    /* let kindFlute: string;

    if (this.boardService.boardList.FluteID == 0){
      this.getformFocus("FluteID", "Flute")
      kindFlute.length == 0;
    } else {
      kindFlute = this.fluteList.find(f => f.FluteID == this.boardService.boardList.FluteID).FluteNomenclature;
    }
    if (this.boardService.boardList.ECT == 0){
      this.getformFocus("ECT", "ECT")
    };
    if (this.boardService.boardList.ColorPaperExt == ""){
      this.getformFocus("ColorPaperExt", "Color Paper Exterior")
    };
    if (this.boardService.boardList.TypeStarch == ""){
      this.getformFocus("TypeStarch", "Starch Type")
    };
    if (this.boardService.boardList.CoatingTo == ""){
      this.getformFocus("CoatingTo", "Coating To")
    };
    if (this.boardService.boardList.TypeMarket == ""){
      this.getformFocus("TypeMarket", "Market Type")
    };    
    if (kindFlute.length <= 1){
      if (this.boardService.boardList.PaperID01 == ""){
        this.getformFocus("PaperID01", "Outer liner")
      };
      if (this.boardService.boardList.PaperID02 == ""){
        this.getformFocus("PaperID02", "Medium paper")
      };
      if (this.boardService.boardList.PaperID03 == ""){
        this.getformFocus("PaperID03", "Inner liner")
      };
    }
    else 
    {
      if (this.boardService.boardList.PaperID01 == ""){
        this.getformFocus("PaperID01", "Outer liner")
      };
      if (this.boardService.boardList.PaperID02 == ""){
        this.getformFocus("PaperID02", "Medium paper")
      };
      if (this.boardService.boardList.PaperID03 == ""){
        this.getformFocus("PaperID03", "Inner liner")
      };
      if (this.boardService.boardList.PaperID04 == ""){
        this.getformFocus("PaperID04", "Medium paper")
      };
      if (this.boardService.boardList.PaperID05 == ""){
        this.getformFocus("PaperID05", "Inner liner")
      };
    }; */
  };

  getformFocus(formID: string, nameForm: string) {
    this.toastr.error('Por favor, seleccione un(a) ' + nameForm, 'Corrugated Quote.');
    document.getElementById(formID).focus();
    this.isValid = false;
    return this.isValid;
  };


}
