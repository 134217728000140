import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TruckType } from './TruckType.model';

@Injectable({
  providedIn: 'root'
})
export class TruckTypeService {
  trucktypeList: TruckType;
  dataTruckType: TruckType;

  constructor(private http: HttpClient) { }

  getTruckTypeList(){
    return this.http.get(environment.apiURL + '/TruckType').toPromise();
  };

  getTruckTypeListId(truckName: string){
    return this.http.get(environment.apiURL + '/TruckType/'+ truckName).toPromise();
  }

  deleteTruckType(truckID: number) {
    return this.http.delete(environment.apiURL + '/TruckType/' + truckID).toPromise();
  };

  putTruckTypeID(id: number, truckType: TruckType) {
    return this.http.put(environment.apiURL + '/TruckType/' + id, truckType);
  };

  postTruckType() {
    return this.http.post(environment.apiURL + '/TruckType', this.trucktypeList);
  };

}
