import { Component, OnInit, EventEmitter,Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Ink } from 'src/app/shared/ink.model';
import { InkService } from 'src/app/shared/ink.service';
import { InkItemsComponent } from './ink-items.component';

@Component({
  selector: 'app-ink',
  templateUrl: './ink.component.html',
  styles: []
})
export class InkComponent implements OnInit {

//paginación declaracion de variables
itemsPerPage=12;
currentPage=1;
totalPages = 0;
totalItems=0;
data1;
totales;
pages: number [] = [];
@Output() onClick: EventEmitter<number> = new EventEmitter();//captura el evento click y se mueva

  constructor(private dialog: MatDialog, public dataInk: InkService, 
    private toastr: ToastrService) { }

    ngOnInit(): void {
      this.dataInk.getInkList().then(res => this.dataInk.dataInk = res as Ink);
    }
  //paginación funciones
  get paginatedData(){
    this.data1 = JSON.stringify(this.dataInk.dataInk);
    this.data1 = JSON.parse(this.data1);
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.totalItems = this.data1.length;
      return this.data1.slice(start,end);
  }

  ngAfterViewInit(){
  this.updateItems();
  }

  updateItems(){
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.pages = Array.from({length: this.totalPages}, (_, i) => i + 1);
    //console.log(this.pages);
  }

  pageClicked(page: number){
    if(page > this.totalPages) return;
    this.onClick.emit(page);
    this.changePage(page);
  }

  changePage(page: number){
  this.currentPage=page;
  } 

    refreshList(){
      //this.dataBoard.dataBoard.BoardIDFilter = "";
      document.getElementById("InkIDFilter").focus();
      var formInkID = <HTMLInputElement> document.getElementById("InkIDFilter");
      //formBoardID.value = ''
      if ( formInkID.value == '' || formInkID.value == undefined){
        this.dataInk.getInkList().then(res => this.dataInk.dataInk = res as Ink);
      } else {
        this.dataInk.getInkListId(formInkID.value).then(res => this.dataInk.dataInk = res as Ink);
      }
    };
  
    AddOrEditInkItem(inkItemIndex, InkID){
      const dialogConfig = new MatDialogConfig(); 
      dialogConfig.autoFocus = true;
      dialogConfig.disableClose = true;
      dialogConfig.width ="60%";
      dialogConfig.height ="40%";
      dialogConfig.data = { inkItemIndex, InkID };
      this.dialog.open(InkItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
        this.refreshList();
        });
    };
  
    onDeleteInkItem(inkID: number){
      if (confirm('Estas seguro de querer eliminar el registro?')){
        this.dataInk.deleteInk(inkID).then(res => {
          this.refreshList();
          this.toastr.warning('Borrado exitosamente!', 'Corrugated Quote.');
        });
      };
    };
  
    onFilterInkName(auxiliaryName: string){
      this.dataInk.getInkListId(auxiliaryName).then(res => this.dataInk.dataInk = res as Ink);
      this.updateItems();
    };
  
    getformFocus(formID: string) {
      document.getElementById(formID).focus();
      var formInkID = <HTMLInputElement> document.getElementById(formID);
      formInkID.value = ''
    };
}
