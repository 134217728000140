import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TruckType } from 'src/app/shared/TruckType.model';
import { TruckTypeService } from 'src/app/shared/TruckType.service';
import { TrucktypeItemComponent } from './trucktype-item.component';

@Component({
  selector: 'app-trucktype',
  templateUrl: './trucktype.component.html',
  styles: []
})
export class TrucktypeComponent implements OnInit {

  constructor(private dialog: MatDialog, public dataTruckType: TruckTypeService, 
    private toastr: ToastrService) { }

    ngOnInit(): void {
      this.dataTruckType.getTruckTypeList().then(res => this.dataTruckType.dataTruckType = res as TruckType);
    }
  
    refreshList(){
      //this.dataBoard.dataBoard.BoardIDFilter = "";
      document.getElementById("TruckIDFilter").focus();
      var formTruckID = <HTMLInputElement> document.getElementById("TruckIDFilter");
      //formBoardID.value = ''
      if ( formTruckID.value == '' || formTruckID.value == undefined){
        this.dataTruckType.getTruckTypeList().then(res => this.dataTruckType.dataTruckType = res as TruckType);
      } else {
        this.dataTruckType.getTruckTypeListId(formTruckID.value).then(res => this.dataTruckType.dataTruckType = res as TruckType);
      }
    };
  
    AddOrEditTruckTypeItem(truckItemIndex, TruckID){
      const dialogConfig = new MatDialogConfig(); 
      dialogConfig.autoFocus = true;
      dialogConfig.disableClose = true;
      dialogConfig.width ="60%";
      dialogConfig.height ="60%";
      dialogConfig.data = { truckItemIndex, TruckID };
      this.dialog.open(TrucktypeItemComponent, dialogConfig).afterClosed().subscribe(res =>{
        this.refreshList();
        });
    };
  
    onDeleteTruckTypeItem(truckID: number){
      if (confirm('Estas seguro de querer eliminar el registro?')){
        this.dataTruckType.deleteTruckType(truckID).then(res => {
          this.refreshList();
          this.toastr.warning('Borrado exitosamente!', 'Corrugated Quote.');
        });
      };
    };
  
    onFilterTruckName(truckName: string){
      this.dataTruckType.getTruckTypeListId(truckName).then(res => this.dataTruckType.dataTruckType = res as TruckType);
    };
  
    getformFocus(formID: string) {
      document.getElementById(formID).focus();
      var formTruckID = <HTMLInputElement> document.getElementById(formID);
      formTruckID.value = ''
    };

}
