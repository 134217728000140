<h4 class="display-4">Mantenimiento de Papeles</h4>
<hr>
<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    
    <div class="form-row">
        <div class="form-group col-md-1">
            <label>ID Papel</label>
            <input name="PaperID" #PaperID="ngModel" [(ngModel)]="paperService.paperList.PaperID" class="form-control"
            readonly>  
        </div>
        <div class="form-group col-md-2">
            <label>Nomenclatura</label>
            <input name="PaperNomenclature" id="PaperNomenclature" #PaperNomenclature="ngModel" 
            [(ngModel)]="paperService.paperList.PaperNomenclature" class="form-control" readonly>  
        </div>
        <div class="form-group col-md-1">
            <label>Cod. Tipo</label>
            <input name="PaperTypeAbbreviated" id="PaperTypeAbbreviated" #PaperTypeAbbreviated="ngModel" 
            [(ngModel)]="paperService.paperList.PaperTypeAbbreviated" class="form-control" readonly>  
        </div>
        <div class="form-group col-md-2">
            <label>Tipo de Papel</label>
            <select name="PaperType" id="PaperType" #PaperType="ngModel" [(ngModel)]="paperService.paperList.PaperType" 
                class="form-control">
                <option value="">-Select-</option>
                <option value="Test Liner">Test Liner</option>
                <option value="Test Medium">Test Medium</option>
                <option value="Kraft Liner">Kraft Liner</option>
                <option value="Kraft Medium">Kraft Medium</option>
                <option value="White Top">White Top</option>
            </select>  
        </div>
        
        <div class="form-group col-md-2">
            <label>Procedencia</label>
            <select name="PaperNatOrImp" id="PaperNatOrImp" #PaperNatOrImp="ngModel" [(ngModel)]="paperService.paperList.PaperNatOrImp" 
                class="form-control">
                <option value="">-Select-</option>
                <option value="N">Nacional</option>
                <option value="I">Importado</option>
            </select>  
        </div>
        <div class="form-group col-md-4">
            <label>Proveedor</label>
            <select name="SupplierID" id="SupplierID" #SupplierID="ngModel" [(ngModel)]="paperService.paperList.SupplierID" 
            class="form-control">
                <option value="0">-Select-</option>
                <option *ngFor="let item of supplierList" value="{{item.SupplierID}}">{{item.SupplierName}}</option>
            </select>  
        </div>
    </div>  

    <div class="form-row">
        <div class="form-group col-md-1">
            <label>Color</label>
            <input name="ColorPaper" id="ColorPaper" #ColorPaper="ngModel" [(ngModel)]="paperService.paperList.ColorPaper" 
                class="form-control" readonly>
        </div>
        <div class="form-group col-md-2">
            <label>Tipo de Mercado</label>
            <select name="TypeMarket" id="TypeMarket" #TypeMarket="ngModel" [(ngModel)]="paperService.paperList.TypeMarket" 
                class="form-control">
                <option value="">-Select-</option>
                <option value="I">Industrial</option>
                <option value="A">Agrícola</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label>Rendimiento</label>
            <select name="Performance" id="Performance" #Performance="ngModel" [(ngModel)]="paperService.paperList.Performance" 
                class="form-control">
                <option value="">-Select-</option>
                <option value="HP">Alto</option>
                <option value="NP">Normal</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label>Gramaje</label>
            <div class="input-group">
                <input name="Weight" id="Weight" #Weight="ngModel" [(ngModel)]="paperService.paperList.Weight" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">gr/m2</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Costo</label>
            <div class="input-group">
                <input name="CostTonLocCurrency" #CostTonLocCurrency="ngModel" [(ngModel)]="paperService.paperList.CostTonLocCurrency" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">MXN/Ton</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Costo</label>
            <div class="input-group">
                <input name="CostMMSLocCurrency" #CostMMSLocCurrency="ngModel" [(ngModel)]="paperService.paperList.CostMMSLocCurrency" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">MXN/MM2</div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-md-2">
            <label>CMT</label>
            <div class="input-group">
                <input name="CMT" #CMT="ngModel" [(ngModel)]="paperService.paperList.CMT" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">lb</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>CCT</label>
            <div class="input-group">
                <input name="CCT" #CCT="ngModel" [(ngModel)]="paperService.paperList.CCT" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">lb/pulg</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>SCT</label>
            <div class="input-group">
                <input name="SCT" #SCT="ngModel" [(ngModel)]="paperService.paperList.SCT" 
                class="form-control" type="number" min="0" style="text-align: right;"> 
                <div class="input-group-prepend">
                    <div class="input-group-text">kN/m</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>RCT</label>
            <div class="input-group">
                <input name="RCT" #RCT="ngModel" [(ngModel)]="paperService.paperList.RCT" 
                class="form-control" type="number" min="0" style="text-align: right;">
                <div class="input-group-prepend">
                    <div class="input-group-text">lb/pulg</div>
                </div>
            </div> 
        </div>
        <div class="form-group col-md-2">
            <label>COBB180</label>
            <div class="input-group">
                <input name="COBB180" #COBB180="ngModel" [(ngModel)]="paperService.paperList.COBB180" class="form-control"
                type="number" min="0" style="text-align: right;">  
                <div class="input-group-prepend">
                    <div class="input-group-text">gr</div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>COBB120</label>
            <div class="input-group">
                <input name="COBB120" #COBB120="ngModel" [(ngModel)]="paperService.paperList.COBB120" class="form-control"
                type="number" min="0" style="text-align: right;">
                <div class="input-group-prepend">
                    <div class="input-group-text">gr</div>
                </div>
            </div>  
        </div>
    </div>
    <div class="form-row">
        
        <div class="form-group col-md-2">
            <label>Papel Sustituto 1</label>
            <select name="PaperSustitute1" id="PaperSustitute1" #PaperSustitute1="ngModel" [(ngModel)]="paperService.paperList.PaperSustitute1" 
            class="form-control">
                <option value="">-Select-</option>
                <option *ngFor="let item of paperService.dataPaper" value="{{item.PaperNomenclature}}">{{item.PaperNomenclature}}</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label>Papel Sustituto 2</label>
            <select name="PaperSustitute2" id="PaperSustitute2" #PaperSustitute2="ngModel" [(ngModel)]="paperService.paperList.PaperSustitute2" 
            class="form-control">
                <option value="">-Select-</option>
                <option *ngFor="let item of paperService.dataPaper" value="{{item.PaperNomenclature}}">{{item.PaperNomenclature}}</option>
            </select>  
        </div>

        <div class="form-group col-md-2">
            <label>Papel Sustituto 3</label>
            <select name="PaperSustitute3" id="PaperSustitute3" #PaperSustitute3="ngModel" [(ngModel)]="paperService.paperList.PaperSustitute3" 
            class="form-control">
                <option value="">-Select-</option>
                <option *ngFor="let item of paperService.dataPaper" value="{{item.PaperNomenclature}}">{{item.PaperNomenclature}}</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label>Papel Sustituto 4</label>
            <select name="PaperSustitute4" id="PaperSustitute4" #PaperSustitute4="ngModel" [(ngModel)]="paperService.paperList.PaperSustitute4" 
            class="form-control">
                <option value="">-Select-</option>
                <option *ngFor="let item of paperService.dataPaper" value="{{item.PaperNomenclature}}">{{item.PaperNomenclature}}</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label>Papel Sustituto 5</label>
            <select name="PaperSustitute5" id="PaperSustitute5" #PaperSustitute5="ngModel" [(ngModel)]="paperService.paperList.PaperSustitute5" 
            class="form-control">
                <option value="">-Select-</option>
                <option *ngFor="let item of paperService.dataPaper" value="{{item.PaperNomenclature}}">{{item.PaperNomenclature}}</option>
            </select>  
        </div>
        <div class="form-group col-md-2">
            <label>Papel Sustituto 6</label>
            <select name="PaperSustitute6" id="PaperSustitute6" #PaperSustitute6="ngModel" [(ngModel)]="paperService.paperList.PaperSustitute6" 
            class="form-control">
                <option value="">-Select-</option>
                <option *ngFor="let item of paperService.dataPaper" value="{{item.PaperNomenclature}}">{{item.PaperNomenclature}}</option>
            </select>  
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label>Notas Generales</label>
            <input name="Note" #Note="ngModel" [(ngModel)]="paperService.paperList.Note" class="form-control">  
        </div>
    </div>
    <div class="form-group">
        <button type="submit" class="btn btn-dark"><i class="fa fa-database"></i> Guardar</button>
        <button type="submit" class="btn btn-outline-dark ml-1" (click)="onClose()"><i class="fa fa-close"></i> Cerrar</button>
    </div>
</form>
 

