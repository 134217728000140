import { Injectable } from '@angular/core';
import { Quote } from './quote.model';
import { QuoteItem } from './quote-item.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  formData: Quote;
  quoteItems: QuoteItem [];
  qBoxOrSheet: boolean;
  quoteBoxOrSheet: boolean = true;
  fullCapacity: number = 0;
  trailerCapacity: number = 0;
  tortonCapacity: number = 0;
  rabonCapacity: number = 0;
  camionetaCapacity: number = 0;
  widthPallet: number = 0;
  lengthPallet: number = 0;
  heightPallet: number = 0;
  NewOrEdit: boolean = false;
  originalQuantityShipment: number;
  originalCapacityTruck: number;
  viewBoxSheetBotton: boolean = false;
  viewLayer: boolean = false;
  layerBundle: number;
  numPalletCargo: number;
  lengthBundle: number; 
  widthBundle: number;
  boardflute: string;
  editUsoCapacityTruck: boolean = true;
  openNewQuote: boolean = false;
  numOpenNewQuote: number;
  //new cod for copy quote
  //copyFormData: Quote;
  //copyQuoteItems: QuoteItem [];
  //end


  constructor(private http: HttpClient) { }


  saveOrUpdateQuote() {
    this.formData.CustomerID = Number(this.formData.CustomerID);
    this.formData.DestinyID = Number(this.formData.DestinyID);
    this.formData.TruckID = Number(this.formData.TruckID);
    this.formData.PalletID = Number(this.formData.PalletID);
    this.formData.PalletLength = Number(this.formData.PalletLength);
    this.formData.PalletWidth = Number(this.formData.PalletWidth);
    this.formData.PalletHeight = Number(this.formData.PalletHeight);
    this.formData.PalletProduced = Number(this.formData.PalletProduced);
    this.formData.PalletNumber = Number(this.formData.PalletNumber);
    this.formData.BundleVolum = Number(this.formData.BundleVolum);
    this.formData.BundlePallet = Number(this.formData.BundlePallet);
    this.formData.UsedCapacityTruck = Number(this.formData.UsedCapacityTruck);
    this.formData.RateID = Number(this.formData.RateID);
    this.formData.AverageOrder = Number(this.formData.AverageOrder);
    this.formData.MonthlyOrder = Number(this.formData.MonthlyOrder);
    this.formData.BoxShipment = Number(this.formData.BoxShipment);
    this.formData.PriceMBox = Number(this.formData.PriceMBox);
    this.formData.PriceMM2 = Number(this.formData.PriceMM2);
    this.formData.PriceTon = Number(this.formData.PriceTon);
    this.formData.CostMBox = Number(this.formData.CostMBox);
    this.formData.CostMM2 = Number(this.formData.CostMM2);
    this.formData.CostTon = Number(this.formData.CostTon);
    this.formData.FinancialExpense = Number(this.formData.FinancialExpense);
    this.formData.ManufacturingCost = Number(this.formData.ManufacturingCost);
    this.formData.SaleCommission = Number(this.formData.SaleCommission);
    this.formData.TotalCost = Number(this.formData.TotalCost);
    this.formData.FreightCost = Number(this.formData.FreightCost);
    this.formData.TargetProfit = Number(this.formData.TargetProfit);
    this.formData.PriceWithoutFreight = Number(this.formData.PriceWithoutFreight);
    this.formData.PriceWithFreight = Number(this.formData.PriceWithFreight);
    this.formData.ProfitWithoutFreight = Number(this.formData.ProfitWithoutFreight);
    this.formData.ProfitWithFreight = Number(this.formData.ProfitWithFreight);

    this.quoteItems[0].StyleID = Number(this.quoteItems[0].StyleID);
    this.quoteItems[0].ClosureID = Number(this.quoteItems[0].ClosureID);
    this.quoteItems[0].Width = Number(this.quoteItems[0].Width);
    this.quoteItems[0].Length = Number(this.quoteItems[0].Length);
    this.quoteItems[0].Depth = Number(this.quoteItems[0].Depth);
    this.quoteItems[0].Output = Number(this.quoteItems[0].Output);
    this.quoteItems[0].outPutRDCL = Number(this.quoteItems[0].outPutRDCL);
    this.quoteItems[0].outPutRDCW = Number(this.quoteItems[0].outPutRDCW);
    this.quoteItems[0].AddTrim = Number(this.quoteItems[0].AddTrim);
    this.quoteItems[0].TapeID = Number(this.quoteItems[0].TapeID);
    this.quoteItems[0].TapeQuantity = Number(this.quoteItems[0].TapeQuantity);
    this.quoteItems[0].InkNumber = Number(this.quoteItems[0].InkNumber);
    this.quoteItems[0].InkID01 = Number(this.quoteItems[0].InkID01);
    this.quoteItems[0].InkID02 = Number(this.quoteItems[0].InkID02);
    this.quoteItems[0].InkID03 = Number(this.quoteItems[0].InkID03);
    this.quoteItems[0].InkID04 = Number(this.quoteItems[0].InkID04);
    this.quoteItems[0].InkID05 = Number(this.quoteItems[0].InkID05);
    this.quoteItems[0].InkID06 = Number(this.quoteItems[0].InkID06);
    this.quoteItems[0].PerInk01 = Number(this.quoteItems[0].PerInk01);
    this.quoteItems[0].PerInk02 = Number(this.quoteItems[0].PerInk02);
    this.quoteItems[0].PerInk03 = Number(this.quoteItems[0].PerInk03);
    this.quoteItems[0].PerInk04 = Number(this.quoteItems[0].PerInk04);
    this.quoteItems[0].PerInk05 = Number(this.quoteItems[0].PerInk05);
    this.quoteItems[0].PerInk06 = Number(this.quoteItems[0].PerInk06);
    this.quoteItems[0].PerPrintingPlate01 = Number(this.quoteItems[0].PerPrintingPlate01);
    this.quoteItems[0].PerPrintingPlate02 = Number(this.quoteItems[0].PerPrintingPlate02);
    this.quoteItems[0].PerPrintingPlate03 = Number(this.quoteItems[0].PerPrintingPlate03);
    this.quoteItems[0].PerPrintingPlate04 = Number(this.quoteItems[0].PerPrintingPlate04);
    this.quoteItems[0].PerPrintingPlate05 = Number(this.quoteItems[0].PerPrintingPlate05);
    this.quoteItems[0].PerPrintingPlate06 = Number(this.quoteItems[0].PerPrintingPlate06);
    this.quoteItems[0].BoxBundle = Number(this.quoteItems[0].BoxBundle);
    this.quoteItems[0].StrapNumber = Number(this.quoteItems[0].StrapNumber);
    this.quoteItems[0].BundleWidthPallet = Number(this.quoteItems[0].BundleWidthPallet);
    this.quoteItems[0].BundleAlongPallet = Number(this.quoteItems[0].BundleAlongPallet);
    this.quoteItems[0].Layer = Number(this.quoteItems[0].Layer);

    this.quoteItems[0].CostMP = Number(this.quoteItems[0].CostMP);
    this.quoteItems[0].CostAuxCorr = Number(this.quoteItems[0].CostAuxCorr);
    this.quoteItems[0].CostCorrToConv = Number(this.quoteItems[0].CostCorrToConv);
    this.quoteItems[0].CostFixed = Number(this.quoteItems[0].CostFixed);
    this.quoteItems[0].SubTotCorrugator = Number(this.quoteItems[0].SubTotCorrugator);
    this.quoteItems[0].CostMacConverting = Number(this.quoteItems[0].CostMacConverting);
    this.quoteItems[0].CostInk = Number(this.quoteItems[0].CostInk);
    this.quoteItems[0].CostPlateTotal = Number(this.quoteItems[0].CostPlateTotal);
    this.quoteItems[0].CostDieTotal = Number(this.quoteItems[0].CostDieTotal);
    this.quoteItems[0].CostPlate = Number(this.quoteItems[0].CostPlate);
    this.quoteItems[0].CostDie = Number(this.quoteItems[0].CostDie);
    this.quoteItems[0].CostPVA = Number(this.quoteItems[0].CostPVA);
    this.quoteItems[0].CostCover = Number(this.quoteItems[0].CostCover);
    this.quoteItems[0].CostStrapping5mm = Number(this.quoteItems[0].CostStrapping5mm);
    this.quoteItems[0].CostStrapping12mm = Number(this.quoteItems[0].CostStrapping12mm);
    this.quoteItems[0].CostWasteConv = Number(this.quoteItems[0].CostWasteConv);
    this.quoteItems[0].SubTotConverting = Number(this.quoteItems[0].SubTotConverting);

    //consumption materials
    this.quoteItems[0].ConsStarch = Number(this.quoteItems[0].ConsStarch);
    this.quoteItems[0].ConsCoating = Number(this.quoteItems[0].ConsCoating);
    this.quoteItems[0].ConsPVA = Number(this.quoteItems[0].ConsPVA);
    this.quoteItems[0].ConsDieRDC = Number(this.quoteItems[0].ConsDieRDC);
    this.quoteItems[0].ConsDieFDC = Number(this.quoteItems[0].ConsDieFDC);
    this.quoteItems[0].ConsStringKing = Number(this.quoteItems[0].ConsStringKing);
    this.quoteItems[0].ConsOpenSesame = Number(this.quoteItems[0].ConsOpenSesame);
    this.quoteItems[0].ConsCover = Number(this.quoteItems[0].ConsCover);
    this.quoteItems[0].ConsInk01 = Number(this.quoteItems[0].ConsInk01);
    this.quoteItems[0].ConsInk02 = Number(this.quoteItems[0].ConsInk02);
    this.quoteItems[0].ConsInk03 = Number(this.quoteItems[0].ConsInk03);
    this.quoteItems[0].ConsInk04 = Number(this.quoteItems[0].ConsInk04);
    this.quoteItems[0].ConsInk05 = Number(this.quoteItems[0].ConsInk05);
    this.quoteItems[0].ConsInk06 = Number(this.quoteItems[0].ConsInk06);
    this.quoteItems[0].ConsPlate01 = Number(this.quoteItems[0].ConsPlate01);
    this.quoteItems[0].ConsPlate02 = Number(this.quoteItems[0].ConsPlate02);
    this.quoteItems[0].ConsPlate03 = Number(this.quoteItems[0].ConsPlate03);
    this.quoteItems[0].ConsPlate04 = Number(this.quoteItems[0].ConsPlate04);
    this.quoteItems[0].ConsPlate05 = Number(this.quoteItems[0].ConsPlate05);
    this.quoteItems[0].ConsPlate06 = Number(this.quoteItems[0].ConsPlate06);
    this.quoteItems[0].ConsStrapping5mm = Number(this.quoteItems[0].ConsStrapping5mm);
    this.quoteItems[0].ConsStrapping12mm = Number(this.quoteItems[0].ConsStrapping12mm);
    this.quoteItems[0].ConsStrechFilm = Number(this.quoteItems[0].ConsStrechFilm);
    this.quoteItems[0].ConsPallet = Number(this.quoteItems[0].ConsPallet);
    this.quoteItems[0].ConsBoardPackaging = Number(this.quoteItems[0].ConsBoardPackaging);
    this.quoteItems[0].ConsWater = Number(this.quoteItems[0].ConsWater);
    this.quoteItems[0].ConsGasForklift = Number(this.quoteItems[0].ConsGasForklift);
    this.quoteItems[0].ConsGasBoiler = Number(this.quoteItems[0].ConsGasBoiler);

    //for (let i in this.quoteItems){
    //  this.quoteItems[i].ItemID=Number(this.quoteItems[i].ItemID);
    //  this.quoteItems[i].Quantity=Number(this.quoteItems[i].Quantity);   
    //}
    var body = {
      ...this.formData,      
      QuoteItem: this.quoteItems
    };
    return this.http.post(environment.apiURL + '/Quote', body)
  };

  getQuoteList(){
    return this.http.get(environment.apiURL + '/Quote').toPromise();
  };

  getQuoteByID(id: number): any{
    return this.http.get(environment.apiURL + '/Quote/' + id).toPromise();
  };

  deleteQuote(id: number){
    return this.http.delete(environment.apiURL + '/Quote/' + id).toPromise();
  };

  getQuoteCustomerFilter(customerID: string){
    return this.http.get(environment.apiURL + '/Quote/customer/'+ customerID).toPromise();
  };

  getQuoteSimboloFilter(simboloSKU: string){
    return this.http.get(environment.apiURL + '/Quote/customer/customerSKU/'+ simboloSKU).toPromise();
  };

  getQuoteMax(){
    return this.http.get(environment.apiURL + '/Quote/quotes/maxQuote/maxQuotes/maxQuotesQ').toPromise();
  };
}
