import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { Country } from 'src/app/shared/country.model';
import { CountryService } from 'src/app/shared/country.service';
import { State } from 'src/app/shared/state.model';
import { StateService } from 'src/app/shared/state.service';
import { Supplier } from 'src/app/shared/supplier.model';
import { SupplierService } from 'src/app/shared/supplier.service';

@Component({
  selector: 'app-supplier-items',
  templateUrl: './supplier-items.component.html',
  styles: []
})
export class SupplierItemsComponent implements OnInit {
  countryList: Country [];
  stateList: State [];
  listState: State [];
  isValid: boolean = true;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) 
  public data,
  public dialogRef: MatDialogRef<SupplierItemsComponent>,
  public supplierService: SupplierService, private countryService: CountryService,
  private stateService: StateService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.countryService.getCountryList().then(res => this.countryList = res as Country[]);
    this.stateService.getStateList().then(res => this.stateList = res as State[]);
    this.stateService.getStateList().then(res => this.listState = res as State[]);
  
    if (this.data.supplierItemIndex==null){
      this.supplierService.supplierList = {
        SupplierID: null,
        SupplierRFC: '',
        SupplierName: '',
        SupplierTradeName: '',
        ContactName: '',
        ContactPosition: '',
        CountryID: 0,
        StateID: 0,
        Address: '',
        ZipCode: '',
        Telephone: '',
        Email: ''
      }
    }else {
        this.supplierService.supplierList = Object.assign({}, this.supplierService.dataSupplier[this.data.supplierItemIndex]);
    };
  }

  onSubmit (form: NgForm){
    //validacion de RFC en mayusculas
    this.supplierService.supplierList.SupplierRFC=""+ this.supplierService.supplierList.SupplierRFC.toUpperCase();
    if (this.isValid == false){
      this.dialogRef.close();
    }else {
      if (this.validateForm(form.value)){
    //if (this.isValid == true){
        this.supplierService.supplierList.CountryID = +this.supplierService.supplierList.CountryID;
        this.supplierService.supplierList.StateID = +this.supplierService.supplierList.StateID;
          if (this.data.supplierItemIndex == null)
            this.supplierService.postSupplier().subscribe(res => {
              this.toastr.success('Grabado exitosamente!', 'Corrugated Quote.');
            });
          else
            this.supplierService.putSupplierID(this.supplierService.supplierList.SupplierID, this.supplierService.supplierList).subscribe(res => {
              this.toastr.success('Actualización exitosa!', 'Corrugated Quote.');
            });
            this.dialogRef.close();
        };
      this.isValid = true;
    };
  };
  
  onClose(){
    this.isValid = false;
  }

  validateForm(formSupplier: Supplier){
    this.onvalidateForm();
    if (this.isValid == false){
      exit;
    }else {
      this.isValid = true;
    };
    return this.isValid;
  };

  onSelectCountry(countryID: number){
      this.stateService.getStateList().then(res => this.listState = res as State []);
      this.stateList = this.listState.filter(item => item.CountryID == countryID);
  };

  onvalidateForm(){
    if (this.supplierService.supplierList.SupplierName == ""){
      this.getformFocus("SupplierName", "la Razón Social")
    };
    if (this.supplierService.supplierList.CountryID == 0){
      this.getformFocus("CountryID", " el Pais")
    };
   
    if (this.supplierService.supplierList.StateID == 0){
      this.getformFocus("StateID", " el Estado")
    };
  };

  getformFocus(formID: string, nameForm: string) {
    this.toastr.error('Por favor, ingrese  ' + nameForm, 'Corrugated Quote.');
    document.getElementById(formID).focus();
    this.isValid = false;
    return this.isValid;
  };
  
  //validación de la extensikon del RFC
  onvalidateRFC(SupplierRFC: string){
    //let comparation : string = "/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/";
    //et comparation : string = "/^([a-z&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([a-z\d]{2})$/";
    //var res = SupplierRFC.match(comparation);
      let res = SupplierRFC.length
        if(res == 13){
        this.toastr.success('El RFC es corrector')
        }
    };

}
