import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Pallet } from 'src/app/shared/pallet.model';
import { PalletService } from 'src/app/shared/pallet.service';
import { PalletItemsComponent } from './pallet-items.component';
import { DialogConfirm } from 'src/app/quotes/quote/confirm/dialog-confirm-component';

@Component({
  selector: 'app-pallet',
  templateUrl: './pallet.component.html',
  styles: []
})
export class PalletComponent implements OnInit {

  constructor(private dialog: MatDialog, public dataPallet: PalletService, 
    private router: Router, private currentRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dataPallet.getPalletList().then(res => this.dataPallet.dataPallet = res as Pallet);
  }

  refreshList(){
    document.getElementById("PalletIDFilter").focus();
    var formPalletID = <HTMLInputElement> document.getElementById("PalletIDFilter");
    //formBoardID.value = ''
    if ( formPalletID.value == '' || formPalletID.value == undefined){
      this.dataPallet.getPalletList().then(res => this.dataPallet.dataPallet = res as Pallet);
    } else {
      this.dataPallet.getPalletListId(formPalletID.value).then(res => this.dataPallet.dataPallet = res as Pallet);
    }
  };

  AddOrEditPalletItem(palletItemIndex, PalletID){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="60%";
    dialogConfig.height ="50%";
    dialogConfig.data = { palletItemIndex, PalletID };
    this.dialog.open(PalletItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });
  };

  onDeletePalletItem(palletID: number){
    this.confirmDisplayDelete(palletID);
    /*if (confirm('Estas seguro de quere borrar el registro?')){
      this.dataPallet.deletePallet(palletID).then(res => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente!', 'Corrugated Quote.');
      });
    };*/
  };

  confirmDisplayDelete(palletID:number): void {
    this.dialog
      .open(DialogConfirm, {
        data: '¿Estas seguro de querer borrar el registro?',
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.dataPallet.deletePallet(palletID).then(res => {
            //this.refreshList();
            this.toastr.warning('Borrado exitoso!', 'Corrugated Quote.');
          })
        }
      });
  }  
  onFilterPalletName(palletName: string){
    this.dataPallet.getPalletListId(palletName).then(res => this.dataPallet.dataPallet = res as Pallet);
  };

  getformFocus(formID: string) {
    document.getElementById(formID).focus();
    var formPalletID = <HTMLInputElement> document.getElementById(formID);
    formPalletID.value = ''
  };


}
