<h3>Buscar Tarima:</h3> 
<div class="form-row">
    <div class="form-group col-md-4" style="margin-top: 5px;">
        <input name="PalletIDFilter" id="PalletIDFilter" class="form-control" placeholder="Digite la Tarima a buscar..." 
        (keyup)="onFilterPalletName($event.target.value)">
    </div>
</div>
<table class="table table-hover">
    <thead class="thead-light">
        <th>ID</th>
        <th>Nombre</th>
        <th style="text-align: center;">Ancho (cm)</th>
        <th style="text-align: center;">Largo (cm)</th>
        <th style="text-align: center;">Altura (cm)</th>
        <th style="text-align: center;">Costo (MXN/Pza)</th>
        <th style="text-align: right;">
            <a class="btn btn-outline-success" (click)="AddOrEditPalletItem(null, dataPallet.dataPallet.PalletID)"><i class="fa fa-plus-square"></i>  Nuevo</a>
        </th>
    </thead>
    <tbody>
        <tr *ngFor="let item of dataPallet.dataPallet; let i=index;">
            <th (click)="AddOrEditPalletItem(i, item.PalletID)">{{item.PalletID}}</th>
            <td>{{item.PalletName}}</td>
            <td style="text-align: center;">{{item.Width}}</td>
            <td style="text-align: center;">{{item.Length}}</td>
            <td style="text-align: center;">{{item.Height}}</td>
            <td style="text-align: center;">{{item.Cost}}</td>
            <td style="text-align: right;">
                <a class="btn btn-sm btn-info text-white" (click)="AddOrEditPalletItem(i, item.PalletID)"><i class="fa fa-pencil"></i></a>
                <a class="btn btn-sm btn-danger text-white ml-1" (click)="onDeletePalletItem(item.PalletID)"><i class="fa fa-trash"></i></a>
            </td>
        </tr>
    </tbody>
</table>



