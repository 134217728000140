import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { Auxiliary } from 'src/app/shared/auxiliary.model';
import { AuxiliaryService } from 'src/app/shared/auxiliary.service';

@Component({
  selector: 'app-auxiliary-items',
  templateUrl: './auxiliary-items.component.html',
  styles: []
})
export class AuxiliaryItemsComponent implements OnInit {
  auxiliaryList: Auxiliary [];
  isValid: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) 
  public data,
  public dialogRef: MatDialogRef<AuxiliaryItemsComponent>,
  public auxiliaryService: AuxiliaryService,
  private toastr: ToastrService,
  private router: Router, private currentRoute: ActivatedRoute) { }

  ngOnInit(): void {
    //this.supplierService.getSupplierList().then(res => this.supplierList = res as Supplier[])

    if (this.data.auxiliaryItemIndex==null){
      this.auxiliaryService.auxiliaryList = {
        AuxMatID: 0,
        AuxMatName: '',
        AuxMatRatCon: null,
        AuxMatUniCon: null,
        AuxMatCost: null,
        AuxMatCostUni: null,
        AuxMatWaste: null,
        AuxLifeTime: null
      }
    }else {
        this.auxiliaryService.auxiliaryList = Object.assign({}, this.auxiliaryService.dataAuxiliary[this.data.auxiliaryItemIndex]);
    };
  }

  onSubmit (form: NgForm){
      if (this.isValid == false){
        this.dialogRef.close();
      }else {
        if (this.validateForm(form.value)){
          //this.auxiliaryService.auxiliaryList.AuxMatID = + this.auxiliaryService.auxiliaryList.AuxMatID;
          if (this.data.auxiliaryItemIndex == null)
            this.auxiliaryService.postAuxiliary().subscribe(res => {
              this.toastr.success('Grabado exitosamente!', 'Corrugated Quote.');
          });
          else
            this.auxiliaryService.putAuxiliaryID(this.auxiliaryService.auxiliaryList.AuxMatID, this.auxiliaryService.auxiliaryList).subscribe(res => {
              this.toastr.success('Actualización exitosa!', 'Corrugated Quote.');
            });
          this.dialogRef.close();
        };
        this.isValid = true;
      };
  };
  
  onClose(){
    this.isValid = false;
  }


  validateForm(formAuxiliary: Auxiliary){
    this.onvalidateForm();
    if (this.isValid == false){
      exit;
    }else {
      this.isValid = true;
    };
    return this.isValid;
  };

  onvalidateForm(){
    if (this.auxiliaryService.auxiliaryList.AuxMatName == ""){
      this.getformFocus("AuxMatName", " el Nombre del Material")
    };
    if (this.auxiliaryService.auxiliaryList.AuxMatRatCon == 0 || this.auxiliaryService.auxiliaryList.AuxMatRatCon == null){
      this.getformFocus("AuxMatRatCon", " el Consumo")
    };
  };

  getformFocus(formID: string, nameForm: string) {
    this.toastr.error('Por favor, ingrese ' + nameForm, 'Corrugated Quote.');
    document.getElementById(formID).focus();
    this.isValid = false;
    return this.isValid;
  };
}
