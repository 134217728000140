import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { Pallet } from 'src/app/shared/pallet.model';
import { PalletService } from 'src/app/shared/pallet.service';

@Component({
  selector: 'app-pallet-items',
  templateUrl: './pallet-items.component.html',
  styles: []
})
export class PalletItemsComponent implements OnInit {

  palletList: Pallet [];
  isValid: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) 
  public data,
  public dialogRef: MatDialogRef<PalletItemsComponent>,
  public palletService: PalletService,
  private toastr: ToastrService,
  private router: Router, private currentRoute: ActivatedRoute) { }

  ngOnInit(): void {

    if (this.data.palletItemIndex==null){
      this.palletService.palletList = {
        PalletID: 0,
        PalletName: '',
        Width: 0,
        Length: 0,
        Height: 0,
        Cost: 0
      }
    }else {
        this.palletService.palletList = Object.assign({}, this.palletService.dataPallet[this.data.palletItemIndex]);
    };
  }

  onSubmit (form: NgForm){
      if (this.isValid == false){
        this.dialogRef.close();
      }else {
        if (this.validateForm(form.value)){
          if (this.data.palletItemIndex == null)
            this.palletService.postPallet().subscribe(res => {
              this.toastr.success('Grabado exitosamente!', 'Corrugated Quote.');
          });
          else
            this.palletService.putPalletID(this.palletService.palletList.PalletID, this.palletService.palletList).subscribe(res => {
              this.toastr.success('Actualización exitosa!', 'Corrugated Quote.');
            });
          this.dialogRef.close();
        };
        this.isValid = true;
      };
  };
  
  onClose(){
    this.isValid = false;
  }


  validateForm(formPallet: Pallet){
    this.onvalidateForm();
    if (this.isValid == false){
      exit;
    }else {
      this.isValid = true;
    };
    return this.isValid;
  };

  onvalidateForm(){
    if (this.palletService.palletList.PalletName == ""){
      this.getformFocus("PalletName", "Nombre del Material")
    };
    if (this.palletService.palletList.Width == 0 || this.palletService.palletList.Length == 0){
      this.getformFocus("Width", "Ancho / Largo")
    };
  };

  getformFocus(formID: string, nameForm: string) {
    this.toastr.error('Por favor, ingrese el/la ' + nameForm, 'Corrugated Quote.');
    document.getElementById(formID).focus();
    this.isValid = false;
    return this.isValid;
  };

}
