import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { TruckType } from 'src/app/shared/TruckType.model';
import { TruckTypeService } from 'src/app/shared/TruckType.service';

@Component({
  selector: 'app-trucktype-item',
  templateUrl: './trucktype-item.component.html',
  styles: []
})
export class TrucktypeItemComponent implements OnInit {

  //auxiliaryList: TruckType [];
  isValid: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) 
  public data,
  public dialogRef: MatDialogRef<TrucktypeItemComponent>,
  public trucktypeService: TruckTypeService,
  private toastr: ToastrService) { }

  ngOnInit(): void {

    if (this.data.truckItemIndex==null){
      this.trucktypeService.trucktypeList = {
        TruckID: 0,
        TruckName: "",
        Length: 0,
        Width: 0,
        Height: 0,
        CapacityM3: 0,
      }
    }else {
        this.trucktypeService.trucktypeList = Object.assign({}, this.trucktypeService.dataTruckType[this.data.truckItemIndex]);
    };
  }

  onSubmit (form: NgForm){
      if (this.isValid == false){
        this.dialogRef.close();
      }else {
        this.trucktypeService.trucktypeList.CapacityM3 = parseFloat((this.trucktypeService.trucktypeList.Length * this.trucktypeService.trucktypeList.Width * this.trucktypeService.trucktypeList.Height).toFixed(2));
        if (this.validateForm(form.value)){
          if (this.data.truckItemIndex == null)
            this.trucktypeService.postTruckType().subscribe(res => {
              this.toastr.success('Grabado exitosamente!', 'Corrugated Quote.');
          });
          else
            this.trucktypeService.putTruckTypeID(this.trucktypeService.trucktypeList.TruckID, this.trucktypeService.trucktypeList).subscribe(res => {
              this.toastr.success('Actualización exitosa!', 'Corrugated Quote.');
            });
          this.dialogRef.close();
        };
        this.isValid = true;
      };
  };
  
  onClose(){
    this.isValid = false;
  }


  validateForm(formTruckType: TruckType){
    this.onvalidateForm();
    if (this.isValid == false){
      exit;
    }else {
      this.isValid = true;
    };
    return this.isValid;
  };

  onvalidateForm(){
    if (this.trucktypeService.trucktypeList.TruckName == "" || this.trucktypeService.trucktypeList.TruckName == null){
      this.getformFocus("TruckName", "Descripción del Transporte")
    };
    if (this.trucktypeService.trucktypeList.Length == 0 || this.trucktypeService.trucktypeList.Length == null){
      this.getformFocus("Length", "Largo...")
    };
    if (this.trucktypeService.trucktypeList.Width == 0 || this.trucktypeService.trucktypeList.Width == null){
      this.getformFocus("Width", "Ancho...")
    };
    if (this.trucktypeService.trucktypeList.Height == 0 || this.trucktypeService.trucktypeList.Height == null){
      this.getformFocus("Height", "Altura...")
    };
  };

  getformFocus(formID: string, nameForm: string) {
    this.toastr.error('Por favor, ingrese el ' + nameForm, 'Corrugated Quote.');
    document.getElementById(formID).focus();
    this.isValid = false;
    return this.isValid;
  };

}
