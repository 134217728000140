<h3>Buscar papel:</h3>
<div class="form-row">
    <div class="form-group col-md-4" style="margin-top: 5px;">
        <input name="PaperIDFilter" id="PaperIDFilter" class="form-control" placeholder="Digite el Papel a buscar..." 
        (keyup)="onFilterPaperNomenclature($event.target.value)">
    </div>
    <div class="form-group" style="margin-left: 380px; margin-top: 5px;">
        <a class="btn btn-outline-success" 
        style="font-weight:bold; height: 40px; width: 300px; text-align: center; vertical-align:middle;" 
        (click)="updatePaperCost()"><i class="fa fa-dollar"></i> Actualizar Costos</a>
    </div>
</div>
<table class="table table-hover">
    <thead class="thead-light">
        <th>ID</th>
        <th>Nomenclatura</th>
        <th hidden = "true">Cod. Tipo</th>
        <th>Tipo de Papel</th>
        <th hidden = "true">Procedencia</th>
        <th hidden = "true">Proveedor</th>
        <th>Color</th>
        <th hidden = "true">Tipo de Mercado</th>
        <th>Rendimiento</th>
        <th style="text-align: right;">Gramaje (gr/m2)</th>
        <th style="text-align: right;">Costo MXN/Ton</th>
        <th style="text-align: right;">CostO MXN/MM2</th>
        <th>CMT</th>
        <th>CCT</th>
        <th>SCT</th>
        <th>RCT</th>
        <th hidden = "true">COBB180</th>
        <th style="text-align: right;">COBB120</th>
        <th hidden = "true">Papel Sustituto 1</th>
        <th hidden = "true">Papel Sustituto 2</th>
        <th hidden = "true">Papel Sustituto 3</th>
        <th hidden = "true">Papel Sustituto 4</th>
        <th hidden = "true">Papel Sustituto 5</th>
        <th hidden = "true">Papel Sustituto 6</th>
        <th hidden = "true">Notas</th>
        <th>
            <a class="btn btn-outline-success" (click)="AddOrEditPaperItem(null, dataPaper.dataPaper.PaperID)"><i class="fa fa-plus-square"></i>  Nuevo</a>
        </th>
    </thead>
    <tbody>
        <tr *ngFor="let item of paginatedData; let i=index;">
            <th (click)="AddOrEditPaperItem(((currentPage-1)*itemsPerPage)+i, item.PaperID)">{{item.PaperID}}</th>
            <td>{{item.PaperNomenclature}}</td>
            <td>{{item.PaperType}}</td>
            <td hidden = "true">{{item.PaperTypeAbbreviated}}</td>
            <td style="text-align: center;">{{item.ColorPaper}}</td>
            <td hidden = "true">{{item.TypeMarket}}</td>
            <td style="text-align: center;">{{item.Performance}}</td>
            <td hidden = "true">{{item.SupplierID}}</td>
            <td hidden = "true">{{item.PaperNatOrImp}}</td>
            <td style="text-align: right;">{{item.Weight}}</td>
            <td style="text-align: right;">{{item.CostTonLocCurrency}}</td>
            <td style="text-align: right;">{{item.CostMMSLocCurrency}}</td>
            <td>{{item.CMT}}</td>
            <td>{{item.CCT}}</td>
            <td>{{item.SCT}}</td>
            <td>{{item.RCT}}</td>
            <td hidden = "true">{{item.COBB180}}</td>
            <td>{{item.COBB120}}</td>
            <td hidden = "true">{{item.PaperSustitute1}}</td>
            <td hidden = "true">{{item.PaperSustitute2}}</td>
            <td hidden = "true">{{item.PaperSustitute3}}</td>
            <td hidden = "true">{{item.PaperSustitute4}}</td>
            <td hidden = "true">{{item.PaperSustitute5}}</td>
            <td hidden = "true">{{item.PaperSustitute6}}</td>
            <td hidden = "true">{{item.Note}}</td>
            <td style="text-align: right;">
                <a class="btn btn-sm btn-info text-white" (click)="AddOrEditPaperItem(((currentPage-1)*itemsPerPage)+i, item.PaperID)"><i class="fa fa-pencil"></i></a>
                <!-- <a class="btn btn-sm btn-danger text-white ml-1" (click)="onDeletePaperItem(item.PaperID)"><i class="fa fa-trash"></i></a> -->
            </td>
        </tr>
    </tbody>
</table>
<!--codigo de paginación-->
<nav>
    <ul class="pagination"> <!--controla el recuadro de anterior-->
        <li class="page-item" style="cursor: pointer;">
            <a class="page-link"
            [ngClass]="{disabled:currentPage ===1 }" 
            (click)="pageClicked(currentPage - 1)">Anterior</a>
        </li>
        <li (click)="pageClicked(page)"
            *ngFor="let page of pages" 
            class="page-item" 
            [ngClass]="{ active: page == currentPage }">
            <a class="page-link" href="javascript:void(0)">{{ page }}</a><!--se pocisiona en la pagina y activa el cuadrito.-->
        </li>
        <li class="page-item" style="cursor: pointer;"> <!--controla el recuadro de siguiente-->
            <a class="page-link" 
            [ngClass]="{ disabled: currentPage === totalPages }" 
            (click)="pageClicked(currentPage + 1)">Siguiente</a>
        </li>
    </ul>
</nav>


