import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { Costrate } from 'src/app/shared/costrate.model';
import { CostrateService } from 'src/app/shared/costrate.service';

@Component({
  selector: 'app-costrate-items',
  templateUrl: './costrate-items.component.html',
  styles: []
})
export class CostrateItemsComponent implements OnInit {

  isValid: boolean = true;
  
  constructor(@Inject(MAT_DIALOG_DATA) 
  public data,
  public dialogRef: MatDialogRef<CostrateItemsComponent>,
  public costRateService: CostrateService,
  private toastr: ToastrService) { }

  ngOnInit(): void {  
    if (this.data.costRateItemIndex==null){
      this.costRateService.costRateList = {
        MachineID: '',
        DirLaborCostHR: 0,
        IndLaborCostHR: 0,
        MaintenanceCostHR: 0,
        EnergyCostoHR: 0,
        WaterCostHR: 0,
        GasCostHR: 0,
        IndManCostHR: 0,
        CorpMiscCostHR: 0,
      }
    }else {
        this.costRateService.costRateList = Object.assign({}, this.costRateService.dataCostRate[this.data.costRateItemIndex]);
    };
  };

  onSubmit (form: NgForm){

    if (this.isValid == false){
      this.dialogRef.close();
    }else {
      if (this.validateForm(form.value)){
    //if (this.validateForm(form.value)){
        //if (this.isValid == true){
          //this.workstationService.workstationList.CountryID = +this.workstationService.workstationList.CountryID;
          //this.workstationService.workstationList.StateID = +this.workstationService.workstationList.StateID;
          /* if (this.data.costRateItemIndex == null)
            this.costRateService.putCostRateID().subscribe(res => {
              this.toastr.success('Grabado exitosamente!', 'Corrugated Quote.');
            });
          else */
            this.costRateService.putCostRateID(this.costRateService.costRateList.MachineID, this.costRateService.costRateList).subscribe(res => {
              this.toastr.success('Actualización exitosa!', 'Corrugated Quote.');
          });
          this.dialogRef.close();
        };
        this.isValid = true;
        
    //};
    };
  };
  
  onClose(){
    this.isValid = false;
  }


  validateForm(formCostRate: Costrate){
    this.onvalidateForm();
    if (this.isValid == false){
      exit;
    }else {
      this.isValid = true;
    };
    return this.isValid;
  };

  onvalidateForm(){
    if (this.costRateService.costRateList.DirLaborCostHR < 0){
      this.getformFocus("DirLaborCostHR", "Costo de mano de obra")
    };
  };

  getformFocus(formID: string, nameForm: string) {
    this.toastr.error('Por favor, ingrese el ' + nameForm, 'Corrugated Quote.');
    document.getElementById(formID).focus();
    this.isValid = false;
    return this.isValid;
  };

}
