import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { Paper } from 'src/app/shared/paper.model';
import { PaperService } from 'src/app/shared/paper.service';
import { Supplier } from 'src/app/shared/supplier.model';
import { SupplierService } from 'src/app/shared/supplier.service';

@Component({
  selector: 'app-paper-items',
  templateUrl: './paper-items.component.html',
  styles: []
})
export class PaperItemsComponent implements OnInit {
  supplierList: Supplier [];
  isValid: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) 
  public data,
  public dialogRef: MatDialogRef<PaperItemsComponent>,
  public paperService: PaperService, private supplierService: SupplierService,
  private toastr: ToastrService,
  private router: Router, private currentRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.supplierService.getSupplierList().then(res => this.supplierList = res as Supplier[])

    if (this.data.paperItemIndex==null){
      this.paperService.paperList = {
        PaperID: 0,
        PaperType: '',
        PaperTypeAbbreviated : '',
        PaperNomenclature: '',
        Performance: '',
        ColorPaper: '',
        SupplierID: null,
        TypeMarket: '',
        PaperNatOrImp: '',
        Weight: null,
        CostTonLocCurrency: null,
        CostMMSLocCurrency: null,
        CMT: null,
        CCT: null,
        SCT: null,
        RCT: null,
        COBB180: null,
        COBB120: null,
        PaperSustitute1: '',
        PaperSustitute2: '',
        PaperSustitute3: '',
        PaperSustitute4: '',
        PaperSustitute5: '',
        PaperSustitute6: '',
        Note: '',
      }
    }else {
        this.paperService.paperList = Object.assign({}, this.paperService.dataPaper[this.data.paperItemIndex]);
    };
  }

  onSubmit (form: NgForm){
      if (this.isValid == false){
        this.dialogRef.close();
      }else {
        if (this.validateForm(form.value)){
          this.paperService.paperList.SupplierID = + this.paperService.paperList.SupplierID;
          this.onsavePaper();
          if (this.data.paperItemIndex == null)
            this.paperService.postPaper().subscribe(res => {
              this.toastr.success('Grabado exitosamente!', 'Corrugated Quote.');
          });
          else
            this.paperService.putPaperID(this.paperService.paperList.PaperID, this.paperService.paperList).subscribe(res => {
              this.toastr.success('Actualización exitosa!', 'Corrugated Quote.');
            });
          this.dialogRef.close();
        };
        this.isValid = true;
      };
  };
  
  onClose(){
    this.isValid = false;
  }

  validateForm(formPaper: Paper){
    this.onvalidateForm();
    if (this.isValid == false){
      exit;
    }else {
      this.isValid = true;
    };
    return this.isValid;
  };

  onvalidateForm(){
    if (this.paperService.paperList.PaperType == ""){
      this.getformFocus("PaperType", "Tipo de Papel")
    };
    if (this.paperService.paperList.PaperNatOrImp == ""){
      this.getformFocus("PaperNatOrImp", "la procedencia del papel")
    };
    if (this.paperService.paperList.SupplierID == 0){
      this.getformFocus("SupplierID", "proveedor del papel")
    };
    if (this.paperService.paperList.TypeMarket == ""){
      this.getformFocus("TypeMarket", "tipo de mercado")
    };
    if (this.paperService.paperList.Performance == ""){
      this.getformFocus("Performance", "perfomance del papel")
    };
    if (this.paperService.paperList.Weight == 0 || this.paperService.paperList.Weight == null){
      this.getformFocus("Weight", "Gramaje del papel")
    };
  };

  getformFocus(formID: string, nameForm: string) {
    this.toastr.error('Por favor, ingrese el/la ' + nameForm, 'Corrugated Quote.');
    document.getElementById(formID).focus();
    this.isValid = false;
    return this.isValid;
  };

  onsavePaper(){
    let supplierPaper: string;
    this.paperService.paperList.ColorPaper = "K"
    if(this.paperService.paperList.PaperType == "Test Liner"){
      this.paperService.paperList.PaperTypeAbbreviated = "TL";
    } else if(this.paperService.paperList.PaperType == "Test Medium"){
      this.paperService.paperList.PaperTypeAbbreviated = "TM";
    } else if(this.paperService.paperList.PaperType == "Kraft Liner"){
      this.paperService.paperList.PaperTypeAbbreviated = "KL";
    } else if(this.paperService.paperList.PaperType == "Kraft Medium"){
      this.paperService.paperList.PaperTypeAbbreviated = "KM";
    } else if(this.paperService.paperList.PaperType == "White Top"){
      this.paperService.paperList.PaperTypeAbbreviated = "WT";
      this.paperService.paperList.ColorPaper = "B";
    };
    supplierPaper = this.supplierList.find(supplierName => supplierName.SupplierID == this.paperService.paperList.SupplierID).SupplierName;
    supplierPaper = supplierPaper.substring(0,3).toUpperCase();
    this.paperService.paperList.PaperNomenclature = this.paperService.paperList.PaperTypeAbbreviated + '-' + this.paperService.paperList.PaperNatOrImp + '-' + supplierPaper + '-' + this.paperService.paperList.TypeMarket + '-' + this.paperService.paperList.Performance + '-' + this.paperService.paperList.Weight.toString();
}
}
