import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Destiny } from 'src/app/shared/destiny.model';
import { DestinyService } from 'src/app/shared/destiny.service';
import { DestinyItemsComponent } from './destiny-items.component';

@Component({
  selector: 'app-destiny',
  templateUrl: './destiny.component.html',
  styles: []
})
export class DestinyComponent implements OnInit {
    //paginación declaracion de variables
    itemsPerPage=12;
    currentPage=1;
    totalPages = 0;
    totalItems=0;
    data1;
    totales;
    pages: number [] = [];
    @Output() onClick: EventEmitter<number> = new EventEmitter();//captura el evento click y se mueva

  constructor(private dialog: MatDialog, public dataDestiny: DestinyService, 
    private router: Router, private currentRoute: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dataDestiny.getDestinyList().then(res => this.dataDestiny.dataDestiny = res as Destiny);
  }

  //paginación funciones
  get paginatedData(){
    this.data1 = JSON.stringify(this.dataDestiny.dataDestiny);
    this.data1 = JSON.parse(this.data1);
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.totalItems = this.data1.length;
      return this.data1.slice(start,end);
  }

  ngAfterViewInit(){
  this.updateItems();
  }

  updateItems(){
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.pages = Array.from({length: this.totalPages}, (_, i) => i + 1);
    //console.log(this.pages);
  }

  pageClicked(page: number){
    if(page > this.totalPages) return;
    this.onClick.emit(page);
    this.changePage(page);
  }

  changePage(page: number){
  this.currentPage=page;
  }
  refreshList(){
    this.dataDestiny.getDestinyList().then(res => this.dataDestiny.dataDestiny = res as Destiny);
  };

  AddOrEditDestinyItem(destinyItemIndex, DestinyID){
    const dialogConfig = new MatDialogConfig(); 
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width ="100%";
    dialogConfig.height ="50%";
    dialogConfig.data = { destinyItemIndex, DestinyID };
    this.dialog.open(DestinyItemsComponent, dialogConfig).afterClosed().subscribe(res =>{
      this.refreshList();
      });
  };

  onDeleteDestinyItem(id: number){
    if (confirm('Estas seguro de querer eliminar el Destino seleccionado?')) {
      this.dataDestiny.deleteDestiny(id).then(() => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente', 'Corrugated Quote.');
      }, () =>{
          this.toastr.error('El Destino seleccionado no se puede eliminar, tiene registros activos con otras tablas', 'Corrugated Quote.');
      });
    };
    /* if (confirm('Estas seguro de querer eliminar el registro?')){
      this.dataDestiny.deleteDestiny(id).then(res => {
        this.refreshList();
        this.toastr.warning('Borrado exitosamente!', 'Corrugated Quote.');
      });
    }; */
  };

  onFilterDestinyName(destinyName: string){
    this.dataDestiny.getDestinyListId(destinyName).then(res => this.dataDestiny.dataDestiny = res as Destiny);
    this.updateItems();
  };
}

